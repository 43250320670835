import React, { Component } from "react";
import { Media,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { LineChart,Line,AreaChart,Area,PieChart,Pie,BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBTable, MDBTableBody, MDBTableHead  } from "mdbreact";
import {url} from '../../services/common';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

class IntrusionDetection extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            activeTab: 1,
            isLoading:false,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            Linedata:[],
            tableData:[],
            startDate:null,
            endDate:null,
            searchKey:'',
            filteredTableData:[],
        }

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);
    
        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }

  
    exportPDFWithMethod(){

        let element = this.container.current || document.body;
        savePDF(element, {
          paperSize: "auto",
          margin: 40,
          fileName: `Report for ${new Date().getFullYear()}`,
        });
      }
    
    exportPDFWithComponent(){
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
      };


    handleChange(e){

        this.setState({searchKey:e.target.value})  
       
        var tData = this.state.tableData;
        var newTable =[];

        for(var i=0;i<tData.length;i++){
            if( (tData[i].rule_description.toLowerCase().includes(e.target.value.toLowerCase())) 
             || (tData[i].agent.name.toLowerCase().includes(e.target.value.toLowerCase())) 
             || (tData[i].rule_mitre_tactic && tData[i].rule_mitre_tactic.length>0 && tData[i].rule_mitre_tactic[0].toLowerCase().includes(e.target.value.toLowerCase())) 
             ){
                newTable.push(tData[i]);
            }
        }

        this.setState({filteredTableData:newTable})       

    }

    onStartDateChange(e){
        this.setState({endDate:null})
        this.setState({startDate:new Date(e.target.value)})
    }

    onEndDateChange(e){
        //console.log(e.target.value)
        this.setState({endDate:new Date(e.target.value)})
    }
  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
       this.getlineChartData()
       this.getChartData()
       this.getTableData()
    }

    getlineChartData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/intrusion-detection/linechart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({Linedata:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }
  
    getChartData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/intrusion-detection/chart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({data:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoadin: false})); 
    }

    getTableData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/intrusion-detection/table`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({tableData:json.table, filteredTableData:json.table}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }
    
    render() {
        return (
            
            <React.Fragment>

                 <Button
                        color="info"
                        className="waves-effect waves-light mr-1 pull-right"
                        onClick={()=> this.exportPDFWithComponent()}
                        style={{float:'right', display:'block'}}
                    >
                        Export to PDF
                 </Button>


                { this.state.isLoading ?
                    <div>
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={'100%'}   
                        timeout={5000} //3 secs
                    />  
                    </div>     
                :


                
                
                    <div className="mainContaindiv">
                        <Row className="mt-4">
                            <h4>{this.props.heading}</h4>
                        </Row>
                        <Row className="mt-4">
                        <AvForm inline >
                            Filter Data
                            <Col style={{display:'flex', flexDirection:'row'}}>                                

                                 <input type="date" name="start_date" onChange={this.onStartDateChange}/><input type="time" name="start_time" onChange={this.onStartTimeChange}/> &nbsp; &nbsp;to &nbsp; &nbsp;
                                 <input type="date" name="end_date" onChange={this.onEndDateChange} /><input type="time" name="end_time" onChange={this.onEndTimeChange}/>
                              </Col>
                        </AvForm>
                        </Row>

                        <PDFExport
                    ref={this.pdfExportComponent}
                    paperSize="auto"
                    margin={40}
                    fileName={`Report for ${new Date().getFullYear()}`}
                    author="KendoReact Team"
                >

                    <div className="mainContaindiv" style={{padding:50}}>    


                        <Row className="mt-4">                            
                            <Col>
                                <h4>Rootkits activity over time</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={this.state.Linedata}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <br/>
                                    <Line type="monotone" dataKey="Binary trojan" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    <Line type="monotone" dataKey="Omega rootkit" stroke="#82ca9d" />
                                    <Line type="monotone" dataKey="TRK rootkit" stroke="#259E3A" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <h4>Security Alerts</h4>
                        </Row>

                        <Row className="mt-4">

                        <div data-test="datatable-input" class="mdb-datatable-filter flex-row">
                            <input class="form-control form-control-sm ml-0 my-1" 
                               type="text" 
                               placeholder="Search" 
                               aria-label="Search" 
                               onChange={(e) => {this.handleChange(e)}}
                               value={this.state.searchKey}/>
                        </div>
                        </Row>

                        
                        <Row className="mt-4">
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Agent Name</th>
                                        <th>rule.mitre.id</th>
                                        <th>rule.mitre.tactic</th>
                                        <th>Description</th>
                                        {/* <th>rule.level</th>
                                        <th>rule.id</th> */}
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {
                                        this.state?.filteredTableData?.map((item,index)=>{
                                            return <tr>
                                                        <td>{item?.timestamp}</td>
                                                        <td>{item?.agent?.name}</td>
                                                        <td title={`https://attack.mitre.org/techniques/${item?.rule_mitre_id?.[0]}`}>
                                                        <a href={`https://attack.mitre.org/techniques/${item?.rule_mitre_id?.[0]}`} target="_blank">
                                                            {item?.rule_mitre_id?.[0]}
                                                        </a>
                                                    </td>
                                                        <td>{item?.rule_mitre_tactic?.[0]}</td>
                                                        <td>{item?.rule_description}</td>
                                                        {/* <td>{item?.rule_level}</td>
                                                        <td>{item?.rule_id}</td> */}
                                                    </tr>
                                        })
                                    }
                                </MDBTableBody>
                            </MDBTable>
                        </Row>

                        
                        </div>
                        </PDFExport>
                    </div>

                }
            </React.Fragment>

        );
    }
}

export default IntrusionDetection;
