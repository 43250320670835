import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import Loader from "react-loader-spinner";
import {url} from '../../services/common';

class ThreatsComparison extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [12, 88],
            options : {
                labels: ["NEW", "RECURRING"],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '75%'
                        }
                    }
                },
                dataLabels: {
                    enabled: true
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        const total = series.reduce((a, b) => a + b, 0);
                        const percent = ((series[seriesIndex] / total) * 100).toFixed(1);
                        const label = w.config.labels[seriesIndex]; // Access label using seriesIndex
                        return `<div class="apexcharts-tooltip-custom">${label}: ${percent}%</div>`;
                    }
                },
                legend: {
                    position: 'right',
                },
                fill: {
                    type: 'solid',
                },
                colors: ['#30DD72', '#ff0000']                   
            
            },
            scanType:"all",
            isLoading:false,
            loadingMessage:'Loading...'
        }
    }
    
    componentDidMount()
    {
       this.ChangeScanType(this.state.scanType)
    }

    ChangeScanType = (scanType) =>{
        this.setState({scanType:scanType})
        var authToken = localStorage.getItem('auth_token');  	
        this.setState({isLoading: true})
        fetch(`${url}/new-threats-vs-repeated-threats?scanType=${scanType}`, {
            method: "GET",  
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {
            let seriesUpdated    =   json.pieData;
            console.log('seriesUpdated', seriesUpdated);
            this.setState({series:seriesUpdated})
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-right">
                            <select name="scantype" onChange={(e)=>this.ChangeScanType(e.target.value)} className="custom-select custom-select-sm">
                                <option value="all" {...this.state.scanType === 'All' ? 'selected': ''}>All</option>
                                <option value="aws" {...this.state.scanType === 'aws' ? 'selected': ''}>AWS</option>
                                <option value="gcp" {...this.state.scanType === 'gcp' ? 'selected': ''}>GCP</option>
                                <option value="azure" {...this.state.scanType === 'azure' ? 'selected': ''}>AZURE</option>
                                <option value="oci" {...this.state.scanType === 'oci' ? 'selected': ''}>OCI</option>
                            </select>
                        </div>
                        <h4 className="card-title mb-4">New Threats vs Repeated Threats</h4>

                        {this.state.series[0] == 0 && this.state.series[1] == 0 ?
                        <div></div>
                        :
                        <div id="donut-chart" className="apex-charts">
                            {
                                this.state.isLoading ? 
                                    <div>
                                    <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'20%'}}>{this.state.loadingMessage}</h3>
                                    <Loader
                                        type="Bars"
                                        color="#00BFFF"
                                        height={100}
                                        width={'100%'}   
                                        timeout={5000} //3 secs
                                    />  
                                    </div> 

                                : 
                                
                                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="230" />
                            }
                        </div>
                        }
                        
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default ThreatsComparison;