import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Media } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18b
import { withNamespaces } from "react-i18next";
import {url} from '../../../services/common';

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      notifications:[]
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount(){
    this.getnotifications()
      setTimeout(() => {
        this.getnotifications()
      }, 300000);
  }
  getnotifications = () => {
    let authToken = localStorage.getItem('auth_token');
    fetch(`${url}/get-notifications`, {
        method: "GET",     
        headers: {
          'Accept': 'application/json',  
          'Authorization': 'Bearer '+authToken                  
        }            
    })
    .then((response) => response.json())
    .then((json) => {
        this.setState({notifications: json})
    })
    .catch((error) => console.error('ss'+error))
    .finally(() => this.setState({isLoading: false})); 
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }
  render() {
    return (
      <React.Fragment>
                        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
                            <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown">
                                <i className="ri-notification-3-line"></i>
                                <span className="noti-dot"></span>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-lg p-0"
                                aria-labelledby="page-header-notifications-dropdown">
                                <div className="p-3">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h6 className="m-0"> {this.props.t('Notifications')} </h6>
                                        </Col>
                                    </Row>
                                </div>
                                <SimpleBar style={{maxHeight: "230px"}}>
                                    {
                                        this.state.notifications?.length > 0 ? 
                                            this.state.notifications.map((item,index)=>{
                                                return <Link to="#" className="text-reset notification-item">
                                                    <Media>
                                                        <div className="avatar-xs mr-3">
                                                            <span className="avatar-title bg-success rounded-circle font-size-16">
                                                                <i className="ri-checkbox-circle-line"></i>
                                                            </span>
                                                        </div>
                                                        <Media body>
                                                            <h6 className="mt-0 mb-1">{item?.title}</h6>
                                                            <div className="font-size-12 text-muted">
                                                                <p className="mb-1 text-left">{item?.description}</p>
                                                                {/* <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {this.props.t('3 min ago')}</p> */}
                                                            </div>
                                                        </Media>
                                                    </Media>
                                                </Link>
                                            })
                                        
                                        : null
                                    }
                                </SimpleBar>
                                <div className="p-2 border-top">
                                    <Link to="/notifications" className="btn btn-sm btn-link font-size-14 btn-block text-center">
                                        <i className="mdi mdi-arrow-right-circle mr-1"></i>{this.props.t(' View More')}
                                    </Link>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(NotificationDropdown);
