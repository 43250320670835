import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import {url} from '../../services/common';
import Loader from "react-loader-spinner";

class MostAffectedRegions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:{
                labels: [],
                datasets: [
                    {
                        label:"Threats",
                        backgroundColor: [
                            '#6ece58', '#482878', '#3e4989', '#31688e', '#26828e', 
                            '#1f9e89', '#35b779', '#fde725', '#b5de2b', '#440154'
                        ], // Use the provided colors
                        borderColor: "rgba(245, 6, 58, 1)",
                        borderWidth: 0,
                        hoverBackgroundColor: [
                            '#6ece58', '#482878', '#3e4989', '#31688e', '#26828e', 
                            '#1f9e89', '#35b779', '#fde725', '#b5de2b', '#fde725'
                        ], // Use the provided hover colors
                        borderWidth: 0,
                        hoverBorderColor: "transparent",
                        data: []
                    }
                ]
            },
            option: {
                scales: {
                    xAxes: [{
                        barPercentage: 0.7,
                        ticks: {
                            fontColor: 'white', // Set y-axis label color to white
                        },
                    }],
                    yAxes: [{                        
                        ticks: {
                            fontColor: 'white', // Set y-axis label color to white
                        },
                    }],
                },
                legend: {
                    display: false // Turn off legends
                }
            },
            scanType:'all',
            isLoading:false,
            loadingMessage:'Loading...',
        }
           
    }

    componentDidMount(){
        this.ChangeScanType(this.state.scanType);
    }

    ChangeScanType = (scanType) =>{
        var authToken = localStorage.getItem('auth_token');  
        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Loading...'});
        fetch(`${url}/most-affected-regions?scanType=${scanType}`, {
            method: "GET",  
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {
            let {data}                       =   this.state;
            data.labels                      =   json.barChart.regions;
            data.datasets[0].data            =   json.barChart.threats;
            this.setState({data,scanType:scanType});
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-right">
                            <ButtonGroup className="mb-2">
                                <select name="scantype" onChange={(e) => this.ChangeScanType(e.target.value)} className="custom-select custom-select-sm">
                                    <option value="all" selected={this.state.scanType === 'all' ? true : false}>All</option>
                                    <option value="aws" selected={this.state.scanType === 'aws' ? true : false}>AWS</option>
                                    <option value="gcp" selected={this.state.scanType === 'gcp' ? true : false}>GCP</option>
                                    <option value="azure" selected={this.state.scanType === 'azure' ? true : false}>AZURE</option>
                                    <option value="oci" selected={this.state.scanType === 'oci' ? true : false}>OCI</option>
                                </select>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Most Affected Regions</h4>
                        <div>
                            {
                                this.state.isLoading &&
                                    <div>
                                    <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
                                    <Loader
                                        type="Bars"
                                        color="#00BFFF"
                                        height={100}
                                        width={'100%'}   
                                    />  
                                    </div> 
                            }
                            {
                                !this.state.isLoading &&
                                <Bar width={474} height={300} data={this.state.data} options={this.state.option} />                                    
                            }
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MostAffectedRegions;   