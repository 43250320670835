import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
// users
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import {url} from '../../../services/common';
import toastr from 'toastr'

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
    }
    
    componentDidMount(){
    
    }
    
    // Logout and go to login page
    logout()
    {
        this.setState({isLoading: true}); 
        var authToken = localStorage.getItem('auth_token');
        fetch(`${url}/logout`, {
            method: "POST",    
            headers: {
              'Accept': 'application/json', 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {
            if(json.success){
                localStorage.removeItem('is_logged_in');
                localStorage.removeItem('auth_token');
                localStorage.removeItem('email');
                localStorage.removeItem('first_name');
                localStorage.removeItem('last_name');
                localStorage.removeItem('username');
                localStorage.removeItem('userId');
                localStorage.removeItem('user_type');
                localStorage.removeItem('HasPerms');
                // toastr.success(json.message,'Success');
                this.props.history.push('/');
            }else{
                //toastr.error(json.message,'Error');
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {

    let username = "Admin";
   if(localStorage.getItem("authUser"))
   {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const uNm = obj.email.split("@")[0];
        username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
   }
  
        return (
            <React.Fragment>
                        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                            <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                                <img className="rounded-circle header-profile-user mr-1" src={avatar2} alt="Header Avatar"/>
                                <span className="d-none d-xl-inline-block ml-1 text-transform">{this.props.userfirstName}</span>
                                <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="/my-account"><i className="ri-user-line align-middle mr-1"></i>Profile</DropdownItem>

                                <DropdownItem className="d-block" href="/settings"><span className="badge badge-success float-right mt-1"></span><i className="ri-settings-2-line align-middle mr-1"></i>Settings</DropdownItem>

                                <DropdownItem divider />
                                <DropdownItem 
                                    className="text-danger" onClick={this.logout}
                                    ><i className="ri-shut-down-line align-middle mr-1 text-danger"></i>Logout</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { userfirstName } = state.Login;
    return { userfirstName };
}

export default withRouter(connect(mapStatetoProps, null)(ProfileMenu));
