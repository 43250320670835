import React, { Component } from "react";
import { Media,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { PieChart,Pie,BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBTable, MDBTableBody, MDBTableHead  } from "mdbreact";
import {url} from '../../services/common';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";


class AnomalyDetection extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            isLoading:false,
            activeTab: 1,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            widgets:[],
            tableData:[],
            piedata:[],
            startDate:null,
            endDate:null,
            startTime:null,
            endTime:null,
            startDatePlain:null,
            endDatePlain:null,
            startTimePlain:null,
            endTimePlain:null,
            searchKey:'',
            filteredTableData:[],
        }

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);

        this.onStartTimeChange = this.onStartTimeChange.bind(this);
        this.onEndTimeChange = this.onEndTimeChange.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);
    
        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }

    handleChange(e){

        this.setState({searchKey:e.target.value})  
       
        var tData = this.state.tableData;
        var newTable =[];
        for(var i=0;i<tData.length;i++){
            if( (tData[i].rule_description.toLowerCase().includes(e.target.value.toLowerCase())) 
             || (tData[i].agent.name.toLowerCase().includes(e.target.value.toLowerCase())) 
             || (tData[i].rule_mitre_tactic && tData[i].rule_mitre_tactic.length>0 && tData[i].rule_mitre_tactic[0].toLowerCase().includes(e.target.value.toLowerCase())) 
             ){
                newTable.push(tData[i]);
            }

        }

        this.setState({filteredTableData:newTable})       
    }


    onStartDateChange(e){        

        if(e.target.value == ''){
            this.setState({endDate:null})
            this.setState({endDatePlain:''})
            this.setState({endTime:null})
            this.setState({endTimePlain:''})
    
            this.setState({startDate:null})
            this.setState({startDatePlain:''})
        }else{
           this.setState({endDate:null})
           this.setState({endDatePlain:''})
           this.setState({endTime:null})
           this.setState({endTimePlain:''})

           this.setState({startDate:new Date(e.target.value)})
           this.setState({startDatePlain:e.target.value})
        }
    }

    onEndDateChange(e){

        //console.log(e.target.value)
        this.setState({endDate:new Date(e.target.value)})
        this.setState({endDatePlain:e.target.value})
    }

    onStartTimeChange(e){
        
        this.setState({endTime:null})
        this.setState({endTimePlain:''})
        this.setState({endDate:null})
        this.setState({endDatePlain:''})

        this.setState({startTime:new Date(e.target.value)})
        this.setState({startTimePlain:e.target.value})
    }

    onEndTimeChange(e){
        

        this.setState({endTime:new Date(e.target.value)})
        this.setState({endTimePlain:e.target.value})

        // Since the end time is the last value to be selected, call the API again to get filtered
        // results based on timestamp

        var startTimestamp = this.state.startDatePlain + ' ' + this.state.startTimePlain;
        var endTimestamp = this.state.endDatePlain + ' ' + e.target.value;

        this.getTableData(startTimestamp, endTimestamp);
        this.getChartData(startTimestamp, endTimestamp);
        this.getPieData(startTimestamp, endTimestamp);

    }

    exportPDFWithMethod(){

        let element = this.container.current || document.body;
        savePDF(element, {
          paperSize: "auto",
          margin: 40,
          fileName: `Report for ${new Date().getFullYear()}`,
        });
      }
    
    exportPDFWithComponent(){
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
      };
  
    componentDidMount()
    {
       
       var isLoggedIn = localStorage.getItem("is_logged_in");
       var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
            if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
            }
            if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
            }   
       }else{
            this.props.history.push('/');    
       }

       this.getTableData('','');
       this.getChartData('','');
       this.getPieData('','');
    }
    

    getPieData = (startTimestamp, endTimestamp) => {

        this.setState({isLoading: true})
        fetch(`${url}/anomaly/security-analytics/pie?start_timestamp=${startTimestamp}&end_timestamp=${endTimestamp}`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
             }            
        })
         .then((response) => response.json())
         .then((json) => this.setState({piedata:json}))
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }
    
    getChartData = (startTimestamp, endTimestamp) => {

        this.setState({isLoading: true})
        fetch(`${url}/anomaly/security-analytics/chart?start_timestamp=${startTimestamp}&end_timestamp=${endTimestamp}`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
             }            
        })
         .then((response) => response.json())
         .then((json) => this.setState({data:json}))
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoadin: false})); 
    }

    getTableData = (startTimestamp, endTimestamp) => {

        this.setState({isLoading: true})
        fetch(`${url}/anomaly/security-analytics/table?start_timestamp=${startTimestamp}&end_timestamp=${endTimestamp}`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
             }            
        })
         .then((response) => response.json())
         .then((json) => {
             this.setState({widgets:json.widgets,tableData:json.table, filteredTableData:json.table})
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }
   
    COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#F80975','#135C21','#8C26A2','#7E5109','#7E5109','#7E5109'];

    RADIAN = Math.PI / 180;
    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
        const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
        
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };


    render() {
        return (
            <React.Fragment>

                    <Button
                        color="info"
                        className="waves-effect waves-light mr-1 pull-right"
                        onClick={()=> this.exportPDFWithComponent()}
                        style={{float:'right', display:'block'}}
                    >
                        Export to PDF
                 </Button>

                 <Row className="mt-4">
                        <h4>{this.props.heading}</h4>
                    </Row>
                    <Row className="mt-4" style={{marginLeft:10}}>
                        <AvForm inline >
                            Filter Data
                            <Col style={{display:'flex', flexDirection:'row'}}>                                

                                 <input type="date" name="start_date" value={this.state.startDatePlain} onChange={this.onStartDateChange}/>
                                 <input type="time" name="start_time" value={this.state.startTimePlain} onChange={this.onStartTimeChange}/> &nbsp; &nbsp;to &nbsp; &nbsp;
                                 <input type="date" name="end_date" value={this.state.endDatePlain} onChange={this.onEndDateChange} />
                                 <input type="time" name="end_time" value={this.state.endTimePlain} onChange={this.onEndTimeChange}/>
                              </Col>
                        </AvForm>
                    </Row>


                { this.state.isLoading ?
                    <div>
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={'100%'}   
                        timeout={5000} //3 secs
                    />  
                    </div>     
                :
                <div className="mainContaindiv">
                    


                    <PDFExport
                       ref={this.pdfExportComponent}
                       paperSize="auto"
                       margin={40}
                       fileName={`Report for ${new Date().getFullYear()}`}
                       author="KendoReact Team"
                    >

                    {!this.state.isLoading && this.state.tableData.length==0 ?

                       <h5 style={{marginTop:20, textAlign:'center'}}>No data found</h5>
                    :

                    <div className="mainContaindiv" style={{padding:50}}>    

                    


                    <Row className="mt-4">
                        {
                            this.state.widgets.map((item,index)=>{
                                return <Col key={index}>
                                            <Card>
                                                <CardBody>
                                                    <Media>
                                                        <Media body className="overflow-hidden">
                                                            <p className="font-size-14 mb-2 text-left">{item.name}</p>
                                                            <h4 className="mb-0 ">{item.value}</h4>
                                                        </Media>
                                                        <div className="text-primary">
                                                            <i className={" ri-stack-line font-size-24"}></i>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        </Col>
                            })   
                        }
                    </Row>

                    <Row>
                        <Col md={8}>
                            <h4>Alert Evolution- Top 2 Agents</h4>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={this.state.data}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="time" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    {
                                        this.state?.data?.[0]   &&
                                            Object.keys(this.state?.data?.[0]).map((key,index) => {
                                                if(key !== 'time')
                                                    return <Bar dataKey={key} stackId='a' fill={this.COLORS[index]} />
                                            })
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>
                        <Col md={4}>
                            <h4>Top Mitre ATT&K tactics</h4>
                            <ResponsiveContainer width="100%"  height={300}>
                            <PieChart width={400} height={300}>
                            <Pie
                                data={this.state.piedata}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={this.renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {this.state.piedata.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <h4>Security Alerts</h4>
                    </Row>

                    <Row className="mt-4">

                        <div data-test="datatable-input" class="mdb-datatable-filter flex-row">
                            <input class="form-control form-control-sm ml-0 my-1" 
                               type="text" 
                               placeholder="Search" 
                               aria-label="Search" 
                               onChange={(e) => {this.handleChange(e)}}
                               value={this.state.searchKey}/>
                        </div>
                    </Row>

                    <Row className="mt-4">                 


                        <MDBTable responsive>
                            

                            <MDBTableHead>
                                <tr>
                                    <th>Time</th>
                                    <th>VM Name</th>
                                    <th>rule.mitre.id</th>
                                    <th>rule.mitre.tactic</th>
                                    <th>Description</th>
                                    {/* <th>rule.level</th>
                                    <th>rule.id</th> */}
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {
                                    this.state?.filteredTableData?.map((item,index)=>{
                                        return <tr>
                                                    <td>{item?.timestamp}</td>
                                                    <td>{item?.agent?.name}</td>
                                                    <td title={`https://attack.mitre.org/techniques/${item?.rule_mitre_id?.[0]}`}>
                                                        <a href={`https://attack.mitre.org/techniques/${item?.rule_mitre_id?.[0]}`} target="_blank">
                                                            {item?.rule_mitre_id?.[0]}
                                                        </a>
                                                    </td>
                                                    <td>{item?.rule_mitre_tactic?.[0]}</td>
                                                    <td>{item?.rule_description}</td>                                                    
                                                </tr>
                                    })
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </Row>

                    </div>
    }
                    </PDFExport>
                </div>
            }       
            </React.Fragment>
        );
    }
}

export default AnomalyDetection;
