import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label, FormGroup } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError,setUserName } from '../../store/actions';

// import images
import logoDark from "../../assets/images/intrucept_logo.svg";
import logoLight from "../../assets/images/intrucept-white-logo.png";

import { useHistory } from "react-router-dom";
import {url} from '../../services/common';
import {Helmet} from "react-helmet";
import toastr from 'toastr'

class Login extends Component {

    constructor(props) {
    	
        super(props);
        
        this.state = {  
            email : "",
            password : "",
            loginError:'',
            isLoading:false
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.handleLoginResponse = this.handleLoginResponse.bind(this);
        this.performLogin = this.performLogin.bind(this);       
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);    
        this.handleKeyPress = this.handleKeyPress.bind(this);            
    }

    handleSubmit(event, values) {
        this.props.checkLogin(values, this.props.history);
    }
    
    // When enter key pressed
    handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        this.performLogin();
      }
    }

    componentDidMount(){
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
        
        var isLoggedIn = localStorage.getItem("is_logged_in");
        
        if(isLoggedIn){
        	 if(isLoggedIn == "yes"){
                this.props.history.push('/dashboard');       	 
        	 }
        }
    }

    componentWillUnmount(){
        document.body.classList.remove("auth-body-bg");
    }
    
     handleEmailChange(event) {    	

        this.setState({email: event.target.value});
     }
     
     handlePasswordChange(event) {
        this.setState({password: event.target.value});
     }

    
    // Peform login
    performLogin(){     
    
         this.setState({isLoading: true});      	 
    	   fetch(`${url}/login`, {
                  method: "POST",        
                  body:JSON.stringify({
                      email:this.state.email,
                      password:this.state.password
                  }),       
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },

               })
               .then((response) => response.json())
               .then((json) => this.handleLoginResponse(json))
               .catch((error) => console.error('ss'+error))
               .finally(() => this.setState({isLoading: false}));     
    }

    // Handle login response
    handleLoginResponse(json){
        if(json.success){
           localStorage.setItem('is_logged_in', 'yes');
           localStorage.setItem('auth_token', json.user.token);
           localStorage.setItem('email', json.user.email);
           localStorage.setItem('first_name', json.user.firstname);
           localStorage.setItem('last_name', json.user.lastname);
           localStorage.setItem('username', json.user.username);
           localStorage.setItem('userId', json.user.id);
           localStorage.setItem('user_type', json.user.user_type);
           localStorage.setItem('HasPerms', JSON.stringify(json.user.has_perms));
           
           this.props.setUserName(json.user.firstname);
           this.props.history.push('/dashboard');
            
        }else{
            if(json.error)
            {
                Object.keys(json.error).map((itme,index)=>{
                    json.error[itme].map((item2,index2)=>{
                        //toastr.error(item2,'Error');
                    })
                })
            }else{
                //toastr.error(json.message,'Error');
            }
        }    	
       
    }
    
    render() {

        return (
            <React.Fragment>
                <Helmet><title>Login</title></Helmet>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
                </div>
                
                <div>

            <Container fluid className="p-0">
                <Row className="no-gutters">

                    <Col lg={4} style={{backgroundColor:'white'}}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center" style={{paddingTop:40}}>
                                                <div>
                                                    <Link to="/" className="logo"><img src={logoDark} height="40" alt="logo"/></Link>
                                                </div>
    
                                                <h4 className="font-size-18 mt-4" style={{color:'black'}}>Sign in to continue</h4>
                                            </div>                                           

                                            {this.state.loginError && this.state.loginError ? <Alert color="danger">{this.state.loginError}</Alert> : null }

                                            <div className="p-2 mt-5">
                                                <AvForm className="form-horizontal" onValidSubmit={this.performLogin} >
                    
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="username">Email</Label>
                                                        <AvField name="username" value={this.state.email} 
                                                                 onChange={this.handleEmailChange} 
                                                                 type="text" 
                                                                 className="form-control" 
                                                                 id="username" 
                                                                 style={{color:'black', backgroundColor:'white'}}
                                                                 validate={{email: true, required: true}} 
                                                                 placeholder="Enter Email"/>
                                                    </FormGroup>
                            
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="userpassword">Password</Label>
                                                        <AvField name="password" 
                                                                 onKeyPress={this.handleKeyPress} 
                                                                 value={this.state.password} 
                                                                 onChange={this.handlePasswordChange}
                                                                 type="password" 
                                                                 className="form-control" 
                                                                 id="userpassword" 
                                                                 style={{color:'black', backgroundColor:'white'}}
                                                                 placeholder="Enter Password"/>
                                                    </FormGroup>
                            
                                                    <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                                        <Label className="custom-control-label" htmlFor="customControlInline">Remember me</Label>
                                                    </div>

                                                    <div className="mt-4 text-center" style={{width:'100%'}}>
                                                        <Button color="primary" 
                                                                className="w-md waves-effect waves-light" 
                                                                style={{width:'100%'}}
                                                        type="submit">LOGIN</Button>
                                                    </div>

                                                    {/* <div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                    </div> */}
                                                </AvForm>
                                            </div>

                                            <div className="mt-5 text-center">
                                                <p style={{display:'none'}}>Don't have an account ? <Link to="/register" className="font-weight-medium text-primary"> Register </Link> </p>
                                                <p style={{color:'black'}}>© 2023 All Rights Reserved by Intrucept Labs</p>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError,userfirstName } = state.Login;
    return { loginError,userfirstName };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError, setUserName })(Login));