export const fileIntegrity  =   [
                                    {
                                        date: "2021-09-13 00:00:29",
                                        file: "c:/tmp/credentials.txt",
                                        changes: 2,
                                        attrs: {
                                                event: "modified",
                                                size: "26",
                                                mode: 0,
                                                perm: "",
                                                uid: "",
                                                gid: "",
                                                md5: "6c2477ab9b4afc088cb7978f532ae834",
                                                sha1: "5e6f3d8a90824c3f2f63860059589ac1e8dedddb"
                                        }
                                    },
                                    {
                                        date: "2021-09-11 00:00:29",
                                        file: "c:/tmp/credentials.txt",
                                        changes: 2,
                                        attrs: {
                                            event: "modified",
                                            size: "25",
                                            mode: 0,
                                            perm: "",
                                            uid: "",
                                            gid: "",
                                            md5: "cdc7fc07b3b0d4cc36db9eb833ec61cb",
                                            sha1: "9fa7885a2212aae3cac4e386b296acacb182aa43"
                                        }
                                    }
                                ];

export const fileContent  =   [
    {
    "changes": 1,
    "date": "2021-09-13 00:00:29",
    "file": "/etc/sgml/xml-core.cat",
    "gid": "0",
    "gname": "root",
    "inode": 2896763,
    "md5": "055ba0bd3154c0a58b9bf8a0c9ecf2fa",
    "mtime": "2012-11-07T21:44:21Z",
    "perm": "100644",
    "sha1": "3dec5570307472381671ff18bbe4d4be09951690",
    "sha256": "3c46704b553c4b55ce928ffe89badfcfd08a02f0e6558211dfd57d9ae1e72aa4",
    "size": 45,
    "type": "file",
    "uid": "0",
    "uname": "root"
  },
  {
    "changes": 1,
    "date": "2021-09-11 00:00:29",
    "file": "/etc/sgml/xml-core.cat",
    "gid": "0",
    "gname": "root",
    "inode": 2896763,
    "md5": "055ba0bd3154c0a58b9bf8a0c9ecf2fa",
    "mtime": "2012-11-07T21:44:21Z",
    "perm": "100644",
    "sha1": "3dec5570307472381671ff18bbe4d4be09951690",
    "sha256": "3c46704b553c4b55ce928ffe89badfcfd08a02f0e6558211dfd57d9ae1e72aa4",
    "size": 45,
    "type": "file",
    "uid": "0",
    "uname": "root"
  },
  {
    "changes": 1,
    "date": "2021-07-10 00:00:29",
    "file": "/etc/sgml/xml-core.cat",
    "gid": "0",
    "gname": "root",
    "inode": 2896763,
    "md5": "055ba0bd3154c0a58b9bf8a0c9ecf2fa",
    "mtime": "2012-11-07T21:44:21Z",
    "perm": "100644",
    "sha1": "3dec5570307472381671ff18bbe4d4be09951690",
    "sha256": "3c46704b553c4b55ce928ffe89badfcfd08a02f0e6558211dfd57d9ae1e72aa4",
    "size": 45,
    "type": "file",
    "uid": "0",
    "uname": "root"
  },
  {
    "changes": 1,
    "date": "2020-09-09 00:00:29",
    "file": "/etc/sgml/xml-core.cat",
    "gid": "0",
    "gname": "root",
    "inode": 2896763,
    "md5": "055ba0bd3154c0a58b9bf8a0c9ecf2fa",
    "mtime": "2012-11-07T21:44:21Z",
    "perm": "100644",
    "sha1": "3dec5570307472381671ff18bbe4d4be09951690",
    "sha256": "3c46704b553c4b55ce928ffe89badfcfd08a02f0e6558211dfd57d9ae1e72aa4",
    "size": 45,
    "type": "file",
    "uid": "0",
    "uname": "root"
  },
  {
    "changes": 1,
    "date": "2020-01-04 00:00:29",
    "file": "c:/tmp/credentials.txt",
    "gid": "0",
    "gname": "root",
    "inode": 2896763,
    "md5": "055ba0bd3154c0a58b9bf8a0c9ecf2fa",
    "mtime": "2012-11-07T21:44:21Z",
    "perm": "100644",
    "sha1": "3dec5570307472381671ff18bbe4d4be09951690",
    "sha256": "3c46704b553c4b55ce928ffe89badfcfd08a02f0e6558211dfd57d9ae1e72aa4",
    "size": 45,
    "type": "file",
    "uid": "0",
    "uname": "root"
  },
]
  