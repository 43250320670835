import React, { Component } from "react";
import { Card, CardBody, Col, Row, Button, Container, FormGroup, Label, Input, CustomInput } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class ScheduleAnAWSScan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link : "#" },
                { title : "AWS Scans", link : "#" },
                { title : "Schedule a Scan", link : "#" },
            ],
            customchk: true,
            toggleSwitch: true
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Schedule an AWS Scan" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Scheduling an AWS Scan</h4>
                                        <p className="card-title-desc">Please get the Access Key ID and Secret Access Key from your AWS Console. Visit the Help section for more information</p>

                                        <FormGroup row>
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Scan Start Date</Label>
                                            <Col md={10}>
                                                <Input className="form-control" type="text" defaultValue="" id="example-text-input" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="example-search-input" className="col-md-2 col-form-label">Scan Start Time</Label>
                                            <Col md={10}>
                                                <Input className="form-control" type="search" defaultValue="" id="example-search-input" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="example-email-input" className="col-md-2 col-form-label">Access Key ID</Label>
                                            <Col md={10}>
                                                <Input className="form-control" type="email" defaultValue=""  id="example-email-input" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="example-url-input" className="col-md-2 col-form-label">Secret Access Key</Label>
                                            <Col md={10}>
                                                <Input className="form-control" type="url" defaultValue=""  id="example-url-input" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Button
                                               color="primary"
                                               className=" waves-effect waves-light mr-1 pull-right"
                                             >
                                                Schedule Scan
                                             </Button>   
                                        </FormGroup>   
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ScheduleAnAWSScan;
