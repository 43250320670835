import React, { Component } from 'react';
import { Col, Card, CardBody, Media } from "reactstrap";
import {url} from '../../services/common';
import Loader from "react-loader-spinner";

class MiniWidgets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports : [
                { icon : "ri-search-eye-fill", key:"allScans", title : "All Scans", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-amazon-line", key:"AwsScancount", title : "AWS Scans", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "fab fa-microsoft", key:"AzureScancount", title : "Azure Scans", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-google-fill", key:"GcpScancount", title : "GCP Scans", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-cloud-line", key:"OciScancount", title : "OCI Scans", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-error-warning-fill", key:"ThreatsFound", title : "Threats ", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-list-unordered", key:"categoryName", title : "Most Affected Category", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-global-fill", key:"regionName", title : "Most Affected Region", value : "0", rate : "2.4%", desc : "From previous period" },
            ],
            option: {
                scales: {
                    xAxes: [{
                        barPercentage: 0.4
                    }]
                }
            },
            scanType:'all',
            isLoading:false,
            loadingMessage:'Loading..',
        }
    }

    componentDidMount(){
        this.getCards();
    }
  
    getCards = () =>{
          var authToken = localStorage.getItem('auth_token');  
          this.setState({isLoading: true});    
          this.setState({loadingMessage: 'Loading...'});
          fetch(`${url}/get-dashboard-cards`, {
              method: "GET",  
              headers: {
                'Accept': 'application/json',  
                'Authorization': 'Bearer '+authToken                  
               }            
           })
           .then((response) => response.json())
           .then((json) => {
              let {reports}    =   this.state;
              let scans       =   json.scans
              Object.keys(scans).map((itme,index)=>{
                reports.map((item2,index2)=>{
                    if(item2.key == itme)
                    {
                        item2.value = scans[itme]
                    }
                })
             })
            this.setState({reports});
           })
           .catch((error) => console.error('ss'+error))
           .finally(() => this.setState({isLoading: false})); 
      }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.reports.map((report, key) =>
                        <Col className="card-col" key={key} md={3}>
                            <Card>
                                <CardBody>
                                    <Media>
                                        <Media body className="overflow-hidden">
                                            <p className="font-size-13 mb-2">{report.title}</p>

                                            <h4 className="mb-0">{report.value==0?'---':report.value}</h4>

                                        </Media>
                                        <div className="text-primary">
                                            <i className={report.icon + " font-size-24"}></i>
                                        </div>
                                    </Media>
                                </CardBody>                                           
                            </Card>
                        </Col>
                        )
                }
            </React.Fragment>
        );
    }
}

export default MiniWidgets;