import React, { Component } from "react";
import { Media,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { AreaChart,Area,PieChart,Pie,BarChart, Bar, Cell,Label, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBDataTable,MDBTable, MDBTableBody, MDBTableHead  } from "mdbreact";
import {url} from '../../services/common';
import {fileContent} from './dummy.js';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";


class FileIntegrityMonitoring extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            activeTab: 1,
            isLoading:false,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            widgets:[],
            tableData:[],
            piedata:[],
            startDate:null,
            endDate:null,
            searchKey:'',
            filteredTableData:[],
        }

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);
    
        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }

    ref = React.createRef();
    options = {
        orientation: 'landscape',
        
    };
    

    exportPDFWithMethod(){

        let element = this.container.current || document.body;
        savePDF(element, {
          paperSize: "auto",
          margin: 40,
          fileName: `Report for ${new Date().getFullYear()}`,
        });
      }
    
    exportPDFWithComponent(){
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
      };


    handleChange(e){

        this.setState({searchKey:e.target.value})  
       
        var tData = this.state.tableData;
        var newTable =[];
        for(var i=0;i<tData.length;i++){
            if((tData[i].description.toLowerCase().includes(e.target.value.toLowerCase())) 
            || (tData[i].name.toLowerCase().includes(e.target.value.toLowerCase()))
            ){
                newTable.push(tData[i]);
            }
        }

        this.setState({filteredTableData:newTable})       

    }

    onStartDateChange(e){
        this.setState({endDate:null})
        this.setState({startDate:new Date(e.target.value)})
    }

    onEndDateChange(e){
        //console.log(e.target.value)
        this.setState({endDate:new Date(e.target.value)})
    }
  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
       this.getChartData()
       this.getTableData()
    }

    getChartData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/file-integrity-monitoring/chart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({data:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoadin: false})); 
    }

    getTableData = () => {

        this.setState({isLoading: true})
        fetch(`${url}/anomaly/file-integrity-monitoring/table`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            
            this.setState({tableData:json.table, filteredTableData:json.table})
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false}));
         
    }

    render() {

        const data = {
            columns: [
              {
                label: "Title",
                field: "title",
                sort: "asc",
                width: 30
              },
              {
                label: "agent.name",
                field: "agentname",
                sort: "asc",
                width: 30
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 150
              },
              {
                label: "rule.mitre.tactic",
                field: "rulemitretactic",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.description",
                field: "ruledescription",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.level",
                field: "rulemitrelevel",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 100
              }
            ],
            rows: [
      
            ]
          };
      
          var theData = {};
          theData.columns = data.columns;
          var theRows = this.state.tableData;
          theData.rows = theRows;

        return (
            <React.Fragment>

                <Button
                        color="info"
                        className="waves-effect waves-light mr-1 pull-right"
                        onClick={()=> this.exportPDFWithComponent()}
                        style={{float:'right', display:'block'}}
                    >
                        Export to PDF
                 </Button>


                { this.state.isLoading ?
                    <div>
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={'100%'}   
                        timeout={5000} //3 secs
                    />  
                    </div>     
                :
                    <div className="mainContaindiv" ref={this.ref}>
                        <Row className="mt-4">
                            <h4>{this.props.heading}</h4>
                        </Row>
                        <Row className="mt-4">
                        <AvForm inline >
                            Filter Data
                            <Col style={{display:'flex', flexDirection:'row'}}>                                

                                 <input type="date" name="start_date" onChange={this.onStartDateChange}/><input type="time" name="start_time" onChange={this.onStartTimeChange}/> &nbsp; &nbsp;to &nbsp; &nbsp;
                                 <input type="date" name="end_date" onChange={this.onEndDateChange} /><input type="time" name="end_time" onChange={this.onEndTimeChange}/>
                              </Col>
                        </AvForm>
                        </Row>

                        <PDFExport
                       ref={this.pdfExportComponent}
                       paperSize="auto"
                       margin={40}
                       fileName={`Report for ${new Date().getFullYear()}`}
                       author="KendoReact Team"
                    >

                    <div className="mainContaindiv" style={{padding:50}}>   
                    
                        <Row className="mt-4">
                            <Col>
                                <h4>Alert by action over time (files added, modified and deleted</h4>
                                <ResponsiveContainer width="100%" height={400}>
                                    <AreaChart
                                    width={500}
                                    height={400}
                                    data={this.state.data}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    
                                    <YAxis label={{ value: 'count', angle: -90, position: 'insideLeft' }} />
                                    <Tooltip />
                                    <Legend />
                                        <Area type="monotone" dataKey="modified" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                        <Area type="monotone" dataKey="added" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                                        <Area type="monotone" dataKey="deleted" stackId="1" stroke="#ffc658" fill="#ffc658" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                        
                        {
                            fileContent?.map((item,index)=>{
                                return<>
                                        <h4  className="mt-4">{item.file}</h4>
                                        <Row className="mt-2">
                                                <Col className="mt-2" md={3}>
                                                    <h6>Last Analysis</h6>
                                                    <p style={{textAlign:'left'}}>{item.date}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>Last Modified</h6>
                                                    <p style={{textAlign:'left'}}>{item.mtime}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>User</h6>
                                                    <p style={{textAlign:'left'}}>{item.uname}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>User Id</h6>
                                                    <p style={{textAlign:'left'}}>{item.uid}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>Group</h6>
                                                    <p style={{textAlign:'left'}}>{item.gname}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>Group Id</h6>
                                                    <p style={{textAlign:'left'}}>{item.gid}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>Permissions</h6>
                                                    <p style={{textAlign:'left'}}>{item.perm}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>Size</h6>
                                                    <p style={{textAlign:'left'}}>{item.size}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>Inode</h6>
                                                    <p style={{textAlign:'left'}}>{item.inode}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>MD5</h6>
                                                    <p style={{textAlign:'left'}}>{item.md5}</p>
                                                </Col>
                                                <Col className="mt-2" md={3}>
                                                    <h6>SHA1</h6>
                                                    <p style={{textAlign:'left'}}>{item.sha1}</p>
                                                </Col>
                                        </Row>
                                </>
                            })
                        }
                        


                        <h4 className="mt-4">Recent Events</h4>

                        <Row className="mt-4">

                        <div data-test="datatable-input" class="mdb-datatable-filter flex-row">
                            <input class="form-control form-control-sm ml-0 my-1" 
                               type="text" 
                               placeholder="Search" 
                               aria-label="Search" 
                               onChange={(e) => {this.handleChange(e)}}
                               value={this.state.searchKey}/>
                        </div>
                        </Row>


                        <Row  className="mt-2">
                            <Col>
                                <MDBTable responsive>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Action</th>
                                            <th>Description</th>
                                            {/* <th>Level</th>
                                            <th>Rule Id</th> */}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                            this.state?.filteredTableData?.map((item,index)=>{
                                                return <tr>
                                                            <td>{item?.timestamp}</td>
                                                            <td>{item?.name}</td>
                                                            <td>{item?.description}</td>
                                                            {/* <td>{item?.rule_level}</td>
                                                            <td>{item?.rule_id}</td> */}
                                                        </tr>
                                            })
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </Col>
                        </Row>

                        </div>
                        </PDFExport>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default FileIntegrityMonitoring;
