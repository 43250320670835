import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Button } from "reactstrap";
import {url} from '../../services/common';
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import toastr from 'toastr';
import { CopyBlock } from "react-code-blocks";
import {Helmet} from "react-helmet";


class HowToGetKeys extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "How to get cloud keys", link : "#" },
            ],
        };
    }   
    

    render() {
        return (
            <React.Fragment>
                <Helmet><title>How to get cloud keys</title></Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="How to get cloud keys" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xs={12}>
                                <div>
                                    <p style={{color:"#e50202",fontSize:'16px', textAlign:'left'}}>Intrucept CSPM will not have any write access to any areas of your cloud infrastructure. None of the below steps are granting any such access.</p>
                                </div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Steps to get AWS keys</h4>
                                        
                                        <ol>
                                           <li>Log into your AWS account as an admin or with permission to create IAM resources.</li>
                                           
                                           <li>Navigate to the IAM console.</li>

                                           <li>Click on Users.</li>
                                           
                                           <li>Create a new user (Add user).</li>
                                           
                                           <li>Set the username to intrucept-labs.</li>
                                           
                                           <li>Set the access type to "Programmatic access", click Next.</li>
                                           
                                           <li>Select "Attach existing policies directly" and select the SecurityAudit policy.</li>

                                           <li>Click "Create policy" to create a supplemental policy (some permissions are not included in SecurityAudit).</li>
                                           
                                           <li>Click the "JSON" tab and paste the following permission set.</li>
                                           
                                          <code>
                                           {'{"Version": "2012-10-17","Statement": [{ "Effect": "Allow", "Action": [ "ses:DescribeActiveReceiptRuleSet", "athena:GetWorkGroup", "logs:DescribeLogGroups", "logs:DescribeMetricFilters", "elastictranscoder:ListPipelines", "elasticfilesystem:DescribeFileSystems", "servicequotas:ListServiceQuotas" ], "Resource": "*" } ] }'}
                                          </code>
                                           
                                          
                                           
                                           <li>Click "Review policy."</li>
                                           
                                           <li>Provide a name (IntruceptCSPMSupplemental) and click "Create policy."</li>
                                           
                                           <li>Return to the "Create user" page and attach the newly-created policy. Click "Next: tags."</li>
                                           
                                           <li>Set tags as needed and then click on "Create user".</li>
                                           
                                           <li>Make sure you safely store the Access key ID and Secret access key.</li>                                          
                                           
                                        
                                        </ol>
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 
                        
                         
                         <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Steps to get Azure keys</h4>
                                        
                                        <ol>
                                           <li>Log into your Azure Portal and navigate to the Azure Active Directory service.</li>
                                           
                                           <li>Select App registrations and then click on New registration.</li>

                                           <li>Enter "IntruceptCSPM" and/or a descriptive name in the Name field, take note of it, it will be used again in step 3.</li>
                                           
                                           <li>Leave the "Supported account types" default: "Accounts in this organizational directory only (YOURDIRECTORYNAME)".</li>

                                           <li>Click on Register.</li>

                                           <li>Copy the Application ID and Paste it below.</li>

                                           <li>Copy the Directory ID and Paste it below.</li>

                                           <li>Click on Certificates & secrets.</li>

                                           <li>Under Client secrets, click on New client secret.</li>

                                           <li>Enter a Description (i.e. IntruceptCSPM-2023) and select Expires "In 1 year".</li>

                                           <li>Click on Add.</li>

                                           <li>The Client secret value appears only once, make sure you store it safely.</li>

                                           <li>Navigate to Subscriptions.</li>

                                           <li>Click on the relevant Subscription ID, copy and paste the ID below.</li>

                                           <li>Click on "Access Control (IAM)".</li>

                                           <li>Go to the Role assignments tab.</li>

                                           <li>Click on "Add", then "Add role assignment".</li>

                                           <li>In the "Role" drop-down, select "Security Reader".</li>

                                           <li>Leave the "Assign access to" default value.</li>

                                           <li>In the "Select" drop-down, type the name of the app registration (e.g. "IntruceptCSPM") you created and select it.</li>

                                           <li>Click "Save".</li>

                                           <li>Repeat the process for the role "Log Analytics Reader"</li>
                                        
                                        </ol>
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 
                        
                        
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-subtitle">Steps to get Google Cloud Platform keys</h4>
                                        <br/>
                                        <h5 className="card-title">Create Security Audit Role</h5>                                        

                                        <ol>
                                           

                                           <li>Log into your Google Cloud console and "Activate" your Cloud Shell.</li>                                     
                                          
                                           <li>Create a new file called intrucept-cspm-security-audit-role.yaml. You can use: nano intrucept-cspm-security-audit-role.yaml.</li>
                                           
                                           
                                           <li>Copy and paste the following yaml code in the file on your Cloud Shell, press Ctrl + X and type "Y" to save the file.</li>

                         
                                           <h6 style={{marginTop:30, textDecoration:'underline'}}>If using an organization ID</h6>

                                           <br/><br/>


                                           <code>
                                           <span>name: roles/Intrucept CSPMCSPMSecurityAudit</span><br/>
 														 <span>title: Intrucept CSPM Security Audit</span>
  														 <span> - includedPermissions:</span><br/>
                                           <span>- cloudkms.cryptoKeys.list</span><br/>
                                           <span>- cloudkms.keyRings.list</span><br/>
   													 <span>- cloudsql.instances.list</span><br/>
   													 <span>- cloudsql.users.list</span><br/>
   													 <span>- compute.autoscalers.list</span><br/>
   													 <span>- compute.backendServices.list</span><br/>
   													 <span>- compute.disks.list</span><br/>
   													 <span>- compute.firewalls.list</span><br/>
   													 <span>- compute.healthChecks.list</span><br/>
   													 <span>- compute.instanceGroups.list</span><br/>
   													 <span>- compute.instances.getIamPolicy</span><br/>
  														 <span>- compute.instances.list</span><br/>
   													 <span>- compute.networks.list</span><br/>
   													 <span>- compute.projects.get</span><br/>
   													 <span>- compute.securityPolicies.list</span><br/>
   													 <span>- compute.subnetworks.list</span><br/>
   													 <span>- compute.targetHttpProxies.list</span><br/>
   												    <span>- container.clusters.list</span><br/>
   													 <span>- dns.managedZones.list</span><br/>
   													 <span>- iam.serviceAccountKeys.list</span><br/>
   													 <span>- iam.serviceAccounts.list</span><br/>
   													 <span>- logging.logMetrics.list</span><br/>
   													 <span>- logging.sinks.list</span><br/>
   													 <span>- monitoring.alertPolicies.list</span><br/>
   												 	 <span>- resourcemanager.folders.get</span><br/>
   													 <span>- resourcemanager.folders.getIamPolicy</span><br/>
   													 <span>- resourcemanager.folders.list</span><br/>
   													 <span>- resourcemanager.hierarchyNodes.listTagBindings</span><br/>
   													 <span>- resourcemanager.organizations.get</span><br/>
   													 <span>- resourcemanager.organizations.getIamPolicy</span><br/>
   												 	 <span>- resourcemanager.projects.get</span><br/>
   													 <span>- resourcemanager.projects.getIamPolicy</span><br/>
   													 <span>- resourcemanager.projects.list</span><br/>
   													 <span>- resourcemanager.resourceTagBindings.list</span><br/>
   													 <span>- resourcemanager.tagKeys.get</span><br/>
   													 <span>- resourcemanager.tagKeys.getIamPolicy</span><br/>
   													 <span>- resourcemanager.tagKeys.list</span><br/>
   													 <span>- resourcemanager.tagValues.get</span><br/>
   													 <span>- resourcemanager.tagValues.getIamPolicy</span><br/>
   													 <span>- resourcemanager.tagValues.list</span><br/>
   													 <span>- storage.buckets.getIamPolicy</span><br/>
   													 <span>- storage.buckets.list</span><br/>
   													 <span>stage: GA</span>      

                                                     </code>                                     
                                          
                                           
                                           <h6 style={{marginTop:30, textDecoration:'underline'}}>If using a project ID</h6>

                                           <br/><br/>


                                           <code>
                                           <span>name: roles/AquaCSPMSecurityAudit</span><br/>
                                           <span>title: Aqua CSPM Security Audit</span><br/>
                                           <span>includedPermissions:</span><br/>
                                               <span>- cloudasset.assets.listResource</span><br/>
                                               <span>- cloudkms.cryptoKeys.list</span><br/>
                                               <span>- cloudkms.keyRings.list</span><br/>
                                               <span>- cloudsql.instances.list</span><br/>
                                               <span>- cloudsql.users.list</span><br/>
                                               <span>- compute.autoscalers.list</span><br/>
                                               <span>- compute.backendServices.list</span><br/>
                                               <span>- compute.disks.list</span><br/>
                                               <span>- compute.firewalls.list</span><br/>
                                               <span>- compute.healthChecks.list</span><br/>
                                               <span>- compute.instanceGroups.list</span><br/>
                                               <span>- compute.instances.getIamPolicy</span><br/>
                                               <span>- compute.instances.list</span><br/>
                                               <span>- compute.networks.list</span><br/>
                                               <span>- compute.projects.get</span><br/>
                                               <span>- compute.subnetworks.list</span><br/>
                                               <span>- compute.targetHttpProxies.list</span><br/>
                                               <span>- container.clusters.list</span><br/>
                                               <span>- dns.managedZones.list</span><br/>
                                               <span>- iam.serviceAccountKeys.list</span><br/>
                                               <span>- iam.serviceAccounts.list</span><br/>
                                               <span>- logging.logMetrics.list</span><br/>
                                               <span>- logging.sinks.list</span><br/>
                                               <span>- monitoring.alertPolicies.list</span><br/>
                                               <span>- resourcemanager.hierarchyNodes.listTagBindings</span><br/>
                                               <span>- resourcemanager.projects.get</span><br/>
                                               <span>- resourcemanager.projects.getIamPolicy</span><br/>
                                               <span>- resourcemanager.resourceTagBindings.list</span><br/>
                                               <span>- resourcemanager.tagKeys.get</span><br/>
                                               <span>- resourcemanager.tagKeys.getIamPolicy</span><br/>
                                               <span>- resourcemanager.tagKeys.list</span><br/>
                                               <span>- resourcemanager.tagValues.get</span><br/>
                                               <span>- resourcemanager.tagValues.getIamPolicy</span><br/>
                                               <span>- resourcemanager.tagValues.list</span><br/>
                                               <span>- storage.buckets.getIamPolicy</span><br/>
                                               <span>- storage.buckets.list</span><br/>
                                            <span>stage: GA</span><br/>
                                            </code>



                                           <li style={{marginTop:20}}>If using an organization ID, Run the following command to create the role, use your Organization Id to create the Role at the Org Level: gcloud iam roles create
                                           IntruceptCSPMCSPMSecurityAudit --organization=YOUR_ORGANIZATION_ID --file=intrucept-cspm-security-audit-role.yaml</li>
                                          
                                           <li>If using a project ID, Run the following command to create the role, 
                                            use your project ID to create the Role at the Project Level: gcloud iam roles create
                                           IntruceptCSPMCSPMSecurityAudit --project=YOUR_PROJECT_ID --file=intrucept-cspm-security-audit-role.yaml</li>
                                          
                                           
                                        
                                        </ol>

                                        
                                           

                                        <br/>
                                        <h5 className="card-title">Create Service Account</h5>
                                        
                                        <ol>
                                         <li>Log into your Google Cloud console and navigate to IAM Admin  Service Accounts.</li>
                                         <li>Click on "Create Service Account".</li>
                                         <li>Enter "IntruceptCSPM" in the "Service account name", then enter "IntruceptCSPM API Access" in the description.</li>
                                         <li>Click on Continue.</li>
                                         <li>Select the role: Custom Intrucept CSPM Security Audit.</li>
                                         <li>Click on Continue.</li>
                                         <li>Click on "Create Key".</li>
                                         <li>Leave the default JSON selected.</li>
                                         <li>Click on "Create".</li>
                                         <li>The key will be downloaded to your machine.</li>
                                        
                                        </ol>
                                        

                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 


                         <Row style={{display:'none'}}>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Steps to get Oracle Cloud Infrastructure keys</h4>
                                        
                                        <ol>
                                           <li>Log into your AWS account as an admin or with permission to create IAM resources.</li>
                                           
                                           <li>Navigate to the IAM console.</li>

                                           <li>Click on Users.</li>
                                           
                                           <li>Create a new user (Add user).</li>
                                           
                                           <li>Set the username to intrucept-cspm.</li>
                                           
                                           <li>Set the access type to "Programmatic access", click Next.</li>
                                           
                                           <li>Select "Attach existing policies directly" and select the SecurityAudit policy.</li>

                                           <li>Click "Create policy" to create a supplemental policy (some permissions are not included in SecurityAudit).</li>
                                           
                                           <li>Click the "JSON" tab and paste the following permission set.</li>
                                           
                                          
                                           
                                           <li>Click "Review policy."</li>
                                           
                                           <li>Provide a name (IntruceptCSPMSupplemental) and click "Create policy."</li>
                                           
                                           <li>Return to the "Create user" page and attach the newly-created policy. Click "Next: tags."</li>
                                           
                                           <li>Set tags as needed and then click on "Create user".</li>
                                           
                                           <li>Make sure you safely store the Access key ID and Secret access key.</li>
                                           

                                           
                                           
                                        
                                        </ol>
                                        
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 

                        
                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default HowToGetKeys;
