import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import CookieConsent, { Cookies } from "react-cookie-consent";

// Import scss
import "./theme.scss";

//import '@progress/kendo-theme-default/dist/all.css';

//Fake backend
import fakeBackend from './helpers/AuthType/fakeBackend';

//Firebase helper
import { initFirebaseBackend } from "./helpers/firebase_helper";

// Activating fake backend
fakeBackend();

const firebaseConfig = {
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: process.env.REACT_APP_AUTHDOMAIN,
	databaseURL: process.env.REACT_APP_DATABASEURL,
	projectId: process.env.REACT_APP_PROJECTID,
	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_APPID,
	measurementId: process.env.REACT_APP_MEASUREMENTID,
};
  
// init firebase backend
//initFirebaseBackend(firebaseConfig);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {  };
		this.getLayout = this.getLayout.bind(this);
	}
 
 	/**
	 * Returns the layout
	 */
	getLayout = () => {
		let layoutCls = VerticalLayout;
		 
		switch (this.props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	render() {
		const Layout = this.getLayout();
		return (
			<React.Fragment>
				<CookieConsent
					location="bottom"
					buttonText="Accept"
					cookieName="threatSpottercookie"
					style={{ background: "#2B373B" }}
					buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
					expires={150}
					enableDeclineButton
					>
					This website uses cookies to enhance the user experience.{" "}
				</CookieConsent>
				
				<Router>
					<Switch>
						{publicRoutes.map((route, idx) => {
							return <AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								exact={true}
							/>
						})}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
