import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label,Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import { connect } from 'react-redux';
import { setUserName } from '../../store/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {url} from '../../services/common';
import toastr from 'toastr'
import {Helmet} from "react-helmet";

class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "My Account", link : "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            user:[],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        let authToken   = localStorage.getItem('auth_token'); 
        let userId      = localStorage.getItem('userId'); 
        fetch(`${url}/edit-user/${userId}`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {
            let {user} =  this.state;
            user =  json.users
            this.setState({user})
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
      }
    
      handleSubmit(event, values) {
        let authToken = localStorage.getItem('auth_token'); 
        let userId      = localStorage.getItem('userId'); 
        fetch(`${url}/update-user`, {
            method: "POST",        
            body:JSON.stringify({
                id:userId,
                firstname: values.firstname,
                lastname: values.lastname,
                username: values.username,
            }),       
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+authToken  
            },
         })
         .then((response) => response.json())
         .then((json) => {
            if(json.success){
                this.props.setUserName(values.firstname);
                localStorage.setItem('username', values.username);
                localStorage.setItem('first_name', values.firstname);
                localStorage.setItem('last_name', values.lastname);
                //toastr.success('Your profile is updated!','Success');
            }else{
                //toastr.error(json.message,'Error');
                if(json.error){
                    Object.keys(json.error).map((itme,index)=>{
                        json.error[itme].map((item2,index2)=>{
                            //toastr.error(item2,'Error');
                        })
                    })
                }
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  
      }

    render() {
        return (
            <React.Fragment>
                <Helmet><title>My Account</title></Helmet>
                <div className="page-content">
                    <Container fluid className="my-account">

                        <Breadcrumbs title="My Account" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col md={{ size: 6}} lg={6}>
                                <Card>
                                <CardBody>
                                    <p className="card-title-desc">
                                </p>
                                    <AvForm onValidSubmit={this.handleSubmit} className="form-horizontal" >
                                        <FormGroup className="auth-form-group-custom mb-4">
                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                            <Label htmlFor="firstName">First Name</Label>
                                            <AvField name="firstname" value={this.state.user && this.state.user.firstname} validate={{required: true}} type="text" className="form-control" id="firstName" placeholder="Enter First Name"/>
                                        </FormGroup>
                                        
                                            <FormGroup className="auth-form-group-custom mb-4">
                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                            <Label htmlFor="lastname">Last Name</Label>
                                            <AvField name="lastname" value={this.state.user && this.state.user.lastname} validate={{required: true}} type="text" className="form-control" id="lastname" placeholder="Enter Last Name"/>
                                        </FormGroup>
                                        
                                        <FormGroup className="auth-form-group-custom mb-4">
                                            <i className="ri-mail-line auti-custom-input-icon"></i>
                                            <Label htmlFor="email">Email</Label>
                                            <AvField name="email" value={this.state.user && this.state.user.email} validate={{email: true, required: true}} readOnly={true} type="email" className="form-control" id="email" placeholder="Enter Email"/>
                                        </FormGroup>

                                        <FormGroup className="auth-form-group-custom mb-4">
                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                            <Label htmlFor="username">Username</Label>
                                            <AvField name="username" value={this.state.user && this.state.user.username} validate={{required: true}} type="text" className="form-control" id="username" placeholder="Enter username"/>
                                        </FormGroup>

                                        <FormGroup className="mb-0">
                                            <Col className="noLeftRightPadding" sm={{ size:12, offset:0, paddingLeft:0, paddingRight:0 }}>
                                                <Button type="submit" color="primary" className="mr-1" style={{width:'100%'}}>
                                                    Update Info
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </AvForm>
                                </CardBody>
                                </Card>
                            </Col>
                        </Row>                     
                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { userfirstName } = state.Login;
    return { userfirstName };
}

export default connect(mapStatetoProps, { setUserName })(MyAccount);
