import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Row, Col, Card, Modal,Media, Container, ModalHeader, ModalBody, ModalFooter, CardBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../Tables/datatables.scss";

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from "react-loader-spinner";
//Import Charts
import ReactApexChart from 'react-apexcharts';
import {url,Downloadurl} from '../../services/common';
import "../Dashboard/dashboard.scss";
import toastr from 'toastr'
import {Helmet} from "react-helmet";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import './pdfStyles.css';

class ClusterResultHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
    breadcrumbItems : [
        { title : "Dashboard", link : "/dashboard" },
        { title : "Cluster History", link : "#" },
    ],
    series: [42.0, 26.0, 15.0],
    options : {
        labels: ["OK", "UNKNOWN", "FAILED"],
        plotOptions: {
            pie: {
                donut: {
                    size: '75%'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
        },
        colors: ['#0EC905', '#FFC300', '#CA0B00'],
    
    },
    scanId:this.props.match.params.id,
    tableData:[
      {
        "id":1,
        "version": 1,
        "platform": "EKS",
        "category": "Host and Cluster Security",
        "resource": "GKE",
        "title": "GKE Workload Identity should be enabled and enforcing metadata protection on all NodePools",
        "description": "Currently, all pods have the ability to reach the Instance Metadata API corresponding to the underlying node.  By extension, those pods can access the APIs and data used to bootstrap the Kubernetes worker node.  The credentials used to bootstrap a Kubernetes worker node are very commonly sufficient to be used to privilege escalate to `cluster-admin`.  Also by extension, this means that every container image ever run in this cluster in the non-production namespace has had the ability to reach and export these credentials.  Therefore, it's very important for a cluster's security posture to prevent pods from being able to reach the Instance Metadata API to fetch those bootstrapping credentials.",
        "remediation": "Configure Workload Identity on the cluster and every node pool in the cluster with the GKE_METADATA setting enabled.  Alternatively, deploy an egress NetworkPolicy blocking egress to 169.254.169.254 for all non-kube-system namespaces.",
        "validation": "Run `gcloud container clusters describe <clustername> --format=json | jq -r 'select(.workloadIdentityConfig.workloadPool | test(\"svc.id.goog\")) | .name'` and ensure that the cluster's name is listed.",
        "severity": 0.9,
        "effort": 0.5,
        "result": "passed"
      },
      {
        "id":2,
        "version": 1,
        "platform": "AKS",
        "category": "Host and Cluster Security",
        "resource": "GKE",
        "title": "GKE Workload Identity should be enabled and enforcing metadata protection on all NodePools",
        "description": "Currently, all pods have the ability to reach the Instance Metadata API corresponding to the underlying node.  By extension, those pods can access the APIs and data used to bootstrap the Kubernetes worker node.  The credentials used to bootstrap a Kubernetes worker node are very commonly sufficient to be used to privilege escalate to `cluster-admin`.  Also by extension, this means that every container image ever run in this cluster in the non-production namespace has had the ability to reach and export these credentials.  Therefore, it's very important for a cluster's security posture to prevent pods from being able to reach the Instance Metadata API to fetch those bootstrapping credentials.",
        "remediation": "Configure Workload Identity on the cluster and every node pool in the cluster with the GKE_METADATA setting enabled.  Alternatively, deploy an egress NetworkPolicy blocking egress to 169.254.169.254 for all non-kube-system namespaces.",
        "validation": "Run `gcloud container clusters describe <clustername> --format=json | jq -r 'select(.workloadIdentityConfig.workloadPool | test(\"svc.id.goog\")) | .name'` and ensure that the cluster's name is listed.",
        "severity": 0.9,
        "effort": 0.5,
        "result": "failed"
      } 
    ],
    isLoading:false,
    loadingMessage:'',
    showResultModal:false,
    currentlyShowingScan:{},
    currentScanId:null,
    currentScanName:'',
    show:false,
    Editshow:false,
    scanName:'',
    };
    
    this.setTheTableData = this.setTheTableData.bind(this); 
    this.showTheResult = this.showTheResult.bind(this); 

    this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
    this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);

    this.container = React.createRef();
    this.pdfExportComponent = React.createRef();
  }
  
  child   = { width: `30em`, height: `100%`}
  
  ref = React.createRef();
  
  options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4,2]
  };

  componentDidMount()
  {
    //  console.log(this.state.scanId)
  }

  exportPDFWithMethod(){

    let element = this.container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  }

  exportPDFWithComponent(){
    
     if (this.pdfExportComponent.current) {
        this.pdfExportComponent.current.save();
     }
  };

  setTheTableData(json){  	 
        this.setState({tableData: json.awsScans});  
  }

  showTheResult(ScanId, scanName)
  {  	 
      this.setState({isLoading: true})
      this.setState({loadingMessage: 'Loading ...'});    
      let authToken = localStorage.getItem('auth_token');  	 
      fetch(`${url}/get-aws/threats/${ScanId}`, {
                  method: "GET",     
                  headers: {
                    'Accept': 'application/json',  
                    'Authorization': 'Bearer '+authToken                  
                  }            
              })
              .then((response) => response.json())
              .then((json) => {
                let {currentlyShowingScan,showResultModal,currentScanId, currentScanName}  = this.state;
                if(json.success)
                {
                    currentlyShowingScan  = json.data
                    currentScanId  = ScanId
                    currentScanName  = scanName
                    this.setPieData(currentlyShowingScan);
                    this.setState({currentlyShowingScan,showResultModal: true,currentScanId, currentScanName},()=>{
                      //console.log(currentlyShowingScan);
                      //console.log(showResultModal);
                    });   
                }else{
                    this.setState({isLoading: false})
                }
              })
              .catch((error) => console.error('ss'+error))
              .finally(() => this.setState({isLoading: false})); 
  }
  
  render() 
  {

    const data = {
      columns1: [       
        {
          label: "Version",
          field: "version",
          sort: "asc",
          width: 30
        },
        {
          label: "Platform",
          field: "platform",
          sort: "asc",
          width: 30
        },
        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 30
        },      
        {
          label: "Severity",
          field: "severity",
          sort: "asc",
          width: 30
        },      
        {
          label: "Result",
          field: "result",
          sort: "asc",
          width: 30
        },      
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 30
        }      
      ],
      rows: [
      ]
    };

    var theResult = {};
    theResult.columns = data.columns1;
    theResult.rows = this.state.tableData;
    
    return (

      <React.Fragment>

        
      <Helmet><title>Cluster Results</title></Helmet>
      {this.state.isLoading &&
         <div>
          <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
          <Loader
             type="Bars"
             color="#00BFFF"
             height={100}
             width={'100%'}   
             timeout={5000} //3 secs
          />  
         </div>     
      }
      
      {!this.state.isLoading &&

<PDFExport
ref={this.pdfExportComponent}
paperSize="auto"
margin={40}
fileName={`Report for ${new Date().getFullYear()}`}
 author="KendoReact Team"
>
      
        <div className="page-content" >
        <Container fluid >
        <Breadcrumbs title={`Cluster Results`} breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <Col md={4}>
                  <Card>
                      <CardBody>
                          <Media>
                              <Media body className="overflow-hidden">
                                  <p className="font-size-14 mb-2 text-left">FAILED</p>
                                  <h4 className="mb-0 ">1</h4>
                              </Media>
                              <div className="text-primary">
                                  <i className={" ri-stack-line font-size-24"}></i>
                              </div>
                          </Media>
                      </CardBody>
                  </Card>
              </Col>
              <Col md={4}>
                  <Card>
                      <CardBody>
                          <Media>
                              <Media body className="overflow-hidden">
                                  <p className="font-size-14 mb-2 text-left">PASSED</p>
                                  <h4 className="mb-0 ">1</h4>
                              </Media>
                              <div className="text-primary">
                                  <i className={" ri-stack-line font-size-24"}></i>
                              </div>
                          </Media>
                      </CardBody>
                  </Card>
              </Col>
            </Row>
            <Row style={{marginBottom:15}}>
                <Col xs={12}>  
                    <Button
                        color="success"
                        onClick={()=> this.exportPDFWithComponent()}
                        className="waves-effect waves-light mr-1 pull-right"
                        style={{float:'right'}}
                    >
                        Export to PDF
                    </Button> 

                    <Button
                        color="primary"
                        onClick={()=>{this.props.history.push('/kubernetes/all/cluster')}}
                        className="waves-effect waves-light mr-1 pull-right"
                        style={{float:'right'}}

                    >
                      All Clusters
                    </Button> 
                </Col>
            </Row>
            
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                     <h4 className="card-title">Total vulnerabilities: 2 </h4>
                    <MDBDataTable responsive bordered data={theResult} />
                  </CardBody>
                </Card>
              </Col>
            </Row> 
          </Container>
        </div>

        </PDFExport>
       }
      </React.Fragment>

    );
  }
}

export default ClusterResultHistory;
