import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Container,Button } from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../Tables/datatables.scss";
import {url} from '../../services/common';
import SweetAlert from "react-bootstrap-sweetalert";
import {Helmet} from "react-helmet";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "Users", link : "/all-users" },
        { title : "All Users", link : "#" },
      ],
	  datatables:[],
    deleteUserId:null,
    isLoading:false,
    loadingMessage:'',
    success_dlg: false,
		error_dlg: false,
    confirm_both:false,
    };
  }

  componentDidMount(){
	  this.getUsersData();
  }

  getUsersData = () => {
      this.setState({isLoading: true});    
      this.setState({loadingMessage: 'Loading ...'});  
      var authToken = localStorage.getItem('auth_token'); 
      fetch(`${url}/get-all-users`, {
        method: "GET",     
        headers: {
          'Accept': 'application/json',  
          'Authorization': 'Bearer '+authToken                  
        }            
      })
      .then((response) => response.json())
      .then((json) => {
          let {datatables} =  this.state;
          datatables =  json.users
          this.setState({datatables,isLoading: false})
      })
      .catch((error) => console.error('ss'+error))
      .finally(() => this.setState({isLoading: false})); 
  }

  deleteHandler = (id)  =>  {
    const {deleteUserId} = this.state
    var authToken = localStorage.getItem('auth_token'); 
    fetch(`${url}/delete-user/${deleteUserId}`, {
      method: "POST",     
      headers: {
        'Accept': 'application/json',  
        'Authorization': 'Bearer '+authToken                  
      }            
    })
    .then((response) => response.json())
    .then((json) => {
      let {datatables} =  this.state;
      if(json.success){
          datatables =  json.users
          this.setState({
            datatables,
            confirm_both: false,
            success_dlg: true,
            dynamic_title: "Deleted",
            dynamic_description: "User has been deleted."
          })
      }else{
        this.setState({
          confirm_both: false,
          error_dlg: true,
          dynamic_title: "Cancelled",
          dynamic_description: "User is safe :)"
        })
      }
    })
    .catch((error) => console.error('ss'+error))
    .finally(() => {console.error('')}); 
  }

  cancelDeleteHandler = ()  =>  {
      this.setState({
        confirm_both: false,
        error_dlg: true,
        dynamic_title: "Cancelled",
        dynamic_description: "User is safe :)"
      })
  }

  render() {

    const data = {
      columns: [
        {
          label: "First Name",
          field: "firstname",
          sort: "asc",
          width: 150
        },
        {
          label: "Last Name",
          field: "lastname",
          sort: "asc",
          width: 270
        },
        {
          label: "Company Name",
          field: "company_name",
          sort: "asc",
          width: 270
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 200
        },
        {
          label: "Actions",
          field: "action",
          sort: "asc",
          width: 100
        }
      ],
      rows: [
      ]
    };

	var theData = {};
	theData.columns = data.columns;
	var theRows = this.state.datatables;
	if(theRows)
	{
		for(var i=0;i<theRows.length;i++)
		{
			let id    =   theRows[i].id; 
			theRows[i].action = <div><Link to={`/edit-user/${id}`} ><Button className="mb-2" color="info" size="sm">Edit</Button>{' '}</Link> <Button className="mb-2" color="danger" size="sm" onClick={() => this.setState({ confirm_both: true,deleteUserId:id })}>Delete</Button></div>
		}  
	}
	theData.rows = theRows;

return (
      <React.Fragment>
        <Helmet><title>All Users</title></Helmet>
        <div className="page-content">
        {
          this.state.isLoading &&
            <div>
              <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
              <Loader
                type="Bars"
                color="#00BFFF"
                height={100}
                width={'100%'}   
              />  
            </div> 
        }

        {
          !this.state.isLoading &&
            <Container fluid>
              <Breadcrumbs title="All Users" breadcrumbItems={this.state.breadcrumbItems} />
              {this.state.success_dlg ? (
                <SweetAlert
                  success
                  title={this.state.dynamic_title}
                  onConfirm={() => this.setState({ success_dlg: false })}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null}

              {this.state.error_dlg ? (
                <SweetAlert
                  error
                  title={this.state.dynamic_title}
                  onConfirm={() => this.setState({ error_dlg: false })}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null}
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <MDBDataTable responsive bordered data={theData} />
                    </CardBody>
                  </Card>
                  {
                    this.state.confirm_both ? (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={this.deleteHandler}
                        onCancel={this.cancelDeleteHandler}
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    ) : null
                  }
                </Col>
              </Row>
            </Container>
        }
        </div>
      </React.Fragment>
    );
  }
}

export default Users;
