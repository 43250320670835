import React, { Component } from "react";
import { Media,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { PieChart,Pie,BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBDataTable } from "mdbreact";
import {url} from '../../services/common';

class ContainersSecurity extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            activeTab: 1,
            isLoading:false,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            widgets:[],
            tableData:[],
            piedata:[],
        }
    }
  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
       this.getPieData()
       this.getChartData()
       this.getTableData()
    }

    COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    RADIAN = Math.PI / 180;
    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
        const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
        
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    getPieData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/containers-security/pie`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({piedata:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }
    
    getChartData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/containers-security/chart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({data:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoadin: false})); 
    }

    getTableData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/containers-security/table`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({widgets:json.widgets,tableData:json.table})
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }

    render() {

        const data = {
            columns: [
              {
                label: "Title",
                field: "title",
                sort: "asc",
                width: 30
              },
              {
                label: "agent.name",
                field: "agentname",
                sort: "asc",
                width: 30
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 150
              },
              {
                label: "rule.mitre.tactic",
                field: "rulemitretactic",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.description",
                field: "ruledescription",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.level",
                field: "rulemitrelevel",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 100
              }
            ],
            rows: [
      
            ]
          };
      
          var theData = {};
          theData.columns = data.columns;
          var theRows = this.state.tableData;
          theData.rows = theRows;

        return (
            <React.Fragment>
                { 
                this.state.isLoading ?
                    <div>
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={'100%'}   
                        timeout={5000} //3 secs
                    />  
                    </div>     
                :
                    <div style={{width:'100%'}}>
                        <Row className="mt-4">
                            <h4>{this.props.heading}</h4>
                        </Row>
                        <Row className="mt-4" style={{display:'none'}}>
                            <AvForm inline >
                                <Col><AvField type="text" placeholder="Search"  name="clusterName" required />{' '}</Col>
                                <Col><AvField type="date" name="AZURE_CLIENT_ID" required />{' '}</Col>
                                <Col><Button outline color="primary" type="submit">Refresh</Button></Col>
                            </AvForm>
                        </Row>

                        <Row className="mt-4">
                            <Col md={6}>
                                <h4>Top 5 Events</h4>
                                <ResponsiveContainer width="100%"  height={300}>
                                    <PieChart width={400} height={300}>
                                    <Pie
                                        data={this.state.piedata}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={this.renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {this.state.piedata.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>
                            <Col md={6}>
                                <h4>Events by source over time</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.data}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="container" stackId="a" fill="#8884d8" />
                                        <Bar dataKey="image" stackId="a" fill="#82ca9d" />
                                        <Bar dataKey="network" stackId="a" fill="#F80975" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <h4>Security Alerts</h4>
                            <MDBDataTable responsive bordered data={theData} />
                        </Row>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default ContainersSecurity;
