import React, { Component } from "react";
import { Card,Alert,CardBody, Col, Row, Container, FormGroup, Label, Button} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { AvForm, AvField } from "availity-reactstrap-validation";
import {url} from '../../services/common';
import toastr from 'toastr'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Cloud API Keys", link : "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            isLoading:false,
            toastMessage:'',
            keys: {
                aws_accessKeyId: null,
                aws_secretAccessKey: null,
                azure_ApplicationID: null,
                azure_DirectoryID: null,
                azure_KeyValue: null,
                azure_SubscriptionID: null,
                created_at: null,
                gcp_client_email: null,
                gcp_private_key: null,
                gcp_project: null,
                gcp_type: null,
                oracle_oci_compartmentId: null,
                oracle_oci_keyFingerprint: null,
                oracle_oci_keyValue: null,
                oracle_oci_tenancyId: null,
                oracle_oci_userId: null,
            }
        };
    }

    componentDidMount()
    {
       this.getUserKey();
    }

    getUserKey = () => {
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/get-user-keys`, {
            method: "GET",       
            headers: {
                'Accept': 'application/json',  
                'Authorization': 'Bearer '+authToken   
            },
         })
         .then((response) => response.json())
         .then((json) => {
             if(json.success)
             {
                var { keys } = this.state
                keys.aws_accessKeyId              = json.keys.aws_accessKeyId 
                keys.aws_secretAccessKey          = json.keys.aws_secretAccessKey
                keys.azure_ApplicationID          = json.keys.azure_ApplicationID
                keys.azure_DirectoryID            = json.keys.azure_DirectoryID
                keys.azure_KeyValue               = json.keys.azure_KeyValue
                keys.azure_SubscriptionID         = json.keys.azure_SubscriptionID
                keys.created_at                   = json.keys.created_at
                keys.gcp_client_email             = json.keys.gcp_client_email
                keys.gcp_private_key              = json.keys.gcp_private_key
                keys.gcp_project                  = json.keys.gcp_project
                keys.gcp_type                     = json.keys.gcp_type
                keys.oracle_oci_compartmentId     = json.keys.oracle_oci_compartmentId
                keys.oracle_oci_keyFingerprint    = json.keys.oracle_oci_keyFingerprint
                keys.oracle_oci_keyValue          = json.keys.oracle_oci_keyValue
                keys.oracle_oci_tenancyId         = json.keys.oracle_oci_tenancyId
                keys.oracle_oci_userId            = json.keys.oracle_oci_userId
                this.setState({keys})
             }
             
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));
    }

    handleAWSSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-aws-keys`, {
            method: "POST",        
            body:JSON.stringify({
                aws_accessKeyId:values.aws_accessKeyId,
                aws_secretAccessKey:values.aws_secretAccessKey
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
             if(json.success){
               // toastr.success(json.message, 'Success');
             }else{
               // toastr.error(json.message, 'Error');
             }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }

    handlegcpSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-gcp-keys`, {
            method: "POST",        
            body:JSON.stringify({
                gcp_client_email: values.gcp_client_email,
                gcp_private_key: values.gcp_private_key,
                gcp_project: values.gcp_project,
                gcp_type: values.gcp_type,
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
             if(json.success){
                //toastr.success(json.message, 'Success');
             }else{
               // toastr.error(json.message, 'Error');
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }

    handleazureSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-azure-keys`, {
            method: "POST",        
            body:JSON.stringify({
                azure_ApplicationID : values.azure_ApplicationID,
                azure_DirectoryID   : values.azure_DirectoryID,
                azure_KeyValue      : values.azure_KeyValue,
                azure_SubscriptionID : values.azure_SubscriptionID,
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
            if(json.success){
               // toastr.success(json.message, 'Success');
            }else{
               // toastr.error(json.message, 'Error');
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }
    
    handleociSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-oci-keys`, {
            method: "POST",        
            body:JSON.stringify({
                oracle_oci_compartmentId: values.oracle_oci_compartmentId,
                oracle_oci_keyFingerprint: values.oracle_oci_keyFingerprint,
                oracle_oci_keyValue: values.oracle_oci_keyValue,
                oracle_oci_tenancyId: values.oracle_oci_tenancyId,
                oracle_oci_userId: values.oracle_oci_userId,
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
            if(json.success){
               // toastr.success(json.message, 'Success');
            }else{
                //toastr.error(json.message, 'Error');
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }

    render() {
        return (
            <React.Fragment>
                <Helmet><title>Cloud API Keys</title></Helmet>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Cloud API Keys" breadcrumbItems={this.state.breadcrumbItems} />
                         <Row>
                            <Col xs={12}>
                               <Link className="pull-right" style={{paddingBottom:10, fontSize:17, float:'right', textDecoration:'underline'}} to="/how-to-get-cloud-keys">How to get cloud keys?</Link>
                            </Col>
                         </Row>      
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h2 className="card-title" style={{fontSize:20, textTransform:'uppercase', marginBottom:20}}>AWS Keys</h2>
                                        <AvForm onValidSubmit={this.handleAWSSubmit} className="form-horizontal" >
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Access Key ID</Label>
                                                
                                                <Col md={10}>
                                                    <AvField className="form-control" name="aws_accessKeyId" value={this.state.keys.aws_accessKeyId} validate={{required: true}} type="text"  />
                                                </Col>
                                            </FormGroup>
                                            
                                            <FormGroup row>
                                                <Label  className="col-md-2 col-form-label">Secret Access Key</Label>
                                                <Col md={10}>
                                                    <AvField className="form-control" name="aws_secretAccessKey" value={this.state.keys.aws_secretAccessKey} validate={{required: true}} type="text"  />
                                                </Col>
                                            </FormGroup>
                                            
                                            <FormGroup row>
                                                <Col style={{textAlign:"right"}}>
                                                    <Button
                                                        color="primary"
                                                        className=" waves-effect waves-light mr-1 pull-right"
                                                        type="submit"
                                                    >
                                                        Save AWS Keys
                                                    </Button>   
                                                </Col>
                                            </FormGroup>  
                                        </AvForm> 
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 
                        
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title" style={{fontSize:20, textTransform:'uppercase', marginBottom:20}}>Google Cloud Platform Keys</h4>
                                        <AvForm onValidSubmit={this.handlegcpSubmit} className="form-horizontal" >
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Project</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="gcp_project" value={this.state.keys.gcp_project} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Client Email</Label>
                                            <Col md={10}>
                                            <AvField className="form-control" name="gcp_client_email" value={this.state.keys.gcp_client_email} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Private Key</Label>
                                            <Col md={10}>
                                            <AvField className="form-control" name="gcp_private_key" value={this.state.keys.gcp_private_key} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Col style={{textAlign:"right"}}>
                                                <Button
                                                color="primary"
                                                className=" waves-effect waves-light mr-1 pull-right"
                                                >
                                                    Save Google Cloud Platform Keys
                                                </Button>   
                                            </Col> 
                                        </FormGroup>
                                        </AvForm>   
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>    
                        
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title" style={{fontSize:20, textTransform:'uppercase', marginBottom:20}}>Azure Keys</h4>
                                        <AvForm onValidSubmit={this.handleazureSubmit} className="form-horizontal" >
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Application ID</Label>
                                            <Col md={10}>
                                            <AvField className="form-control" name="azure_ApplicationID" value={this.state.keys.azure_ApplicationID} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Secret Key Value</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="azure_KeyValue" value={this.state.keys.azure_KeyValue} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Directory ID</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="azure_DirectoryID" value={this.state.keys.azure_DirectoryID} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Subscription ID</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="azure_SubscriptionID" value={this.state.keys.azure_SubscriptionID} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Col style={{textAlign:"right"}}>
                                                <Button
                                                color="primary"
                                                className=" waves-effect waves-light mr-1 pull-right"
                                                >
                                                    Save Azure Keys
                                                </Button>  
                                             </Col> 
                                        </FormGroup> 
                                        </AvForm> 
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title" style={{fontSize:20, textTransform:'uppercase', marginBottom:20}}>Oracle Cloud Infrastructure Keys</h4>
                                        <AvForm onValidSubmit={this.handleociSubmit} className="form-horizontal" >
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Tenancy ID</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="oracle_oci_tenancyId" value={this.state.keys.oracle_oci_tenancyId} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Compartment ID</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="oracle_oci_compartmentId" value={this.state.keys.oracle_oci_compartmentId} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">User ID</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="oracle_oci_userId" value={this.state.keys.oracle_oci_userId} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Key Fingerprint</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="oracle_oci_keyFingerprint" value={this.state.keys.oracle_oci_keyFingerprint} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Key Value</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="oracle_oci_keyValue" value={this.state.keys.oracle_oci_keyValue} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Col style={{textAlign:"right"}}>
                                                <Button
                                                color="primary"
                                                className=" waves-effect waves-light mr-1 pull-right"
                                                >
                                                    Save OCI Keys
                                                </Button>   
                                             </Col>
                                        </FormGroup>   
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>  
                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Settings;
