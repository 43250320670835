import React, { Component } from "react";
import { connect } from "react-redux";
import {Button} from "reactstrap";
import { Link } from "react-router-dom";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
//Import i18n
import { withNamespaces } from "react-i18next";
// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//Import logo Images
import logosmdark from "../../assets/images/intrucept-white-logo.png";
import logodark from "../../assets/images/intrucept-white-logo.png";
import logosmlight from "../../assets/images/intrucept-white-logo.png";
import logolight from "../../assets/images/intrucept-white-logo.png";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isSocialPf: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }


  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <Link to="#" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logosmdark} alt="" height="30"/>
                                </span>
                                <span className="logo-lg">
                                    <img src={logodark} alt="" height="30"/>
                                </span>
                            </Link>

                            <Link to="#" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logosmlight} alt="" height="30"/>
                                </span>
                                <span className="logo-lg">
                                    <img src={logolight} alt="" height="30"/>
                                </span>
                            </Link>
                        </div>

                    </div>
                      <div className="d-flex">
                        
                        <NotificationDropdown/>
                        
                        <ProfileMenu/>
                    </div>
                </div>
            </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
