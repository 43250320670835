import React, { Component } from "react";
import {Label, Modal, ModalHeader, ModalBody, ModalFooter,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader,Carousel, CarouselItem, CarouselControl } from "reactstrap";
//Import Breadrumb
import Select from 'react-select';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import SecurityAnalytics from './SecurityAnalytics'
import IntrusionDetection from './IntrusionDetection'
import LogDataAnalysis from './LogDataAnalysis'
import FileIntegrityMonitoring from './FileIntegrityMonitoring'
import VulnerabilityDetection from './VulnerabilityDetection'
import ConfigurationAssessment from './ConfigurationAssessment'
import IncidentResponse from './IncidentResponse'
import RegulatoryCompliance from './RegulatoryCompliance'
import CloudSecurityMonitoring from './CloudSecurityMonitoring'
import ContainersSecurity from './ContainersSecurity'
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import './anomaly.css'
import {url} from '../../services/common';
import toastr from 'toastr'
import { MDBDataTable } from "mdbreact";


class AnomalyDetectionAgents extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            options : {
                labels: ['Critical', 'Low', 'Medium', 'High', 'Info'],
                colors: ["#34c38f", "#556ee6","#f46a6a", "#50a5f1", "#f1b44c"],
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    floating: false,
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: -10
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240
                        },
                        legend: {
                            show: false
                        },
                    }
                }]
              
              },
            series: [44, 55, 41, 17, 15],
            authToken:localStorage.getItem('auth_token'),
            agentShow:false,
            agentserviceShow:false,
            activeTab: 1,
            os:null,
            arch:'none',
            activeTabComponet: null,
            activeIndex:0,
            agents:[],
            infrastructures:[],
            tableData:[]
        }
    }    

  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
     
       this.getAgents();       
    }


    toggle1 = (tab,component) => {
        
        if(this.state.agents?.length > 0){
            this.setState({
                activeTab: tab,
                activeTabComponet:component,
            });
        }else{
            this.setState({
                activeTab: tab,
                activeTabComponet:null,
            });
        }
	}

    agentToggale = () => {
        this.setState({agentShow:!this.state.agentShow})
    }

    agentserviceToggale = () => {
        this.setState({agentserviceShow:!this.state.agentserviceShow})
    }

    deleteAgent = (agentId) => {

        // Do nothing
    }

    getAgents = () => {

        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Loading agents'});  

        fetch(`${url}/anomaly/all-agent`, {
            method: "GET",     
            headers: {
            'Accept': 'application/json',  
            'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({agents: json.agents})
            if(this.state.agents.length > 0){
                this.setState({tableData:json.agents})    
            }
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    } 


    


    render() {

        const dataAgents = {
            columns: [
              {
                label: "OS",
                field: "os",
                sort: "asc",                
                width: 30
              },
              {
                label: "Agent Name",
                field: "agent_name",
                sort: "asc",
                width: 30
              },
              {
                label: "Agent IP",
                field: "agent_ip",
                sort: "asc",
                width: 30
              },
              {
                label: "Architecture",
                field: "architecture",
                sort: "asc",
                width: 30
              },                          
              {
                label: "Created At",
                field: "created_at",
                sort: "asc",
                width: 100
              },  
                  
              
            ],
            rows: [
      
            ]
          };

        var theData = {};
        theData.columns = dataAgents.columns;
        var theRows = this.state.tableData;

       /* if(theRows){
            for(var i=0;i<this.state.tableData.length;i++){
              let agentId = theRows[i].id
              theRows[i].action = <div>                                    
                                    <Button color="danger" 
                                            size="sm"
                                            onClick={()=>this.deleteAgent(agentId)}
                                    >Delete Agent</Button>{' '}
                                  </div>
            }    
        }*/


        theData.rows = theRows;           

        const child = { width: `300em`, height: `100%`}
        const RedhatOptions = ["CentOS 5","CentOS 6 or Higher"];
        const DebianOptions = ["i386","x86_64","armhf","aarch64"];

        return (
            <React.Fragment>
                <Helmet><title>Anomaly Detection - Agents</title></Helmet>

                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Anomaly Detection - Agents" 
                           breadcrumbItems={this.state.breadcrumbItems} />                                                  
                       
                    </Container> 

                    <Container>

                    <Row>

                            <Col md={12}>
                                <MDBDataTable 
                                   responsive searching={true} 
                                   bordered 
                                   data={theData} />
                            </Col>

                   </Row>
                 
                    </Container>
                    
                </div>
                
               
                
            </React.Fragment>
        );
    }
}

export default AnomalyDetectionAgents;
