import React, { Component } from "react";
import { Media,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { PieChart,Pie,BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBDataTable } from "mdbreact";
import {url} from '../../services/common';

class LogDataAnalysis extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            activeTab: 1,
            isLoading:false,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            tableData:[],
        }
    }
  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
       this.getChartData()
       this.getTableData()
    }

    getChartData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/log-data-analysis/chart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({data:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoadin: false})); 
    }

    getTableData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/log-data-analysis/table`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({tableData:json.table})
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }

    render() {

        const data = {
            columns: [
              {
                label: "Title",
                field: "title",
                sort: "asc",
                width: 30
              },
              {
                label: "agent.name",
                field: "agentname",
                sort: "asc",
                width: 30
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 150
              },
              {
                label: "rule.mitre.tactic",
                field: "rulemitretactic",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.description",
                field: "ruledescription",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.level",
                field: "rulemitrelevel",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 100
              }
            ],
            rows: [
      
            ]
          };
      
          var theData = {};
          theData.columns = data.columns;
          var theRows = this.state.tableData;
          theData.rows = theRows;

        return (
            <React.Fragment>
              { this.state.isLoading ?
                    <div>
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={'100%'}   
                        timeout={5000} //3 secs
                    />  
                    </div>     
                :
                <div style={{width:'100%'}}>
                    <Row className="mt-4">
                      <h4>{this.props.heading}</h4>
                    </Row>
                    <Row className="mt-4" style={{display:'none'}}>
                        <AvForm inline >
                            <Col><AvField type="text" placeholder="Search"  name="clusterName" required />{' '}</Col>
                            <Col><AvField type="date" name="AZURE_CLIENT_ID" required />{' '}</Col>
                            <Col><Button outline color="primary" type="submit">Refresh</Button></Col>
                        </AvForm>
                    </Row>

                    <Row className="mt-4">
                        <Col md={6}>
                            <div>
                                <h4>Attack tactics by agent</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                    width={500}
                                    height={300}
                                    data={this.state.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Credential Access" fill="#8884d8" />
                                    <Bar dataKey="Defense Evasion" fill="#82ca9d" />
                                    <Bar dataKey="Execution" fill="#9E6E25" />
                                    <Bar dataKey="Lateral Movement" fill="#25349E" />
                                    <Bar dataKey="Persistence" fill="#9E2539" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Col>
                        <Col md={6} >
                                <h5>Exploit Public-Facing Application</h5>
                                <MDBDataTable searching={true} responsive bordered data={theData} />
                        </Col>
                    </Row>
                    <Row>
                      <Col md={6} >
                                <h5>Recent Events</h5>
                                <MDBDataTable searching={true} responsive bordered data={theData} />
                      </Col>
                      <Col md={6}>
                            <h5>Apache: Attempt to access forbidden directory index</h5>
                            <MDBDataTable searching={true} responsive bordered data={theData} />
                      </Col>
                    </Row>
                </div>
              }
            </React.Fragment>
        );
    }
}

export default LogDataAnalysis;
