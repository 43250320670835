import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Row, Col, Card, Modal, Container, ModalHeader, ModalBody, ModalFooter, CardBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../Tables/datatables.scss";

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from "react-loader-spinner";
//Import Charts
import ReactApexChart from 'react-apexcharts';
import { url, Downloadurl } from '../../services/common';
import "../Dashboard/dashboard.scss";
import toastr from 'toastr'
import { Helmet } from "react-helmet";


class AWSScans extends Component {

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Dashboard", link: "/dashboard" },
        { title: "AWS Scans History", link: "#" },
      ],
      series: [42.0, 26.0, 15.0, 5],
      options: {
        labels: ["OK", "UNKNOWN", "FAILED", "WARN"],
        plotOptions: {
          pie: {
            donut: {
              size: '75%'
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          position: 'right', // Change the position to 'right'   
          labels: {
            colors: '#fff', // Set the legend text color to white
          }
        },
        colors: ['#75AD6F', '#FFBE00', '#E3071D', '#FF7E01'],

      },
      tableData: [],
      isLoading: false,
      loadingMessage: '',
      showResultModal: false,
      currentlyShowingScan: {},
      currentScanId: null,
      currentScanName: '',
      show: false,
      scanName: '',
      pciEnabled: false,
      cisEnabled: false,
      hipaaEnabled: false,
    };

    this.setTheTableData = this.setTheTableData.bind(this);
    this.showTheResult = this.showTheResult.bind(this);
    this.goBack = this.goBack.bind(this);
    this.createScan = this.createScan.bind(this);
    this.setPieData = this.setPieData.bind(this);
    this.downloadpdf = this.downloadpdf.bind(this);

    this.pciChanged = this.pciChanged.bind(this);
    this.cisChanged = this.cisChanged.bind(this);
    this.hipaaChanged = this.hipaaChanged.bind(this);
  }

  child = { width: `30em`, height: `100%` }

  ref = React.createRef();

  options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4, 2]
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.setState({ loadingMessage: 'Loading Scan History' });
    var authToken = localStorage.getItem('auth_token');
    fetch(`${url}/fetch-amazon-scans`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authToken
      }
    })
      .then((response) => response.json())
      .then((json) => this.setTheTableData(json))
      .catch((error) => console.error('ss' + error))
      .finally(() => this.setState({ isLoading: false }));

  }

  setTheTableData(json) {

    const userAgent = window.navigator.userAgent;
    if (userAgent.includes("MSIE")) {
      //setBrowser("Internet Explorer");
      this.setState({ tableData: (json.awsScans).reverse() });
    } else if (userAgent.includes("Edge")) {
      //setBrowser("Microsoft Edge");
      this.setState({ tableData: (json.awsScans).reverse() });
    } else if (userAgent.includes("Firefox")) {
      //setBrowser("Mozilla Firefox");
      this.setState({ tableData: json.awsScans });
    } else if (userAgent.includes("Chrome")) {
      //setBrowser("Google Chrome");
      this.setState({ tableData: (json.awsScans).reverse() });
    } else if (userAgent.includes("Safari")) {
      //setBrowser("Apple Safari");
      this.setState({ tableData: json.awsScans });
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      //setBrowser("Opera");
      this.setState({ tableData: json.awsScans });
    } else {
      //setBrowser("Unknown Browser");
      this.setState({ tableData: json.awsScans });
    }

  }

  goBack(json) {
    this.setState({ showResultModal: false });
  }

  pciChanged(e) {
    this.setState({ pciEnabled: e.target.checked });
  }

  cisChanged(e) {
    this.setState({ cisEnabled: e.target.checked });
  }

  hipaaChanged(e) {
    this.setState({ hipaaEnabled: e.target.checked });
  }

  showTheResult(ScanId, scanName) {
    this.setState({ isLoading: true })
    this.setState({ loadingMessage: 'Loading ...' });
    let authToken = localStorage.getItem('auth_token');
    fetch(`${url}/get-aws/threats/${ScanId}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authToken
      }
    })
      .then((response) => response.json())
      .then((json) => {
        let { currentlyShowingScan, showResultModal, currentScanId, currentScanName } = this.state;
        if (json.success) {
          currentlyShowingScan = json.data
          currentScanId = ScanId
          currentScanName = scanName
          this.setPieData(currentlyShowingScan);
          this.setState({ currentlyShowingScan, showResultModal: true, currentScanId, currentScanName }, () => {
            //console.log(currentlyShowingScan);
            //console.log(showResultModal);
          });
        } else {
          this.setState({ isLoading: false })
        }
      })
      .catch((error) => console.error('ss' + error))
      .finally(() => this.setState({ isLoading: false }));
  }

  setPieData(pieData) {

    let ok = 0;
    let UNKNOWN = 0;
    let FAIL = 0;
    let WARN = 0;
    let total = pieData.length;
    var { series } = this.state
    for (var i = 0; i < pieData.length; i++) {
      if (pieData[i].status === "OK") {
        ok = ok + 1;
      } else if (pieData[i].status === "UNKNOWN") {
        UNKNOWN = UNKNOWN + 1;
      } else if (pieData[i].status === "FAIL") {
        FAIL = FAIL + 1;
      } else if (pieData[i].status === "WARN") {
        WARN = WARN + 1;
      }
    }
    series = [
      parseFloat(((ok * 100) / total).toFixed(2)),
      parseFloat(((UNKNOWN * 100) / total).toFixed(2)),
      parseFloat(((FAIL * 100) / total).toFixed(2)),
      parseFloat(((WARN * 100) / total).toFixed(2))
    ];

    this.setState({
      series: series
    })
  }

  createScan() {
    this.setState({ isLoading: true });
    this.setState({ show: false });
    this.setState({ loadingMessage: 'Performing the scan' });
    var authToken = localStorage.getItem('auth_token');

    fetch(`${url}/create-aws-scan`, {
      method: "POST",
      body: JSON.stringify({
        scanName: this.state.scanName,
        isHipaaEnabld: this.state.hipaaEnabled,
        isCISEnabld: this.state.cisEnabled,
        isPCIEnabld: this.state.pciEnabled,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken
      }
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          this.setState({ tableData: json.AllawsScan });
          //toastr.success(json.message,'Success');

          // Reset all state values for the next scan to be fresh
          this.setState({ pciEnabled: false });
          this.setState({ cisEnabled: false });
          this.setState({ hipaaEnabled: false });

        } else {
          //toastr.error(json.message,'Error');

          // Reset all state values for the next scan to be fresh
          this.setState({ pciEnabled: false });
          this.setState({ cisEnabled: false });
          this.setState({ hipaaEnabled: false });
        }
      })
      .catch((error) => console.error('ss' + error))
      .finally(() => this.setState({ isLoading: false }));
  }
  downloadpdf() {
    var userId = localStorage.getItem('userId');
    let scanId = this.state.currentScanId;
    window.open(`${Downloadurl}/pdf-aws-download?scan_id=${scanId}&user_id=${userId}`, '_blank')
  }
  toggle = () => {
    var { show } = this.state
    this.setState({ show: !show })
  }

  render() {

    const data = {
      columns: [
        {
          label: "Scan ID",
          field: "id",
          sort: "desc",
          width: 30
        },
        {
          label: "Scan Name",
          field: "scan_name",
          width: 30
        },
        {
          label: "Started At",
          field: "start_datetime",
          width: 270
        },
        {
          label: "Completed At",
          field: "end_datetime",
          width: 200
        },
        {
          label: "Scan Status",
          field: "status",
          width: 100
        },
        {
          label: "Threats Found",
          field: "threats_found",
          width: 150
        },
        {
          label: "Actions",
          field: "action",
          width: 100
        }
      ],
      columns1: [
        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 30
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 30
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 30
        },
        {
          label: "Region",
          field: "region",
          sort: "asc",
          width: 30
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 30
        },

        {
          label: "Compliance Comment",
          field: "compliance_comment",
          sort: "asc",
          width: 50
        },
      ],
      rows: [

      ]
    };

    var theData = {};
    theData.columns = data.columns;

    var theRows = this.state.tableData;
    if (theRows) {
      for (var i = 0; i < this.state.tableData.length; i++) {

        if (theRows[i].status === "Pending") {
          theRows[i].action = <MDBBtn color="purple" size="sm">CANCEL</MDBBtn>
        }


        if (theRows[i].status === "Completed") {
          let id = theRows[i].id;
          let name = theRows[i].scan_name;

          if (theRows[i].threats_found > 0) {

            theRows[i].action = <MDBBtn color="purple"
              onClick={(theRows) => this.showTheResult(id, name)}
              size="sm">VIEW REPORT</MDBBtn>
          }
        }

        if (theRows[i].status === "Cancelled") {
          theRows[i].action = <MDBBtn color="purple" size="sm">RESUME</MDBBtn>
        }

      }
    }

    theData.rows = theRows;

    var theResult = {};
    if (this.state.showResultModal) {
      theResult.columns = data.columns1;
      theResult.rows = this.state.currentlyShowingScan;
    }


    return (
      <React.Fragment>
        <Helmet><title>AWS Scans</title></Helmet>
        {this.state.isLoading &&
          <div>
            <h3 style={{ color: 'white', textAlign: 'center', width: '100%', marginTop: '25%' }}>{this.state.loadingMessage}</h3>
            <Loader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={'100%'}
              timeout={5000} //3 secs
            />
          </div>
        }

        {!this.state.isLoading &&

          <div className="page-content" >
            <Container fluid className="scan-history-page">
              <Breadcrumbs title="AWS Scans" breadcrumbItems={this.state.breadcrumbItems} />
              {this.state.showResultModal &&
                <Row>
                  <Col xs={12}>
                    <Button
                      color="success"
                      onClick={this.downloadpdf}
                      className="waves-effect waves-light mr-1 pull-right"
                      style={{ float: 'right' }}
                    >
                      Export to PDF
                    </Button>

                    <Button
                      color="primary"
                      onClick={this.goBack}
                      className="waves-effect waves-light mr-1 pull-right"
                      style={{ float: 'right' }}
                    >
                      Back to All Scans
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <h5>Scan #{this.state.currentScanId} - {this.state.currentScanName}</h5>
                    <Card>
                      <CardBody>
                        <div id="donut-chart" className="apex-charts">
                          <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="230" />
                        </div>

                      </CardBody>
                    </Card>
                    <MDBDataTable responsive bordered data={theResult} />
                  </Col>
                </Row>
              }

              {!this.state.showResultModal &&
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        <h3 className="card-title">View and Manage Your AWS Scan History</h3>
                        <hr />

                        <Button
                          color="primary"
                          onClick={() => this.setState({ show: true })}
                          className="waves-effect waves-light mr-1 pull-right start-new-scan"
                          style={{ float: 'right' }}
                        >
                          Start a New Scan
                        </Button>

                        <MDBDataTable responsive bordered data={theData} />

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
              <Modal isOpen={this.state.show} toggle={this.toggle} backdrop={false}>
                <ModalHeader toggle={this.toggle}>Set AWS Scan Name</ModalHeader>
                <AvForm className="form-horizontal" onValidSubmit={this.createScan}>
                  <ModalBody>
                    <Row>
                      <Col xs={12}>
                        <AvField name="ScanName"
                          value={this.state.scanName}
                          onChange={(event) => this.setState({ scanName: event.target.value })}
                          type="text" className="form-control"
                          validate={{ required: true }}
                          placeholder="Enter Scan Name" />
                      </Col>

                      <Col xs={12}>

                        <div style={{ flexDirection: 'row' }}>

                          <AvField name="ScanType1"
                            value={this.state.scanName}
                            type="checkbox"
                            id="PCI"
                            onChange={this.pciChanged}
                          />

                          <label for="ScanType1" style={{ marginLeft: 20 }}>Check for PCI Benchmark</label>
                        </div>

                      </Col>

                      <Col xs={12}>

                        <div style={{ flexDirection: 'row' }}>

                          <AvField name="ScanType2"
                            value={this.state.scanName}
                            type="checkbox"
                            onChange={this.cisChanged}
                            id="CIS"
                          />

                          <label for="ScanType2" style={{ marginLeft: 20 }}>Check for CIS Benchmark</label>
                        </div>

                      </Col>

                      <Col xs={12}>

                        <div style={{ flexDirection: 'row' }}>

                          <AvField name="ScanType3"
                            value={this.state.scanName}
                            type="checkbox"
                            onChange={this.hipaaChanged}
                            id="HIPAA"
                          />

                          <label for="ScanType3" style={{ marginLeft: 20 }}>Check for HIPAA Benchmark</label>
                        </div>

                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" type="submit">Start Scan</Button>
                  </ModalFooter>
                </AvForm>
              </Modal>

            </Container>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default AWSScans;
