import React, { Component } from 'react';
import { Col, Card, CardBody, Media } from "reactstrap";
import {url} from '../../services/common';
import Loader from "react-loader-spinner";

class MiniWidgetsKubernetes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports : [],
            authToken:localStorage.getItem('auth_token'),
            scanType:'all',
            isLoading:false,
            loadingMessage:'Loading..',
        }
    }

    componentDidMount(){
        this.getCards();
    }
  
    getCards = () =>{
          this.setState({isLoading: true});    
          this.setState({loadingMessage: 'Loading...'});
          fetch(`${url}/kubernetes-clusters/dashboard-widgets`, {
              method: "GET",  
              headers: {
                'Accept': 'application/json',  
                'Authorization': 'Bearer '+this.state.authToken                  
               }            
           })
           .then((response) => response.json())
           .then((json) => {
            this.setState({reports:json.widgets});
           })
           .catch((error) => console.error('ss'+error))
           .finally(() => this.setState({isLoading: false})); 
      }

      OpenCluster = (Key) => {
        if(Key ==='totalcluster'){
            this.props.history.push(`/kubernetes/all/cluster`)
        }
        else if(Key === 'AKScluster'){
            this.props.history.push(`/kubernetes/AKS/cluster`)
        }
        else if(Key === 'EKSCluster'){
            this.props.history.push(`/kubernetes/EKS/cluster`)
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.reports.map((report, key) =>
                        <Col key={key} md={3}>
                            <Card>
                                <CardBody
                                    onClick={()=>this.OpenCluster(report.key)}
                                    style={{cursor:'pointer'}}
                                >
                                    <Media>
                                        <Media body className="overflow-hidden">
                                            <p className="font-size-14 mb-2 text-left">{report.title}</p>
                                            <h4 className="mb-0">{report.value}</h4>
                                        </Media>
                                        <div className="text-primary">
                                            <i className={report.icon + " font-size-24"}></i>
                                        </div>
                                    </Media>
                                </CardBody>                                           
                            </Card>
                        </Col>
                        )
                }
            </React.Fragment>
        );
    }
}

export default MiniWidgetsKubernetes;
