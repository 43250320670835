import React, { Component } from "react";
import { Media,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { PieChart,Pie,BarChart, Bar,AreaChart,Area,Label, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBTable, MDBTableBody, MDBTableHead  } from "mdbreact";
import {url} from '../../services/common';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";


class CloudSecurityMonitoring extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            activeTab: 1,
            isLoadin:false,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            widgets:[],
            tableData:[],
            piedata:[],
            startDate:null,
            endDate:null,
            searchKey:'',
            filteredTableData:[],
        }

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);
    
        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }

    onStartDateChange(e){
        this.setState({endDate:null})
        this.setState({startDate:new Date(e.target.value)})
    }

    onEndDateChange(e){
        //console.log(e.target.value)
        this.setState({endDate:new Date(e.target.value)})
    }

    exportPDFWithMethod(){

        let element = this.container.current || document.body;
        savePDF(element, {
          paperSize: "auto",
          margin: 40,
          fileName: `Report for ${new Date().getFullYear()}`,
        });
    }
    
    exportPDFWithComponent(){
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
    };

    handleChange(e){

        this.setState({searchKey:e.target.value})  
       
        var tData = this.state.tableData;
        var newTable =[];
        for(var i=0;i<tData.length;i++){
            if((tData[i].description.toLowerCase().includes(e.target.value.toLowerCase()))
            || (tData[i].source.toLowerCase().includes(e.target.value.toLowerCase()))
            
            ){
                newTable.push(tData[i]);
            }
        }

        this.setState({filteredTableData:newTable})       

    }
  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
       this.getPieData()
       this.getChartData()
       this.getTableData()
    }

    COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    RADIAN = Math.PI / 180;
    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
        const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
        
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    getPieData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/cloud-security-monitoring/pie`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({piedata:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }
    
    getChartData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/cloud-security-monitoring/chart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({data:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoadin: false})); 
    }

    getTableData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/cloud-security-monitoring/table`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({tableData:json.table, filteredTableData:json.table})
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }

    render() {

        const data = {
            columns: [
              {
                label: "Title",
                field: "title",
                sort: "asc",
                width: 30
              },
              {
                label: "agent.name",
                field: "agentname",
                sort: "asc",
                width: 30
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 150
              },
              {
                label: "rule.mitre.tactic",
                field: "rulemitretactic",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.description",
                field: "ruledescription",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.level",
                field: "rulemitrelevel",
                sort: "asc",
                width: 100
              },
              {
                label: "rule.mitre.id",
                field: "rulemitreid",
                sort: "asc",
                width: 100
              }
            ],
            rows: [
      
            ]
          };
      
          var theData = {};
          theData.columns = data.columns;
          var theRows = this.state.tableData;
          theData.rows = theRows;

        return (
            <React.Fragment>

                  <Button
                        color="info"
                        className="waves-effect waves-light mr-1 pull-right"
                        onClick={()=> this.exportPDFWithComponent()}
                        style={{float:'right', display:'block'}}
                    >
                        Export to PDF
                 </Button>


                {
                    this.state.isLoading ?
                    <div>
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={'100%'}   
                        timeout={5000} //3 secs
                    />  
                    </div>     
                :
                    <div className="mainContaindiv">
                        <Row className="mt-4">
                            <h4>{this.props.heading}</h4>
                        </Row>
                        <Row className="mt-4">
                        <AvForm inline >
                            Filter Data
                            <Col style={{display:'flex', flexDirection:'row'}}>                                

                                 <input type="date" name="start_date" onChange={this.onStartDateChange}/><input type="time" name="start_time" onChange={this.onStartTimeChange}/> &nbsp; &nbsp;to &nbsp; &nbsp;
                                 <input type="date" name="end_date" onChange={this.onEndDateChange} /><input type="time" name="end_time" onChange={this.onEndTimeChange}/>
                              </Col>
                        </AvForm>
                        </Row>

                        <PDFExport
                       ref={this.pdfExportComponent}
                       paperSize="auto"
                       margin={40}
                       fileName={`Report for ${new Date().getFullYear()}`}
                       author="KendoReact Team"
                    >

                    <div className="mainContaindiv" style={{padding:50}}> 

                        <Row className="mt-4">
                            <Col md={8}>
                                <h4>Events by source over time</h4>
                                <ResponsiveContainer width="100%" height={400}>
                                    <AreaChart
                                    width={500}
                                    height={400}
                                    data={this.state.data}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    
                                    <YAxis label={{ value: 'count', angle: -90, position: 'insideLeft' }} />
                                    <Tooltip />
                                    <br/>
                                    <Legend />
                                        <Area type="monotone" dataKey="vpc" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                        <Area type="monotone" dataKey="cloudtrail" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                                        <Area type="monotone" dataKey="macie" stackId="1" stroke="#ffc658" fill="#ffc658" />
                                        <Area type="monotone" dataKey="guardduty" stackId="1" stroke="#F2F809" fill="#F2F809" />
                                        <Area type="monotone" dataKey="inspector" stackId="1" stroke="#8C09F8" fill="#8C09F8" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Col>
                            <Col md={4}>
                                <h4>Sources</h4>
                                <ResponsiveContainer width="100%"  height={300}>
                                <PieChart width={400} height={300}>
                                <Pie
                                    data={this.state.piedata}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={this.renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {this.state.piedata.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <h4>Events</h4>
                        </Row>

                        <Row className="mt-4">

<div data-test="datatable-input" class="mdb-datatable-filter flex-row">
     <input class="form-control form-control-sm ml-0 my-1" 
        type="text" 
        placeholder="Search" 
        aria-label="Search" 
        onChange={(e) => {this.handleChange(e)}}
        value={this.state.searchKey}/>
</div>
</Row>


                        <Row className="mt-2">
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Source</th>
                                        <th>Description</th>
                                        
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {
                                        this.state?.filteredTableData?.map((item,index)=>{
                                            return <tr>
                                                        <td>{item?.timestamp}</td>
                                                        <td>{item?.source}</td>
                                                        <td>{item?.description}</td>                                                        
                                                    </tr>
                                        })
                                    }
                                </MDBTableBody>
                            </MDBTable>
                        </Row>

                        </div>

                        </PDFExport>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default CloudSecurityMonitoring;
