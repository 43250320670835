import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Row, Col, Card, Modal, Container, ModalHeader, ModalBody, ModalFooter, CardBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../Tables/datatables.scss";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from "react-loader-spinner";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import {url,Downloadurl} from '../../services/common';
import "../Dashboard/dashboard.scss";
import toastr from 'toastr'
import {Helmet} from "react-helmet";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import './pdfStyles.css';

class DockerResultHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
    breadcrumbItems : [
        { title : "Dashboard", link : "/dashboard" },
        { title : "Cluster History", link : "#" },
    ],
    series: [42.0, 26.0, 15.0],
    options : {
        labels: ["OK", "UNKNOWN", "FAILED"],
        plotOptions: {
            pie: {
                donut: {
                    size: '75%'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
        },
        colors: ['#0EC905', '#FFC300', '#CA0B00'],
    
    },
    scanId:this.props.match.params.id,
    tableData:[
      {
        id:1,
        LIBRARY:'apt',
        VULNERABILITY_ID:'CVE-2019-3462',
        SEVERITY:'CRITICAL',
        INSTALLED_VERSION:'1.0.9.8.3',
        FIXED_VERSION:'1.0.9.8.5',
        TITLE:'Incorrect sanitation of the 302 redirect field in HTTP transport method of...',
      },
      {
        id:2,
        LIBRARY:'bash',
        VULNERABILITY_ID:'CVE-2019-3462',
        SEVERITY:'CRITICAL',
        INSTALLED_VERSION:'1.0.9.8.3',
        FIXED_VERSION:'1.0.9.8.5',
        TITLE:'Incorrect sanitation of the 302 redirect field in HTTP transport method of...',
      }
    ],
    
    isLoading:false,
    loadingMessage:'',
    showResultModal:false,
    currentlyShowingScan:{},
    currentScanId:null,
    currentScanName:'',
    show:false,
    Editshow:false,
    scanName:'',
    };
    
    this.setTheTableData = this.setTheTableData.bind(this); 
    this.showTheResult = this.showTheResult.bind(this); 

    this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
    this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);

    this.container = React.createRef();
    this.pdfExportComponent = React.createRef();
  }
  
  child   = { width: `30em`, height: `100%`}
  
  ref = React.createRef();
  
  options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4,2]
  };

  componentDidMount()
  {
     // console.log(this.state.scanId)
  }

  exportPDFWithMethod(){

    let element = this.container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  }

exportPDFWithComponent(){
    if (this.pdfExportComponent.current) {
        this.pdfExportComponent.current.save();
    }
  };

  setTheTableData(json){  	 
        this.setState({tableData: json.awsScans});  
  }
  showTheResult(ScanId, scanName)
  {  	 
      this.setState({isLoading: true})
      this.setState({loadingMessage: 'Loading ...'});    
      let authToken = localStorage.getItem('auth_token');  	 
      fetch(`${url}/get-aws/threats/${ScanId}`, {
                  method: "GET",     
                  headers: {
                    'Accept': 'application/json',  
                    'Authorization': 'Bearer '+authToken                  
                  }            
              })
              .then((response) => response.json())
              .then((json) => {
                let {currentlyShowingScan,showResultModal,currentScanId, currentScanName}  = this.state;
                if(json.success)
                {
                    currentlyShowingScan  = json.data
                    currentScanId  = ScanId
                    currentScanName  = scanName
                    this.setPieData(currentlyShowingScan);
                    this.setState({currentlyShowingScan,showResultModal: true,currentScanId, currentScanName},()=>{
                      //console.log(currentlyShowingScan);
                      //console.log(showResultModal);
                    });   
                }else{
                    this.setState({isLoading: false})
                }
              })
              .catch((error) => console.error('ss'+error))
              .finally(() => this.setState({isLoading: false})); 
  }
  
  render() 
  {

    const data = {
      columns1: [       
        {
          label: "LIBRARY",
          field: "LIBRARY",
          sort: "asc",
          width: 30
        },
        {
          label: "VULNERABILITY ID",
          field: "VULNERABILITY_ID",
          sort: "asc",
          width: 30
        },
        {
          label: "SEVERITY",
          field: "SEVERITY",
          sort: "asc",
          width: 30
        },      
        {
          label: "INSTALLED VERSION",
          field: "INSTALLED_VERSION",
          sort: "asc",
          width: 30
        },      
        {
          label: "FIXED VERSION",
          field: "FIXED_VERSION",
          sort: "asc",
          width: 30
        },   
        {
          label: "TITLE",
          field: "TITLE",
          sort: "asc",
          width: 30
        }   
      ],
      columns2:[
        {
          label: "cvss access complexity",
          field: "cvss_access_complexity",
          sort: "asc",
          width: 30
        },
        {
          label: "cvss_access_vector",
          field: "cvss_access_vector",
          sort: "asc",
          width: 30
        },
        {
          label: "cvss_authentication",
          field: "cvss_authentication",
          sort: "asc",
          width: 30
        },
        {
          label: "cvss_availability_impact",
          field: "cvss_availability_impact",
          sort: "asc",
          width: 30
        },
        {
          label: "cvss_base",
          field: "cvss_base",
          sort: "asc",
          width: 30
        },  
        {
          label: "cvss_integrity_impact",
          field: "cvss_integrity_impact",
          sort: "asc",
          width: 30
        },  
        {
          label: "summary",
          field: "summary",
          sort: "asc",
          width: 30
        }  
      ],
      rows: [],
    };

    var theResult = {};
    theResult.columns = data.columns1;
    theResult.rows = this.state.tableData;
    
    return (

      <React.Fragment>
      <Helmet><title>Docker Image Scan Results</title></Helmet>
      {this.state.isLoading &&
         <div>
          <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
          <Loader
             type="Bars"
             color="#00BFFF"
             height={100}
             width={'100%'}   
             timeout={5000} //3 secs
          />  
         </div>     
      }
      
      {!this.state.isLoading &&

      <PDFExport
         ref={this.pdfExportComponent}
         paperSize="auto"
         margin={40}
         fileName={`Report for ${new Date().getFullYear()}`}
         author="KendoReact Team"
     >
      
        <div className="page-content" >
        <Container fluid >
        <Breadcrumbs title={`Docker Image Scan Results`} breadcrumbItems={this.state.breadcrumbItems} />
           
            <Row style={{marginBottom:10}}>
                <Col xs={12}>  
                    <Button
                        color="success"
                        className="waves-effect waves-light mr-1 pull-right"
                        onClick={()=> this.exportPDFWithComponent()}
                        style={{float:'right'}}
                    >
                        Export to PDF
                    </Button> 

                    <Button
                        color="primary"
                        onClick={()=>{this.props.history.push('/docker/all-images')}}
                        className="waves-effect waves-light mr-1 pull-right"
                        style={{float:'right'}}

                    >
                        Back to All Docker Images
                    </Button> 
                </Col>
            </Row>
            
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                     <h4 className="card-title">Total vulnerabilities: 2 </h4>
                    <MDBDataTable  responsive bordered data={theResult} />
                  </CardBody>
                </Card>
              </Col>
            </Row> 
            <Row className="mt-4">
              <Col xs={12}>
                <Card>
                  <CardBody>
                     <h4 className="card-title">Total vulnerabilities: 2 </h4>
                     <table class="table table-bordered table-responsive">
                       <thead>
                         <tr>
                           <th>cvss_access_complexity</th>
                           <th>cvss_access_vector</th>
                           <th>cvss_authentication</th>
                           <th>cvss_availability_impact</th>
                           <th>cvss_base</th>
                           <th>cvss_integrity_impact</th>
                           <th>summary</th>
                         </tr>
                       </thead>
                       <tbody>
                         <tr>
                           <td>High</td>
                           <td>Local access</td>
                           <td>None required</td>
                           <td>Complete</td>
                           <td>7.2</td>
                           <td>Partial</td>
                           <td>OpenLDAP before 2.3.29 allows remote attackers to cause a denial of service (daemon crash) via LDAP BIND requests with long authcid names, which triggers an assertion failure.</td>
                         </tr>
                         <tr>
                           <td>High</td>
                           <td>Local access</td>
                           <td>None required</td>
                           <td>Complete</td>
                           <td>7.2</td>
                           <td>Partial</td>
                           <td>slapo-pcache (overlays/pcache.c) in slapd in OpenLDAP before 2.3.39, when running as a proxy-caching server, allocates memory using a malloc variant instead of calloc, which prevents an array from being initialized properly and might allow attackers to cause a denial of service (segmentation fault) via unknown vectors that prevent the array from being null terminated.</td>
                         </tr>
                       </tbody>
                     </table>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
          </Container>
        </div>

        </PDFExport>
       }
      </React.Fragment>

    );
  }
}

export default DockerResultHistory;
