import React, { Component } from "react";
import { Row, Col, Card, Modal, Container, ModalHeader, ModalBody, ModalFooter, CardBody, ListGroup,ListGroupItem,ListGroupItemHeading,ListGroupItemText } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import Loader from "react-loader-spinner";
//Import Charts
import {url,Downloadurl} from '../../services/common';
import toastr from 'toastr'
import {Helmet} from "react-helmet";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
        breadcrumbItems : [
            { title : "Dashboard", link : "/dashboard" },
            { title : "All Notifications", link : "#" },
        ],
        notifications:[],
        isLoading:false,
        loadingMessage:'Loading'
    };
  }

  componentDidMount()
  {
    this.getnotifications(this.state.imageId)
  }
  
  getnotifications = () => {
    let authToken = localStorage.getItem('auth_token');
    this.setState({isLoading: true})
    fetch(`${url}/get-notifications`, {
        method: "GET",     
        headers: {
          'Accept': 'application/json',  
          'Authorization': 'Bearer '+authToken                  
        }            
    })
    .then((response) => response.json())
    .then((json) => {
        this.setState({notifications: json})
    })
    .catch((error) => console.error('ss'+error))
    .finally(() => this.setState({isLoading: false})); 
  }

  render() 
  {
    return (
      <React.Fragment>
      <Helmet><title>All Notifications</title></Helmet>
      {
        this.state.isLoading &&
         <div>
          <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
          <Loader
             type="Bars"
             color="#00BFFF"
             height={100}
             width={'100%'}   
             timeout={5000} //3 secs
          />  
         </div>     
      }
      
      {
      !this.state.isLoading &&
      
        <div className="page-content" >
        <Container fluid >
            <Breadcrumbs title={`Notifications`} breadcrumbItems={this.state.breadcrumbItems} />
            <Card>
              <CardBody>
                <h4 className="card-title">All Notifications</h4>
                  {
                      this.state.notifications?.length > 0 ? 
                      this.state.notifications.map((item,index)=>{
                          return <ListGroup>
                                    <ListGroupItem>
                                        <ListGroupItemHeading>{item?.title}</ListGroupItemHeading>
                                        <ListGroupItemText className="text-left">{item?.description}</ListGroupItemText>
                                    </ListGroupItem>
                                </ListGroup>
                      }) 
                    : <p>No Notification Found</p>
                  }
              </CardBody>
            </Card>
          </Container>
        </div>
       }
      </React.Fragment>
    );
  }
}

export default Notifications;
