import React, { Component } from "react";
import { Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader, Carousel, CarouselItem, CarouselControl } from "reactstrap";
//Import Breadrumb
import Select from 'react-select';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import SecurityAnalytics from './SecurityAnalytics'
import IntrusionDetection from './IntrusionDetection'
import LogDataAnalysis from './LogDataAnalysis'
import FileIntegrityMonitoring from './FileIntegrityMonitoring'
import VulnerabilityDetection from './VulnerabilityDetection'
import ConfigurationAssessment from './ConfigurationAssessment'
import IncidentResponse from './IncidentResponse'
import RegulatoryCompliance from './RegulatoryCompliance'
import CloudSecurityMonitoring from './CloudSecurityMonitoring'
import ContainersSecurity from './ContainersSecurity'
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import './anomaly.css'
import { url } from '../../services/common';
import toastr from 'toastr'

class AnomalyDetectionAgentInstallationGuide extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Intrucept CSPM", link: "#" },
                { title: "Anomaly Detection", link: "#" },
            ],
            options: {
                labels: ['Critical', 'Low', 'Medium', 'High', 'Info'],
                colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    floating: false,
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: -10
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240
                        },
                        legend: {
                            show: false
                        },
                    }
                }]

            },
            series: [44, 55, 41, 17, 15],
            authToken: localStorage.getItem('auth_token'),
            agentShow: false,
            agentserviceShow: false,
            activeTab: 1,
            os: null,
            arch: 'none',
            activeTabComponet: null,
            activeIndex: 0,
            agents: [],
            infrastructures: [],
        }
    }

    // 1. Malware Detection
    // 2. Security Events
    // 3. Cloud Security Monitoring
    // 4. Incident Response
    // 5. Security Configuration Assessment
    // 6. File Integrity Monitoring
    // 7. Vulnerability Detection

    linkArray = [
        { id: 1, name: 'Security Analytics', component: <SecurityAnalytics heading="Security Analytics" />, icon: '' },
        { id: 2, name: 'Intrusion Detection', component: <IntrusionDetection heading="Intrusion Detection" />, icon: '' },
        { id: 4, name: 'File Integrity Monitoring', component: <FileIntegrityMonitoring heading="File Integrity Monitoring" />, icon: '' },
        { id: 5, name: 'Vulnerability Detection', component: <VulnerabilityDetection heading="Vulnerability Detection" />, icon: '' },
        { id: 6, name: 'Configuration Assessment', component: <ConfigurationAssessment heading="Configuration Assessment" />, icon: '' },
        { id: 7, name: 'Incident Response', component: <IncidentResponse heading="Incident Response" />, icon: '' },
        { id: 9, name: 'Cloud Security Monitoring', component: <CloudSecurityMonitoring heading="Cloud Security Monitoring" />, icon: '' },
        // {id:8,name:'Regulatory Compliance',component:<RegulatoryCompliance heading="Regulatory Compliance" />,icon:''},
        // {id:10,name:'Containers Security',component:<ContainersSecurity heading="Containers Security" />,icon:''},
        // {id:3,name:'Log Data Analysis',component:<LogDataAnalysis heading="Log Data Analysis" />,icon:''},
    ]

    componentDidMount() {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");

        if (isLoggedIn) {
            if (isLoggedIn === "no") {
                this.props.history.push('/');
            }
            if (superAdmin === 'admin') {
                this.setState({ user_type: 'admin' })
            }
        } else {
            this.props.history.push('/');
        }
        //  this.getAgents();

    }

    toggle1 = (tab, component) => {
        
        if (this.state.agents?.length > 0) {
            this.setState({
                activeTab: tab,
                activeTabComponet: component,
            });
        } else {
            this.setState({
                activeTab: tab,
                activeTabComponet: null,
            });
        }
    }

    agentToggale = () => {
        this.setState({ agentShow: !this.state.agentShow })
    }
    agentserviceToggale = () => {
        this.setState({ agentserviceShow: !this.state.agentserviceShow })
    }

    getAgents = () => {
        this.setState({ isLoading: true });
        this.setState({ loadingMessage: 'Loading images' });
        fetch(`${url}/anomaly/all-agent`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.state.authToken
            }
        })
            .then((response) => response.json())
            .then((json) => {
                this.setState({ agents: json.agents })
                if (this.state.agents.length > 0) {
                    this.setState({
                        activeTab: 1,
                        activeTabComponet: <SecurityAnalytics heading="Security Analytics" />,
                    });
                } else {
                    this.setState({
                        activeTab: 1,
                        activeTabComponet: null,
                    });
                }
            })
            .catch((error) => console.error('ss' + error))
            .finally(() => this.setState({ isLoading: false }));
    }

    addagentData = (e, value) => {
        this.setState({ isLoading: true });
        this.setState({ loadingMessage: 'Adding Agent' });
        value.architecture = this.state.arch
        fetch(`${url}/anomaly/add-agent`, {
            method: "POST",
            body: JSON.stringify(value),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.state.authToken
            }
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.success) {
                    //toastr.success(json.message, 'Success');
                    this.getAgents();
                } else {
                    //toastr.error(json.message, 'Error');
                }
            })
            .catch((error) => toastr.error('Something went wrong', 'Error'))
            .finally(() => {
                this.setState({ isLoading: false });
                this.agentToggale();
            });
    }
    addagentserviceData = (e, value) => {
        
        this.agentserviceToggale();

    }

    render() {
        const child = { width: `300em`, height: `100%` }
        const RedhatOptions = ["CentOS 5", "CentOS 6 or Higher"];
        const DebianOptions = ["i386", "x86_64", "armhf", "aarch64"];

        return (
            <React.Fragment>
                <Helmet><title>Anomaly Detection - Agent Installation Guide</title></Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Anomaly Detection - Agent Installation Guide"
                            breadcrumbItems={this.state.breadcrumbItems} />


                    </Container>

                    <Container>

                        <h4>Install Agent on a Node</h4>


                        <h5>Linux</h5>

                        <br />
                        <h6>Yum </h6>

                        <p style={{ textAlign: 'left', marginTop: 30 }}>
                            Download the installer from <a target="_blank"
                                href="https://backend.threatspotter.com/storage/binaries/install-agent-yum">
                                here</a> and run in on your Debian/Ubuntu machine you want to monitor:</p>




                        <br /><br />



                        <h6> APT</h6>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> Download the installer from <a target="_blank" href="https://backend.threatspotter.com/storage/binaries/install-agent-yum">
                            here</a> and run in on your Centos/RHEL machine you want to monitor</p>




                        <h5 style={{marginTop:30}}>Windows</h5>

                        <br />
                        <h6>Powershell </h6>

                        <p style={{ textAlign: 'left', marginTop: 30 }}>
                            Download the installer from <a target="_blank"
                                href="https://backend.threatspotter.com/storage/binaries/threatspotter_anomaly_detection_agent.msi">
                                here</a> and run the below command:</p>


                                <code>

{`.\\threatspotter_anomaly_detection_agent.msi /q TS_MANAGER="159.65.237.27" TS_REGISTRATION_SERVER="159.65.237.27"`}<br />
</code>





<h5 style={{marginTop:30}}>Mac OS</h5>

<br />

<p style={{ textAlign: 'left', marginTop: 0 }}>
    Download the installer from <a target="_blank"
        href="https://backend.threatspotter.com/storage/binaries/threatspotter-agent-4.2.5-1.pkg">
        here</a> and run the below command:</p>


        <code>

{`launchctl setenv TS_MANAGER "159.65.237.27" && installer -pkg threatspotter-agent-4.2.5-1.pkg -target /`}<br />
</code>
















                        <hr />

                        <h4 style={{ marginTop: 30 }}>AWS Services Monitoring</h4>

                        <h5>Configuring an S3 Bucket</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Go to Services > Storage > S3`}</p>


                        <img width="800" src={'https://backend.threatspotter.com/storage/help/aws-create-firehose-11.png'} />



                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Click on Create bucket:`}</p>

                        <img width="800" src={'https://backend.threatspotter.com/storage/help/aws-create-firehose-21.png'} />


                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Create a new bucket, give it a name, then click on the Create button:`}</p>

                        <img width="800" src={'https://backend.threatspotter.com/storage/help/aws-create-firehose-31.png'} />



                        <h5>Create an IAM User</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Create new user:Navigate to Services > IAM > Users`}</p>

                        <img width="800" src={'https://backend.threatspotter.com/storage/help/aws-user1.png'} />




                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Enter a user name`}</p>
                        <p style={{ textAlign: 'left', marginTop: 30 }}>Click on “Next: Permissions” to continue.</p>
                        <p style={{ textAlign: 'left', marginTop: 30 }}>Create policy:We will attach this policy later to the user we are creating.</p>

                        <img width="800" src={'https://backend.threatspotter.com/storage/help/aws-create-policy1.png'} />


                        <p style={{ textAlign: 'left', marginTop: 30 }}>Your policy should look like the below.</p>


                        <code>

                            {`{`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;{`"Version": "2012-10-17",`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;{`"Statement": [`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;{` {`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{` "Sid": "VisualEditor0",`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`  "Effect": "Allow",`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`   "Action": [`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`    "s3:GetObject",`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`     "s3:ListBucket",`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`    "s3:DeleteObject"`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`   ],`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`  "Resource": [`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`   "arn:aws:s3:::threatspotter-cloudtrail",`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`   "arn:aws:s3:::threatspotter-cloudtrail/*"`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`  ]`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;{` }`}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;{` ]`}<br />
                            {`}`}<br />
                        </code>




                        <p style={{ textAlign: 'left', marginTop: 30 }}>Attach Policy</p>

                        <img width="800" src={'https://backend.threatspotter.com/storage/help/aws-attach-policy1.png'} />


                        <p style={{ textAlign: 'left', marginTop: 30 }}>Confirm user creation and get credentials</p>

                        <img width="800" src={'https://backend.threatspotter.com/storage/help/aws-summary-user1.png'} />

                        <p style={{ textAlign: 'left', marginTop: 30 }}>Save the credentials, you will use them later to configure the module.</p>


                        <h5 style={{ textAlign: 'left', marginTop: 30 }}>Configure Profiles</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}>
                            You can define profiles in your credentials file (~/.aws/credentials) and specify those profiles on the bucket configuration. This credential file needs to be created on the same VM that you are using as the common agent VM for monitoring the AWS cloud services of all 
                            Intrucept CSPM customers. The below code is the content of the same file, it sits on the common cloud monitoring agent. </p>


                        <p style={{ textAlign: 'left', marginTop: 30 }}>A region must be also specified on the credentials file in order to make it work.</p>


                        <p style={{ textAlign: 'left', marginTop: 30 }}>For example, the following credentials file defines three different profiles: default, dev and prod.</p>


                        <code>


                            {`[default]`}<br />
                            {`aws_access_key_id=foo`}<br />
                            {`aws_secret_access_key=bar`}<br />
                            {`region=us-east-1`}<br />

                        </code>
                        <br />
                        <code>

                            {`[dev]`}<br />
                            {`aws_access_key_id=foo2`}<br />
                            {`aws_secret_access_key=bar2`}<br />
                            {`region=us-east-1`}<br />

                        </code>
                        <br />

                        <code>

                            {`[prod]`}<br />
                            {`aws_access_key_id=foo3`}<br />
                            {`aws_secret_access_key=bar3`}<br />
                            {`region=us-east-1`}<br />

                        </code>



                        <p style={{ textAlign: 'left', marginTop: 30 }}>
                            To use the prod profile in the AWS integration you would use the following bucket configuration:
                        </p>

                        <code>


                            {`<bucket type="cloudtrail">`}<br />
                            {`<name>my-bucket</name>`}<br />
                            {`<aws_profile>prod</aws_profile>`}<br />
                            {`</bucket>`}<br />

                        </code>


                        <h4 style={{ marginTop: 50 }}>Installing dependencies</h4>


                        <p style={{ textAlign: 'left', marginTop: 30 }}>For CentOS/RHEL/Fedora systems, please download the installer form
                            <a href="https://backend.threatspotter.com/storage/binaries/install-cloud-services-agent-yum" target="_blank">here</a>:</p>

                        <p style={{ textAlign: 'left', marginTop: 30 }}>For Debian/Ubuntu systems, please download the installer form
                            <a href="https://backend.threatspotter.com/storage/binaries/install-cloud-services-agent-apt" target="_blank">here</a>:</p>


                        <p style={{ textAlign: 'left', marginTop: 30 }}>Configure the services to monitor (in /var/ossec/etc/ossec.conf) on the Agent or Manager machine</p>



                        <code>

                            {`<wodle name="aws-s3">`}<br />
                            {`<disabled>no</disabled>`}<br />
                            {`<interval>10m</interval>`}<br />
                            {` <run_on_start>yes</run_on_start>`}<br />
                            {` <skip_on_error>yes</skip_on_error>`}<br />

                            <br />

                            {`<!-- Inspector, two regions, and logs after January 2018 -->`}<br />
                            {` <service type="inspector">`}<br />
                            {`  <aws_profile>default</aws_profile>`}<br />
                            {`  <regions>us-east-1,us-east-2</regions>`}<br />
                            {`  <only_logs_after>2018-JAN-01</only_logs_after>`}<br />
                            {`</service>`}<br />

                            <br />

                            {`<!-- GuarDuty, 'production' profile -->`}<br />
                            {` <bucket type="guardduty">`}<br />
                            {`  <name>threatspotter-aws-wodle</name>`}<br />
                            {`  <path>guardduty</path>`}<br />
                            {`  <aws_profile>production</aws_profile>`}<br />
                            {`</bucket>`}<br />


                            <br />

                            {`<!-- Config, 'default' profile -->`}<br />
                            {`<bucket type="config">`}<br />
                            {`  <name>threatspotter-aws-wodle</name>`}<br />
                            {`  <path>config</path>`}<br />
                            {`  <aws_profile>default</aws_profile>`}<br />
                            {` </bucket>`}<br />

                            <br />

                            {`<!-- KMS, 'dev' profile -->`}<br />
                            {`<bucket type="custom">`}<br />
                            {` <name>threatspotter-aws-wodle</name>`}<br />
                            {`  <path>kms_compress_encrypted</path>`}<br />
                            {`  <aws_profile>dev</aws_profile>`}<br />
                            {`</bucket>`}<br />

                            <br />

                            {`<!-- CloudTrail, authentication with hardcoded keys (not recommended), without 'path' tag -->`}<br />
                            {`<bucket type="cloudtrail">`}<br />
                            {`  <name>threatspotter-cloudtrail</name>`}<br />
                            {`  <access_key>XXXXXXXXXX</access_key>`}<br />
                            {`  <secret_key>XXXXXXXXXX</secret_key>`}<br />
                            {`</bucket>`}<br />

                            <br />

                            {` <!-- CloudTrail, 'gov1' profile, and 'us-gov-east-1' GovCloud region -->`}<br />
                            {`<bucket type="cloudtrail">`}<br />
                            {` <name>threatspotter-aws-wodle</name>`}<br />
                            {` <path>cloudtrail-govcloud</path>`}<br />
                            {`  <regions>us-gov-east-1</regions>`}<br />
                            {`  <aws_profile>gov1</aws_profile>`}<br />
                            {` </bucket>`}<br />

                            <br />

                            {`<!-- CloudTrail, 'gov2' profile, and 'us-gov-west-1' GovCloud region -->`}<br />
                            {` <bucket type="cloudtrail">`}<br />
                            {`  <name>threatspotter-aws-wodle</name>`}<br />
                            {`  <path>cloudtrail-govcloud</path>`}<br />
                            {` <regions>us-gov-west-1</regions>`}<br />
                            {`  <aws_profile>gov2</aws_profile>`}<br />
                            {` </bucket>`}<br />

                            {`</wodle>`}<br />

                        </code>



                        <hr />

                        <h4 style={{ marginTop: 30 }}>GCP Services Monitoring</h4>

                        <h5 style={{ marginTop: 20 }}>Installing dependencies</h5>
                        <h6 style={{ marginTop: 20 }}>For CentOS/RHEL/Fedora systems:</h6>

                        <code>
                            {`# yum install python-pip`}<br />
                        </code>

                        <h6 style={{ marginTop: 20 }}>For Debian/Ubuntu systems:</h6>

                        <code>
                            {`# apt-get update && apt-get install python-pip`}<br />
                        </code>

                        <h5 style={{ marginTop: 20 }}>google-cloud-pubsub</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`google-cloud-pubsub is the official python library supported by Google to manage Google Cloud Pub/Sub resources. It will be used to pull the log messages from the Pub/Sub queue.`}</p>


                        <code>
                            {`pip install google-cloud-pubsub==2.7.1`}<br />
                        </code>


                        <h4 style={{ marginTop: 30 }}>Configuring GCP credentials</h4>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`In order to make the Intrucept CSPM GCP module pull log data from Google Pub/Sub, it will be necessary to provide access credentials so it can connect to them.`}</p>
                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`To do this, we will need to create a service account with the Pub/Sub permissions and then create a key. It is important to save this key as a JSON file. We will use this file as credentials for the GCP module.`}</p>


                        <h5 style={{ marginTop: 20 }}>Create a service account</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Within the Service Accounts section, create a new service account and add two roles with Pub/Sub permissions: publisher and subscriber.`}</p>


                        <img width="800" src={'https://documentation.wazuh.com/current/_images/gcp-service-account1.png'} />


                        <h5 style={{ marginTop: 20 }}>Create private key</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`After creating a service account, add a key for it. Save it as JSON:`}</p>



                        <img width="800" src={'https://documentation.wazuh.com/current/_images/gcp-account-key1.png'} />

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Your key should have this format:`}</p>


                        <code>
                            {`"type": "service_account",`}<br />
                            {`"project_id": "threatspotter-gcloud-258815",`}<br />
                            {`"private_key_id": "1f7578bcd3e41b54febdac907f9dea7b5d1ce352",`}<br />
                            {`"private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCxjzFuu7kO+sfY\nXPq0EZo1Oth9YjCyrhIQr6XavJQyD/OT9gcd9Q5+/VvLwCXBijEgVdXFQf5Tcsh2\ndpp/hOjGuc7Lh9Kk+DtebUDZ9AIF92LvRX2yKJJ4a6zqV9iEqCfxAhSrwsYMLnp0\nGbxG0ACUR/VdLv8U2ctNDG4DL8jk6yYowABbsL/074GOFWtwW99w1BJb09+l0f2l\njIom15iY897W1gjOBskM7fsHm3WwlCwD/+4PPodp8PRIjvefnMwx7E0Lu6IcJ8Kg\n4Rhm1Rk5hJWKWEgQHmZ4ik4kc/FKdHRMGERkMY5VVYoZ6bUx7OdhF7Vt3HVZDA88\nsx9fbTBxAgMBAAECggEAAWSAHMA4KVfqLVY9WSAyN2yougMFIsGevqbCBD8qYmIh\npO1vDNsZLAHMsIJnSWdOD1TdAlkMJ5dk3xj7CTj/ol9esdX03vpbbNgqhAsX4PgZ\nvIqs+7K5w1wE1SmvNwsilQ9RHi++4eWTbEmvYlbLSl5uHDb8JSu4HniUfE3po3H5\nWDj01OMSe9dhaXrzhqOn2qo37XJ9xF1VCSkY3JRj3cY7W7crVE3UmDyYT+ZE1Tei\nyYhrZh1QDFeQVCFiHEP3RA1T/MYaFn1ylkwGcvgFvoB81vOJaVEXh1Xldwx/6KZC\nyrXBlnVqa//IuCtEE4zTl146G99kRdQFrAdqTadlSQKBgQDauQefH+zCpxTaO03E\nlzGoXr9mxo6Rzhim60e+uDgkCnDhElc3rqiuxFH6QNORa2/A/zvc7iHYZsu8QAvB\n776S9rrpxHoc1271fLqzMBR6gDkTzh/MjUJnsPNjnfehE2h6U8Zoeq755Xv9S85I\nuk9bIJzs5JH6xBEDxnIb/ier5wKBgQDP0i9jTb5TgrcqYYpjURsHGQRv+6lOaZrC\nD94vNDmhTLg3kW5b2BD0ZeZwGCwiSOSqL/5fjlRie94pPnIn6pm5uGgndgdRLQvw\nIdpRyvAUAOY7SnoLhZjVue4syzwV3k7+d4x7LrzpZclBH8uc3sLU3vOSsmFRIkf+\nfK9qcVv15wKBgQDL2fHRi/algQW9U9JqbKQakZwAVQThvd1aDSVECvxAEv8btnVV\nb1LF+DGTdUH6YdC5ZujLQ6KFx2ERZfvPV/wdixmv8LADG4LOB98WTLR5a/JGlDEs\n+2ctr01YxgzasnUItfXQwK8+N3U1Iab0P7jgbOf1Hh80QfK9uwH1Nw6QdwKBgCuP\nigFNpWxJxOzsPx6sPHcTZlu2q3lVJ2wv+Ul5r+7AbwiuwiwcMQmZZmDuoCmbj9qg\nbrhG1CdEgX+xqCn3wbstDR/gXI5GW+88mU91szbuLVQWO1i46x05eNQI0ZJf47zx\nABA97rkZbcLp0DsUclA+X13LaByii+aq6fXsxvLXAoGBALzkBzJ/SOvotz/UnBxl\nGU9QWmptZttaqtLKizPNQZpY1KO9VxeyoGbkTnN0M58ktpIp8LGlSJejk/tkRKBG\nUFRW/v49GW3eCgl4D+MOTFLCJDT68D2lp4F9hdBHsoH17ZdHy8rennmJN3QExIjx\n0xoq6OYjjzNwhFqkPl0H6HrM\n-----END PRIVATE KEY-----\n",`}<br />
                            {`"client_email": "threatspotter-mail@threatspotter-gcloud-258815.iam.gserviceaccount.com",`}<br />
                            {`"client_id": "102784232161964177687",`}<br />
                            {`"auth_uri": "https://accounts.google.com/o/oauth2/auth",`}<br />
                            {`"token_uri": "https://oauth2.googleapis.com/token",`}<br />
                            {`"auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",`}<br />
                            {`"client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/threatspotter-gcloud-acc%40threatspotter-gcloud-258815.iam.gserviceaccount.com"`}<br />
                        </code>




                        <h5 style={{ marginTop: 20 }}>Configuring Google Cloud Pub/Sub</h5>


                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Google Cloud Pub/Sub is a fully-managed real-time messaging service that allows you to send and receive messages between independent applications.`}</p>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`We use it to get security events from the Google Cloud instances without creating a special logic to avoid reprocessing events.`}</p>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`In this section we will see how to create a topic, a subscription and a sink to fully configure Google Cloud Pub/Sub to work with Intrucept CSPM.`}</p>


                        <h6 style={{ marginTop: 20 }}>Create a topic</h6>



                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Every publishing application sends messages to topics. Intrucept CSPM will retrieve the logs from this topic.`}</p>


                        <img width="800" src={'https://documentation.wazuh.com/current/_images/gcp-topic1.png'} />

                        <h6 style={{ marginTop: 20 }}>Create a subscription</h6>



                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Use the button below the topic details (choose pull delivery). You can create as many subscriptions as you wish.`}</p>

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/gcp-account-key1.png'} />

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`At this point, the Pub/Sub environment is ready to manage the message flow between the publishing and subscribing applications.`}</p>

                        <h6 style={{ marginTop: 20 }}>Export logs via sink</h6>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Log activities should appear under the Logs Router section. Cloud Audit logs can be published to a Cloud Pub/Sub topic through the sinks. Create a sink and use the topic as destination.`}</p>





















                        <hr />

                        <h4 style={{ marginTop: 30 }}>Azure Services Monitoring</h4>

                        <h5 style={{ marginTop: 20 }}>1. Giving permissions to the application</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`1.1 - In the Azure Active Directory section, select the option App registrations and once inside, select New application registration`}</p>


                        <img width="800" src={'https://documentation.wazuh.com/current/_images/graph11.png'} />



                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`1.2 - Access the Settings section. Save the application id for later authentication.:`}</p>

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/graph21.png'} />


                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`1.3 - In the Required permissions section select the Add option.:`}</p>

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/graph31.png'} />


                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`1.4 - Select the API by searching for “Microsoft Graph”.:`}</p>

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/graph41.png'} />

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`1.5 - Select the permissions that adapt to our infrastructure.:`}</p>

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/graph51.png'} />

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`1.6 - Select Done.:`}</p>

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/graph61.png'} />


                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`Back to the Azure Active Directory section, select the option Enterprise applications and once inside, select the newly created app. Open Permissions and click Grant Admin Consent for the application. In the popup window that appears, review the permissions are appropriate, and select Accept.:`}</p>




                        <h5 style={{ marginTop: 20 }}>2. Obtaining the application key for authentication</h5>


                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`2.1 - Select Keys and fill in the DESCRIPTION and EXPIRES fields. Once we save the key we will get its value. This will be the key with which we will authenticate our application in order to use the API.:`}</p>

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/la_create_key1.png'} />

                        <img width="800" src={'https://documentation.wazuh.com/current/_images/la_key_created1.png'} />



                        <h5 style={{ marginTop: 20 }}>Configuration on our Agent</h5>

                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`3. azure-logs module configuration`}</p>
                        <p style={{ textAlign: 'left', marginTop: 30 }}> {`When we choose to use a file for authentication, its content must be field = value. For example:`}</p>




                        <code>
                            {`application_id = 8b7...c14`}<br />
                            {`application_key = w22...91x`}<br />
                        </code>




                        <p style={{ textAlign: 'left', marginTop: 30 }}>With the below example configuration. the integration will be executed every Friday at 12:00. Authentication will be carried out by reading the file containing the credentials. We add a representative tag and set the search for the activities/audit?api-version=beta query to give us the results of the previous day.</p>




                        <code>


                            {`<wodle name="azure-logs">`}<br />
                            {`<disabled>no</disabled>`}<br />
                            {`<wday>Friday</wday>`}<br />
                            {`<time>12:00</time>`}<br />
                            {`<run_on_start>no</run_on_start>`}<br />
                            {`<graph>`}<br />
                            {`<auth_path>/home/manager/Azure/graph_auth.txt</auth_path>`}<br />
                            {`<tenantdomain>threatspotter.onmicrosoft.com</tenantdomain>`}<br />
                            {`<request>`}<br />
                            {`<tag>azure-active_directory</tag>`}<br />
                            {`<query>activities/audit?api-version=beta</query>`}<br />
                            {`<time_offset>1d</time_offset>`}<br />
                            {`</request>`}<br />
                            {`</graph>`}<br />
                            {`</wodle>`}<br />

                        </code>



                        <br /><br /><br />



                    </Container>

                </div>



            </React.Fragment>
        );
    }
}

export default AnomalyDetectionAgentInstallationGuide;
