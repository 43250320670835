import React, { Component } from "react";
import { Row, Col, Button, Alert, Container, Label, FormGroup } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {  registerUser, registerUserFailed, apiError } from '../../store/actions';

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logodark from "../../assets/images/logo-dark.png";
import {url} from '../../services/common';
import toastr from 'toastr'

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
       
    }
    this.handleSubmit = this.handleSubmit.bind(this);
}

    handleSubmit(event, values) {
        fetch(`${url}/register`, {
            method: "POST",        
            body:JSON.stringify({
                email:values.email,
                password:values.password,
                firstname: values.firstname,
                lastname: values.lastname,
                username: values.username,
            }),       
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },

         })
         .then((response) => response.json())
         .then((json) => {
            if(json.success){
                //toastr.success(json.message,'Success');
                this.props.history.push('/login')
            }else{
                if(json.error)
                {
                    Object.keys(json.error).map((itme,index)=>{
                        json.error[itme].map((item2,index2)=>{
                           // toastr.error(item2,'Error');
                        })
                    })
                }else{
                   // toastr.error(json.message,'Error');
                }
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  
    }

  componentDidMount()
  {
    document.body.classList.add("auth-body-bg");
  }


  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>
        <div>
            <Container fluid className="p-0">
                <Row className="no-gutters">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center" style={{marginTop:350}}>
                                                <div>
                                                    <Link to="/" className="logo"><img src={logodark} height="20" alt="logo"/></Link>
                                                </div>
    
                                                <h4 className="font-size-18 mt-4">Register Account</h4>
                                                <p className="text-muted">Get your Intrucept CSPM account now.</p>
                                            </div>
                                            <div className="p-2 mt-5">
                                                <AvForm onValidSubmit={this.handleSubmit} className="form-horizontal" >
                                                
                                                   <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-mail-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="firstName">First Name</Label>
                                                        <AvField name="firstname" validate={{required: true}} type="text" className="form-control" id="firstName" placeholder="Enter First Name"/>
                                                    </FormGroup>
                                                    
                                                     <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-mail-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="lastname">Last Name</Label>
                                                        <AvField name="lastname" validate={{required: true}} type="text" className="form-control" id="lastname" placeholder="Enter Last Name"/>
                                                    </FormGroup>
                                                    
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-mail-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="email">Email</Label>
                                                        <AvField name="email" validate={{email: true, required: true}} type="email" className="form-control" id="email" placeholder="Enter Email"/>
                                                    </FormGroup>

                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="username">Username</Label>
                                                        <AvField name="username" validate={{required: true}} type="text" className="form-control" id="username" placeholder="Enter Username"/>
                                                    </FormGroup>
                            
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="password">Password</Label>
                                                        <AvField name="password"  validate={{required: true}} type="password" className="form-control" id="password" placeholder="Enter Password"/>
                                                    </FormGroup>
                                                    
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="userpassword">Confirm Password</Label>
                                                        <AvField name="userpassword"  value="" validate={{
                                                            required: { value: true },
                                                            match: { value: "password" }
                                                            }} type="password" className="form-control" id="userpassword" placeholder="Confirm Password"/>
                                                    </FormGroup>
                            

                                                    <div className="text-center">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading ..." : "Register"}</Button>
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <p className="mb-0">By registering you agree to the Intrucept CSPM <Link to="/terms-and-conditions" className="text-primary">Terms of Use</Link></p>
                                                    </div>
                                                </AvForm>
                                            </div>

                                            <div className="mt-5 text-center">
                                                <p>Already have an account ? <Link to="/login" className="font-weight-medium text-primary"> Login</Link> </p>
                                                <p>© 2023 All Rights Reserved by Intrucept Labs</p>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {

  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
}

export default connect(mapStatetoProps, { registerUser, apiError, registerUserFailed })(Register);
