import React, { Component } from "react";
import { Media,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { ScatterChart,Scatter,BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBDataTable } from "mdbreact";
import {url} from '../../services/common';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";


class RegulatoryCompliance extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            activeTab: 1,
            isLoading:false,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            widgets:[],
            tableData:[],
            tableDataPCIDSS:[],
            tableDataGDPR:[],
            tableDataNIST:[],
            tableDataGPG:[],
            tableDataTSC:[],
            tableDataHIPAA:[],  
            piedata:[],
            data01:[],
            data02:[],
            startDate:null,
            endDate:null,
        }


        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);
    
        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }

    onStartDateChange(e){
        this.setState({endDate:null})
        this.setState({startDate:new Date(e.target.value)})
    }

    exportPDFWithMethod(){

      let element = this.container.current || document.body;
      savePDF(element, {
        paperSize: "auto",
        margin: 40,
        fileName: `Report for ${new Date().getFullYear()}`,
      });
    }
  
    exportPDFWithComponent(){
      if (this.pdfExportComponent.current) {
          this.pdfExportComponent.current.save();
      }
    };


    onEndDateChange(e){
        //console.log(e.target.value)
        this.setState({endDate:new Date(e.target.value)})
    }
  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
       this.getDotData()
       this.getChartData()
       this.getTableData()
    }

    getDotData = () => {

        this.setState({isLoading: true})
        fetch(`${url}/anomaly/regulatory-compliance/dotchart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({data01:json.data01,data02:json.data02}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }
    
    getChartData = () => {

        this.setState({isLoading: true})
        fetch(`${url}/anomaly/regulatory-compliance/chart`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({data:json}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoadin: false})); 
    }

    getTableData = () => {
        
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/regulatory-compliance/table`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {

            var theData = json.table;

            var pciData = [];
            var gdprData = [];
            var nistData = [];
            var gpgData = [];
            var tscData = [];
            var hipaaData = [];


            for(var i=0;i<theData.length;i++){

              if(theData[i].rule_groups.includes('pci')){

                if(theData[i].agent){
                    theData[i].agent = 'cdtdev';//theData[i].agent.name;
                    pciData.push(theData[i])
                }

               
              }

              if(theData[i].rule_groups.includes('gdpr')){

                if(theData[i].agent){
                   theData[i].agent = 'cdtdev';//theData[i].agent.name;
                   gdprData.push(theData[i])
                }

                
              }

              if(theData[i].rule_groups.includes('nist')){

                if(theData[i].agent){
                    theData[i].agent = 'cdtdev';//theData[i].agent.name;
                    nistData.push(theData[i])
                }

                
              }

              if(theData[i].rule_groups.includes('gpg')){

                if(theData[i].agent){
                    theData[i].agent = 'cdtdev';//theData[i].agent.name;
                    gpgData.push(theData[i])
                }

               
              }

              if(theData[i].rule_groups.includes('tsc')){
                if(theData[i].agent){
                   theData[i].agent = 'cdtdev';//theData[i].agent.name;
                   tscData.push(theData[i])
                }
                
              }

              if(theData[i].rule_groups.includes('hipaa')){

                if(theData[i].agent){
                   theData[i].agent = 'cdtdev';//theData[i].agent.name;
                   hipaaData.push(theData[i])
                }
                
              }

            }
            
            this.setState({tableData:json.table})

            this.setState({tableDataPCIDSS:pciData})
            this.setState({tableDataGDPR:gdprData})
            this.setState({tableDataNIST:nistData})
            this.setState({tableDataGPG:gpgData})
            this.setState({tableDataTSC:tscData})
            this.setState({tableDataHIPAA:hipaaData})
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }
    

    render() {

        const dataPCIDSS = {
            columns: [
              {
                label: "Timestamp",
                field: "timestamp",
                sort: "asc",
                width: 30
              },
              {
                label: "Agent",
                field: "agent",
                sort: "asc",
                width: 30
              },
              {
                label: "PCI Requirement",
                field: "pci_requirement",
                sort: "asc",
                width: 30
              },
              {
                label: "PCI Description",
                field: "description",
                sort: "asc",
                width: 30
              },
              {
                label: "PCI Milestone",
                field: "milestone",
                sort: "asc",
                width: 30
              },
                          
              {
                label: "Event Description",
                field: "rule_description",
                sort: "asc",
                width: 100
              },             
              
            ],
            rows: [
      
            ]
          };


          const dataGDPR = {
            columns: [
              {
                label: "Timestamp",
                field: "timestamp",
                sort: "asc",
                width: 30
              },
              {
                label: "Agent",
                field: "agent",
                sort: "asc",
                width: 30
              },
              {
                label: "Requirement",
                field: "gdpr_requirement",
                sort: "asc",
                width: 30
              },
              {
                label: "Description",
                field: "description",
                sort: "asc",
                width: 30
              },
              {
                label: "Concept",
                field: "concept",
                sort: "asc",
                width: 30
              },
                          
              {
                label: "Event Description",
                field: "rule_description",
                sort: "asc",
                width: 100
              },            
              
            ],
            rows: [
      
            ]
          };


          const dataNIST = {
            columns: [
              {
                label: "Timestamp",
                field: "timestamp",
                sort: "asc",
                width: 30
              },
              {
                label: "Agent",
                field: "agent",
                sort: "asc",
                width: 30
              },
                          
              {
                label: "Event Description",
                field: "rule_description",
                sort: "asc",
                width: 100
              },         
              
            ],
            rows: [
      
            ]
          };



          const dataGPG = {
            columns: [
              {
                label: "Timestamp",
                field: "timestamp",
                sort: "asc",
                width: 30
              },
              {
                label: "Agent",
                field: "agent",
                sort: "asc",
                width: 30
              },
                          
              {
                label: "Event Description",
                field: "rule_description",
                sort: "asc",
                width: 100
              },       
              
            ],
            rows: [
      
            ]
          };



          const dataTSC = {
            columns: [
              {
                label: "Timestamp",
                field: "timestamp",
                sort: "asc",
                width: 30
              },
              {
                label: "Agent",
                field: "agent",
                sort: "asc",
                width: 30
              },
                          
              {
                label: "Event Description",
                field: "rule_description",
                sort: "asc",
                width: 100
              },           
              
            ],
            rows: [
      
            ]
          };


          const dataHIPAA = {
            columns: [
              {
                label: "Timestamp",
                field: "timestamp",
                sort: "asc",                
                width: 30
              },
              {
                label: "Agent",
                field: "agent",
                sort: "asc",
                width: 30
              },
                          
              {
                label: "Description",
                field: "rule_description",
                sort: "asc",
                width: 100
              },         
              
            ],
            rows: [
      
            ]
          };
      
          // PCI DSS
          var theDataPCIDSS = {};
          theDataPCIDSS.columns = dataPCIDSS.columns;
          var theRowsPCIDSS = this.state.tableDataPCIDSS;
          theDataPCIDSS.rows = theRowsPCIDSS;
         
          // GDPR
          var theDataGDPR = {};
          theDataGDPR.columns = dataGDPR.columns;
          var theRowsGDPR = this.state.tableDataGDPR;
          theDataGDPR.rows = theRowsGDPR;

          // NIST 800-53
          var theDataNIST = {};
          theDataNIST.columns = dataNIST.columns;
          var theRowsNIST = this.state.tableDataNIST;
          theDataNIST.rows = theRowsNIST;

          // GPG 13
          var theDataGPG = {};
          theDataGPG.columns = dataGPG.columns;
          var theRowsGPG = this.state.tableDataGPG;
          theDataGPG.rows = theRowsGPG;

          // TSC SOC2
          var theDataTSC = {};
          theDataTSC.columns = dataTSC.columns;
          var theRowsTSC = this.state.tableDataTSC;
          theDataTSC.rows = theRowsTSC;

          // HIPAA
          var theDataHIPAA = {};
          theDataHIPAA.columns = dataHIPAA.columns;
          var theRowsHIPAA = this.state.tableDataHIPAA;
          theDataHIPAA.rows = theRowsHIPAA;

          return (
            <React.Fragment>

                   <Button
                        color="info"
                        className="waves-effect waves-light mr-1 pull-right"
                        onClick={()=> this.exportPDFWithComponent()}
                        style={{float:'right', display:'block'}}
                    >
                        Export to PDF
                 </Button>


                { this.state.isLoading ?
                        <div>
                        <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={'100%'}   
                            timeout={5000} //3 secs
                        />  
                        </div>     
                    :
                    <div style={{width:'100%', marginLeft:50}}>
                        <Row className="mt-4">
                            <h4>{this.props.heading}</h4>
                        </Row>
                        <Row className="mt-4">
                        <AvForm inline >
                            Filter Data
                            <Col style={{display:'flex', flexDirection:'row'}}>                               

                                 <input type="date" name="start_date" onChange={this.onStartDateChange}/><input type="time" name="start_time" onChange={this.onStartTimeChange}/> &nbsp; &nbsp;to &nbsp; &nbsp;
                                 <input type="date" name="end_date" onChange={this.onEndDateChange} /><input type="time" name="end_time" onChange={this.onEndTimeChange}/>
                              </Col>
                        </AvForm>
                        </Row>

                        <PDFExport
                       ref={this.pdfExportComponent}
                       paperSize="auto"
                       margin={40}
                       fileName={`Report for ${new Date().getFullYear()}`}
                       author="KendoReact Team"
                    >

                    <div className="mainContaindiv" style={{padding:50}}> 


                        <Row className="mt-4" style={{display:'none'}}>
                            <AvForm inline >
                                <Col><AvField type="text" placeholder="Search"  name="clusterName" required />{' '}</Col>
                                <Col><AvField type="date" name="AZURE_CLIENT_ID" required />{' '}</Col>
                                <Col><Button outline color="primary" type="submit">Refresh</Button></Col>
                            </AvForm>
                        </Row>
                        
                        <Row className="mt-4" style={{display:'none'}}>
                            <Col md={6}>
                                <h4>PCI DSS requirements</h4>
                                <ResponsiveContainer width="100%" height={400}>
                                    <ScatterChart
                                    width={500}
                                    height={400}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                    >
                                    <CartesianGrid />
                                    <XAxis type="number" dataKey="x" name="stature" unit="cm" />
                                    <YAxis yAxisId="left" type="number" dataKey="y" name="weight" unit="kg" stroke="#8884d8" />
                                    <YAxis
                                        yAxisId="right"
                                        type="number"
                                        dataKey="y"
                                        name="weight"
                                        unit="kg"
                                        orientation="right"
                                        stroke="#82ca9d"
                                    />
                                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                    <Legend />
                                    <Scatter yAxisId="left" name="6.5" data={this.state.data01} fill="#8884d8" />
                                    <Scatter yAxisId="right" name="10.2.4" data={this.state.data02} fill="#82ca9d" />
                                    </ScatterChart>
                                </ResponsiveContainer>
                            </Col>
                            <Col md={6}>
                                <h4>Requirements by agent</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.data}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="Amazon" stackId="a" fill="#8884d8" />
                                        <Bar dataKey="Centos" stackId="a" fill="#82ca9d" />
                                        <Bar dataKey="Debian" stackId="a" fill="#F2F809" />
                                        <Bar dataKey="RHEL7" stackId="a" fill="#FF5733" />
                                        <Bar dataKey="Ubuntu" stackId="a" fill="#C70039" />
                                        <Bar dataKey="Windows" stackId="a" fill="#09ECF8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>

                        <Row className="mt-4" style={{marginBottom:50}}>                            
                            <Col md={12}>
                                <h5>PCI DSS Related Anomalies</h5>
                                <MDBDataTable responsive searching={true} bordered data={theDataPCIDSS} />
                            </Col>
                            <Col md={12}>
                                <h5>GDPR Related Anomalies</h5>
                                <MDBDataTable responsive searching={true} bordered data={theDataGDPR} />
                            </Col>
                            <Col md={12}>
                                <h5>NIST 800-53 Related Anomalies</h5>
                                <MDBDataTable responsive searching={true} bordered data={theDataNIST} />
                            </Col>
                            <Col md={12}>
                                <h5>GPG 13 Related Anomalies</h5>
                                <MDBDataTable responsive searching={true} bordered data={theDataGPG} />
                            </Col>
                            <Col md={12}>
                                <h5>TSC SOC2 Related Anomalies</h5>
                                <MDBDataTable responsive searching={true} bordered data={theDataTSC} />
                            </Col>
                            <Col md={12}>
                                <h5>HIPAA Related Anomalies</h5>
                                <MDBDataTable responsive searching={true} bordered data={theDataHIPAA} />
                            </Col>
                        </Row>

                        </div>

                        </PDFExport>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default RegulatoryCompliance;
