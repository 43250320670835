import React, { Component } from "react";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Button } from "reactstrap";
import {url} from '../../services/common';
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import toastr from 'toastr';
import {Helmet} from "react-helmet";

class ResultIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Dashboard", link : "/dashboard" },
                { title : "Result Integration", link : "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            keys: {
                emails: null,
                jira_project_url:null,
                jira_project_key:null,
                jira_project_username:null,
                jira_project_api_token:null,
                trello_app_key:null,
                trello_board_url:null,
                trello_list_name:null,
                trello_api_token:null,
                slack_webhook_url:null
            }
        };
    }

    componentDidMount()
    {
       this.getUserKey();
    }

    getUserKey = () => {
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/get-user-keys`, {
            method: "GET",       
            headers: {
                'Accept': 'application/json',  
                'Authorization': 'Bearer '+authToken   
            },
         })
         .then((response) => response.json())
         .then((json) => {
             if(json.success)
             {
                var { keys } = this.state
                keys.emails = json.keys.emails 
                keys.jira_project_url = json.keys.jira_project_url
                keys.jira_project_key = json.keys.jira_project_key
                keys.jira_project_username = json.keys.jira_project_username
                keys.jira_project_api_token = json.keys.jira_project_api_token
                keys.trello_app_key = json.keys.trello_app_key
                keys.trello_board_url = json.keys.trello_board_url
                keys.trello_list_name = json.keys.trello_list_name
                keys.trello_api_token = json.keys.trello_api_token
                keys.slack_webhook_url = json.keys.slack_webhook_url
                this.setState({keys})
             }
             
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));
    }

    handleEmailsSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-emails-keys`, {
            method: "POST",        
            body:JSON.stringify({
                emails:values.emails,
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
             if(json.success){
               // toastr.success(json.message, 'Success');
             }else{
                //toastr.error(json.message, 'Error');
             }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }

    handleJiraSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-jira-keys`, {
            method: "POST",        
            body:JSON.stringify({
                jira_project_url:values.jira_project_url,
                jira_project_key:values.jira_project_key,
                jira_project_username:values.jira_project_username,
                jira_project_api_token:values.jira_project_api_token,
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
             if(json.success){
               // toastr.success(json.message, 'Success');
             }else{
              //  toastr.error(json.message, 'Error');
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }

    handleTrelloSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-trello-keys`, {
            method: "POST",        
            body:JSON.stringify({
                trello_app_key:values.trello_app_key,
                trello_board_url:values.trello_board_url,
                trello_list_name:values.trello_list_name,
                trello_api_token:values.trello_api_token,
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
            if(json.success){
               // toastr.success(json.message, 'Success');
            }else{
                //toastr.error(json.message, 'Error');
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }
    
    handleSlackSubmit = (event, values) => {
        this.setState({isLoading: true});  
        let authToken = localStorage.getItem('auth_token');
        fetch(`${url}/store-slack-keys`, {
            method: "POST",        
            body:JSON.stringify({
                slack_webhook_url: values.slack_webhook_url,
            }),       
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+authToken   
            },

         })
         .then((response) => response.json())
         .then((json) => {
            if(json.success){
               // toastr.success(json.message, 'Success');
            }else{
                //toastr.error(json.message, 'Error');
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false}));  

    }

    render() {
        return (
            <React.Fragment>
                <Helmet><title>Result Inegrations</title></Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Result Inegrations" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Emails</h4>
                                        <AvForm onValidSubmit={this.handleEmailsSubmit} className="form-horizontal" >
                                            <FormGroup row>
                                                <Label  className="col-md-2 col-form-label">Email Addresses (comma separated)</Label>
                                                <Col md={10}>
                                                    <AvField className="form-control" name="emails" value={this.state.keys.emails} validate={{required: true}} type="text"  />
                                                </Col>
                                            </FormGroup>
                                            
                                            <FormGroup row>
                                                <Col style={{textAlign:"right"}}>
                                                    <Button
                                                    color="primary"
                                                    className=" waves-effect waves-light mr-1 pull-right"
                                                    type="submit"
                                                    >
                                                    Save Emails
                                                    </Button>   
                                                </Col>
                                            </FormGroup>  
                                        </AvForm> 
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">JIRA</h4>
                                        <AvForm onValidSubmit={this.handleJiraSubmit} className="form-horizontal" >
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Enter Project URL</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="jira_project_url" value={this.state.keys.jira_project_url} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row> 
                                            <Label className="col-md-2 col-form-label">Enter Project Key</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="jira_project_key" value={this.state.keys.jira_project_key} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Enter Username</Label>
                                            <Col md={10}>
                                            <AvField className="form-control" name="jira_project_username" value={this.state.keys.jira_project_username} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Enter API Token</Label>
                                            <Col md={10}>
                                            <AvField className="form-control" name="jira_project_api_token" value={this.state.keys.jira_project_api_token} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Col style={{textAlign:"right"}}>
                                                <Button
                                                color="primary"
                                                className=" waves-effect waves-light mr-1 pull-right"
                                                >
                                                    Save JIRA Keys
                                                </Button> 
                                             </Col>  
                                        </FormGroup>
                                        </AvForm>   
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>    
                        
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">TRELLO</h4>
                                        <AvForm onValidSubmit={this.handleTrelloSubmit} className="form-horizontal" >
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Enter App Key</Label>
                                            <Col md={10}>
                                            <AvField className="form-control" name="trello_app_key" value={this.state.keys.trello_app_key} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Enter Board URL</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="trello_board_url" value={this.state.keys.trello_board_url} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Enter List Name</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="trello_list_name" value={this.state.keys.trello_list_name} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Enter API Token</Label>
                                            <Col md={10}>
                                                <AvField className="form-control" name="trello_api_token" value={this.state.keys.trello_api_token} validate={{required: true}} type="text" />
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Col style={{textAlign:"right"}}>
                                                <Button
                                                color="primary"
                                                className=" waves-effect waves-light mr-1 pull-right"
                                                >
                                                Save Trello Keys
                                                </Button>   
                                             </Col>
                                        </FormGroup> 
                                        </AvForm> 
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Slack</h4>
                                        <AvForm onValidSubmit={this.handleSlackSubmit} className="form-horizontal" >
                                        <FormGroup row>
                                            <Col md={6}>
                                                <Label>Enter Webhook URL</Label>
                                                <AvField className="form-control" name="slack_webhook_url" readOnly={true}  value="https://hooks.slack.com/services/T77CCAXM4/B017" validate={{required: true}} type="text" />
                                                <Button
                                                   color="primary"
                                                   className=" waves-effect waves-light mr-1 pull-right"
                                                 >
                                                   Save Slack Webhook URL
                                                </Button> 
                                            </Col>
                                            <Col md={6}>                                                
                                                
                                            </Col>
                                        </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>  
                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ResultIntegration;
