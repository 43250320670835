import React, { Component } from "react";
import { List,Media,Button,Input,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader } from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import { PieChart,Pie,BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import { MDBTable, MDBTableBody, MDBTableHead  } from "mdbreact";
import {url} from '../../services/common';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";


class ConfigurationAssessment extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            activeTab: 1,
            isLoading:false,
            authToken: localStorage.getItem('auth_token'),
            data:[],
            widgets:[],
            tableData:[],
            piedata:[],
            startDate:null,
            endDate:null,
            searchKey:'',
            filteredTableData:[],
        }

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);
    
        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }

    handleChange(e){

        this.setState({searchKey:e.target.value})  
       
        var tData = this.state.tableData;
        var newTable =[];
        for(var i=0;i<tData.length;i++){
            if((tData[i].title.toLowerCase().includes(e.target.value.toLowerCase()))
             || (tData[i].file.toLowerCase().includes(e.target.value.toLowerCase()))
             || (tData[i].policy.toLowerCase().includes(e.target.value.toLowerCase()))
            
            ){
                newTable.push(tData[i]);
            }
        }

        this.setState({filteredTableData:newTable})       

    }

    onStartDateChange(e){
        this.setState({endDate:null})
        this.setState({startDate:new Date(e.target.value)})
    }

    onEndDateChange(e){
        //console.log(e.target.value)
        this.setState({endDate:new Date(e.target.value)})
    }

    exportPDFWithMethod(){

        let element = this.container.current || document.body;
        savePDF(element, {
          paperSize: "auto",
          margin: 40,
          fileName: `Report for ${new Date().getFullYear()}`,
        });
      }
    
    exportPDFWithComponent(){
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
      };


    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
       this.getPieData()
       this.getTableData()
    }

    COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    RADIAN = Math.PI / 180;

    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
        const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
        
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    getPieData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/configuration-assessment/pie`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
             }            
        })
         .then((response) => response.json())
         .then((json) => { console.log(json);this.setState({piedata:json}) })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }

    getTableData = () => {
        this.setState({isLoading: true})
        fetch(`${url}/anomaly/configuration-assessment/table`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
             }            
        })
         .then((response) => response.json())
         .then((json) => {
             this.setState({widgets:json.widgets,tableData:json.table, filteredTableData:json.table})
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }

    render() {
        return (
            <React.Fragment>

                 <Button
                        color="info"
                        className="waves-effect waves-light mr-1 pull-right"
                        onClick={()=> this.exportPDFWithComponent()}
                        style={{float:'right', display:'block'}}
                    >
                        Export to PDF
                 </Button>


                {
                    this.state.isLoading ?
                    <div>
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={'100%'}   
                        timeout={5000} //3 secs
                    />  
                    </div>     
                :
                    <div className="mainContaindiv">
                        <Row className="mt-4">
                            <h4>{this.props.heading}</h4>
                        </Row>

                        
                        <Row className="mt-4">
                            <h4>CIS Benchmark for Red Hat EnterPrise Linux 7</h4>
                        </Row>

                        <Row className="mt-4">
                    <AvForm inline >
                            Filter Data
                            <Col style={{display:'flex', flexDirection:'row'}}>                                

                                 <input type="date" name="start_date" onChange={this.onStartDateChange}/><input type="time" name="start_time" onChange={this.onStartTimeChange}/> &nbsp; &nbsp;to &nbsp; &nbsp;
                                 <input type="date" name="end_date" onChange={this.onEndDateChange} /><input type="time" name="end_time" onChange={this.onEndTimeChange}/>
                              </Col>
                        </AvForm>
                    </Row>

                    <PDFExport
                       ref={this.pdfExportComponent}
                       paperSize="auto"
                       margin={40}
                       fileName={`Report for ${new Date().getFullYear()}`}
                       author="KendoReact Team"
                    >

                    <div className="mainContaindiv" style={{padding:50}}> 

                    
                        <Row className="mt-4" style={{width:'100%'}}>
                            {
                                this.state.widgets.map((item,index)=>{
                                    return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <Media>
                                                            <Media body className="overflow-hidden">
                                                                <p className="font-size-14 mb-2 text-left">{item.name}</p>
                                                                <h4 className="mb-0 ">{item.value}</h4>
                                                            </Media>
                                                            <div className="text-primary">
                                                                <i className={"ri-stack-line font-size-24"}></i>
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                })   
                            }
                        </Row>

                        

                        <Row className="mt-4" style={{display:'none'}}>
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th>Time</th>
                                        <th>agent.name</th>
                                        <th>rule.mitre.id</th>
                                        <th>rule.mitre.tactic</th>
                                        <th>rule.description</th>
                                        <th>rule.level</th>
                                        <th>rule.id</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {
                                        this.state?.filteredTableData?.map((item,index)=>{
                                            return <tr>
                                                        <td>{item?.timestamp}</td>
                                                        <td>{item?.agent?.name}</td>
                                                        <td>{item?.rule_mitre_id?.[0]}</td>
                                                        <td>{item?.rule_mitre_tactic?.[0]}</td>
                                                        <td>{item?.rule_description}</td>
                                                        <td>{item?.rule_level}</td>
                                                        <td>{item?.rule_id}</td>
                                                    </tr>
                                        })
                                    }
                                </MDBTableBody>
                            </MDBTable>
                        </Row>

                        <Row className="mt-4" style={{display:'none'}}>
                            <Col>
                                <List type="unstyled">
                                    <li>
                                        <h5>Rationale</h5>
                                        <p className="text-left">FTP does not protect the confidentiality of data or authentication credentials. It is recommended sftp be used if file transfer is required. Unless there is a need to run
                                            the system as a FTP server (for example to allow anonymous downloads), it is recommended that the service be disabled to reduce the potential attack surface.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>Remediation</h5>
                                        <p className="text-left">Run the following command to disable vsftpd: # systemctl disable vsftpd.</p>
                                    </li>
                                    <li>
                                        <h5>Description</h5>
                                        <p className="text-left">The File Transfer Protocol (FTP) provides networked computers with the ability to transfer files</p>
                                    </li>
                                    <li>
                                        <h5>check(Condition:none)</h5>
                                        <p className="text-left">c:systemctl is-enabled vsftpd r:^enabled</p>
                                    </li>
                                    <li>
                                        <h5>Compliance</h5>
                                        <p className="text-left">CIS:2.2.9  PCI DSS:2.2.2  NIST 800-53:CM.1  CIS CSS:9.1</p>
                                    </li>
                                </List>
                            </Col>
                        </Row>


                        <Row className="mt-4" style={{marginBottom:30}}>
                            <Col md={3}>
                                <h4>Hardening Result</h4>
                                <ResponsiveContainer width="100%"  height={400} >
                                    <PieChart width={400} height={400}>
                                    
                                        <Pie
                                            data={this.state.piedata}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={this.renderCustomizedLabel}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {this.state.piedata.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Legend />
                                        
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>
                            <Col md={9}>
                                <h4>Security Alerts</h4>

                                <Row className="mt-4">

                         <div data-test="datatable-input" class="mdb-datatable-filter flex-row">
                              <input class="form-control form-control-sm ml-0 my-1" 
                                 type="text" 
                                 placeholder="Search" 
                                 aria-label="Search" 
                                 onChange={(e) => {this.handleChange(e)}}
                                 value={this.state.searchKey}/>
                        </div>
                      </Row>


                                <MDBTable responsive>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Title</th>
                                            <th>File</th>
                                            <th>Policy</th>
                                            
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                            this.state?.filteredTableData?.map((item,index)=>{
                                                return <tr>
                                                            <td>{item?.timestamp}</td>
                                                            <td>{item?.title}</td>
                                                            <td>{item?.file}</td>
                                                            <td>{item?.policy}</td>                                                            
                                                        </tr>
                                            })
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </Col>
                        </Row>

                        </div>
                        </PDFExport>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default ConfigurationAssessment;
