import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, ButtonGroup} from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
//Import Components
import MiniWidgets from "./MiniWidgets";
import LargeWidgets from "./MiniWidgets";
import ScanResultsRatio from "./ScanResultsRatio";
import ThreatsComparison from "./ThreatsComparison";
import ThreatOccurenceComparison from "./ThreatOccurenceComparison";
import BarChart from "./barchart";
import MostAffectedCategories from "./MostAffectedCategories";
import MostAffectedRegions from "./MostAffectedRegions";
import {url} from '../../services/common';
import {Helmet} from "react-helmet";
import ThreatsMap from './ThreatsMap'

class Dashboard extends Component {
	
    constructor(props) {

        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link : "#" },
                { title : "Dashboard", link : "#" },
            ],
            reports : [
                { icon : "ri-briefcase-4-line", key:"allScans", title : "ALL SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-stack-line", key:"AwsScancount", title : "AWS SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-store-2-line", key:"AzureScancount", title : "AZURE SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"GcpScancount", title : "GCP SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"OciScancount", title : "OCI SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"ThreatsFound", title : "THREATS ", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"categoryName", title : "MOST AFFECTED CATEGORIES", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"regionName", title : "MOST AFFECTED REGION", value : "0", rate : "2.4%", desc : "From previous period" },
            ],
            AffectedCards : [
                { icon : "ri-briefcase-4-line", key:"categoryName", title : "MOST AFFECTED CATEGORIES", value : "N/A", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-stack-line", key:"regionName", title : "MOST AFFECTED REGIONS", value : "N/A", rate : "2.4%", desc : "From previous period" },
            ],
            SuperAdminreports : [
                { icon : "ri-stack-line", key:"Alluser", title : "ALL USERS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-stack-line", key:"AwsScancount", title : "AWS SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-store-2-line", key:"AzureScancount", title : "AZURE SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"GcpScancount", title : "GCP SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"OciScancount", title : "OCI SCANS", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"ThreatsFound", title : "THREATS FOUND", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"categoryName", title : "MOST AFFECTED CATEGORIES", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", key:"regionName", title : "MOST AFFECTED REGIONS", value : "0", rate : "2.4%", desc : "From previous period" },
            ],
            isLoading:false,
            loadingMessage:'',
            scans:null,
            threatsAnalytics:null,
            threats:null,
            barChart:null,
            user_type:'normal',
            tableData:[],
            show:false,
            allPerms:[],
            singleUserPerms:[],
        }
    }
    // url to a valid topojson file
    // geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
        if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
        }else{
           this.props.history.push('/');    
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet><title>Dashboard</title></Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        {
                            this.state.user_type === 'admin' ? 
                            <>
                                <Row>
                                    <Col xl={8}>
                                        <Row>
                                            <LargeWidgets />
                                        </Row>
                                        <BarChart />
                                        <MostAffectedCategories />
                                        <MostAffectedRegions />
                                    </Col>
                                    <Col xl={4}>
                                        <ScanResultsRatio />
                                        <ThreatsComparison/>
                                        <ThreatOccurenceComparison/>
                                    </Col>
                                </Row>
                            </>
                            : 
                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets/>
                                    </Row>
                                    <BarChart/>
                                    <MostAffectedCategories />
                                    <MostAffectedRegions />
                                </Col>
                                <Col xl={4}>
                                    <ScanResultsRatio />
                                    <ThreatsComparison />
                                    <ThreatOccurenceComparison/>
                                </Col>
                            </Row>
                        }
                        <Row> 
                            <Col xl={8}>
                                <ThreatsMap />
                            </Col>   
                        </Row>     
                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
