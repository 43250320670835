import React, { Component } from "react";
import { Container, Row,  Modal, ModalHeader, ModalBody, ModalFooter, Col, Card, CardBody, ButtonGroup,Input,Button,FormGroup,Label} from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import toastr from 'toastr'
//Import Components
import LargeWidgetsDocker from "./LargeWidgetsDocker";
import MiniWidgetsDocker from "./MiniWidgetsDocker";
import DockerLineChart from './DockerLineChart'
import DockerLowMediumHigh from './DockerLowMediumHigh'
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import {url} from '../../services/common';
import {Helmet} from "react-helmet";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import './pdfStyles.css';

class dockerVulnManagement extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link : "#" },
                { title : "Dashboard", link : "#" },
            ],
            authToken:localStorage.getItem('auth_token'),  
            isLoading:false,
            loadingMessage:'',
            scans:null,
            threatsAnalytics:null,
            threats:null,
            barChart:null,
            user_type:'normal',
            tableData:[],
            show:false,
            allPerms:[],
            singleUserPerms:[],
            addimageModal:false,
        }

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);

        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }

    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }
    }


    exportPDFWithMethod(){

        let element = this.container.current || document.body;
        savePDF(element, {
          paperSize: "auto",
          margin: 40,
          fileName: `Report for ${new Date().getFullYear()}`,
        });
      }
    
      exportPDFWithComponent(){
        
         if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
         }
      };

    addimagetoggle = () => {
        this.setState({addimageModal:!this.state.addimageModal})
    }

    addImageData = (e,value) => {
        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Adding Image'});    
        fetch(`${url}/dockers-images/create`, {
            method: "POST",
            body:JSON.stringify(value),     
            headers: {
                'Accept': 'application/json', 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            if(json.success){
                //toastr.success(json.message,'Success');
            }else{
               // toastr.error(json.message,'Error');
            }
        })
        .catch((error) => toastr.error('Something went wrong','Error'))
        .finally(() => {
            this.setState({isLoading: false});
            this.addimagetoggle();
        }); 
    }

    render() {
        return (
            <React.Fragment>
                <Helmet><title>Docker Image Vulnerability Management Dashboard</title></Helmet>

                <PDFExport
                   ref={this.pdfExportComponent}
                   paperSize="auto"
                   margin={40}
                   fileName={`Report for ${new Date().getFullYear()}`}
                   author="KendoReact Team"
               >


                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Docker Image Vulnerability Management Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        {
                            this.state.user_type === 'admin' ? 
                            <>
                                <Row>
                                    <Col xl={8}>
                                        <Row>
                                            <LargeWidgetsDocker history={this.props.history} />
                                        </Row>
                                    </Col>
                                    <Col xl={4} style={{justifyContent:'right'}}>
                                        <Button
                                            color="primary"
                                            onClick={() => this.addimagetoggle()}
                                            className=" waves-effect waves-light mr-1 pull-right"
                                        >
                                            Add a Docker Image
                                        </Button> 
                                        {' '}
                                        <Button
                                            color="success"
                                            onClick={()=>{this.props.history.push('/docker/all-images')}}
                                            className=" waves-effect waves-light mr-1 pull-right mt-3"
                                        >
                                            View All Added Images
                                        </Button>

                                        <Button
                                            color="info"
                                            onClick={()=> this.exportPDFWithComponent()}
                                            className=" waves-effect waves-light mr-1 pull-right mt-3"
                                        >
                                            Export to PDF
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : 
                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgetsDocker history={this.props.history}/>
                                    </Row>
                                </Col>
                                <Col xl={4}>
                                    <Button
                                        color="primary"
                                        onClick={() => this.addimagetoggle()}
                                        className=" waves-effect waves-light mr-1 pull-right"
                                    >
                                        Add a Docker Image
                                    </Button> 
                                    {' '}
                                    <Button
                                        color="success"
                                            onClick={()=>{this.props.history.push('/docker/all-images')}}
                                            className=" waves-effect waves-light mr-1 pull-right mt-3"
                                        >
                                        View All Added Images
                                    </Button>
                                </Col>
                            </Row>
                        }
                        <Row> 
                            <Col xl={8}>
                                <DockerLineChart  />
                            </Col> 
                            <Col xl={4}>
                                <DockerLowMediumHigh />
                            </Col>  
                        </Row>     
                    </Container> 

                    <Modal isOpen={this.state.addimageModal} toggle={this.addimagetoggle} backdrop={false}>
                        <ModalHeader toggle={this.addimagetoggle}>Add a docker image</ModalHeader>
                            <ModalBody>
                                <AvForm style={{padding:'10px'}}
                                    onValidSubmit={this.addImageData}
                                >
                                    <AvGroup>
                                        <Label>Image Name</Label>
                                        <AvField type="text" className="form-control" name="image_name" required />
                                    </AvGroup>
                                    <AvGroup style={{float:'right'}}>
                                        <Button color="primary" type="submit">Add Image</Button>
                                    </AvGroup>
                                </AvForm>
                            </ModalBody>
                    </Modal>
                </div>

                </PDFExport>
            </React.Fragment>
        );
    }
}

export default dockerVulnManagement;
