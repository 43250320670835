import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Row, Col, Card, Modal, Container, ModalHeader, ModalBody, ModalFooter, CardBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../Tables/datatables.scss";

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from "react-loader-spinner";
//Import Charts
import ReactApexChart from 'react-apexcharts';
import {url,Downloadurl} from '../../services/common';
import "../Dashboard/dashboard.scss";
import toastr from 'toastr'
import {Helmet} from "react-helmet";

class OracleCloudScans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "Dashboard", link : "/dashboard" },
        { title : "Oracle Cloud Scans History", link : "#" },
    ],
    series: [42.0, 26.0, 15.0],
    options : {
        labels: ["OK", "UNKNOWN", "FAILED"],
        plotOptions: {
            pie: {
                donut: {
                    size: '75%'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
        },
        colors: ['#0EC905', '#FFC300', '#CA0B00'],
    
    },
    tableData:[],
    isLoading:false,
    loadingMessage:'',
    showResultModal:false,
    currentlyShowingScan:{},
    currentScanId:null,
    show:false,
    scanName:'',
    };
    
    this.setTheTableData = this.setTheTableData.bind(this); 
    this.showTheResult = this.showTheResult.bind(this); 
    this.goBack = this.goBack.bind(this);  
    this.createScan = this.createScan.bind(this);        
    this.setPieData = this.setPieData.bind(this);        
    this.downloadpdf = this.downloadpdf.bind(this);        

  }
  
  child   = { width: `30em`, height: `100%`}
  
  ref = React.createRef();
  
  options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4,2]
  };

  componentDidMount()
  {
  	  this.setState({isLoading: true});    
  	  this.setState({loadingMessage: 'Loading Scan History'});    
  	  var authToken = localStorage.getItem('auth_token');  	 
      fetch(`${url}/fetch-oci-scans`, {
                  method: "GET",     
                  headers: {
                    'Accept': 'application/json',  
                    'Authorization': 'Bearer '+authToken                  
                   }            
               })
               .then((response) => response.json())
               .then((json) => this.setTheTableData(json))
               .catch((error) => console.error('ss'+error))
               .finally(() => this.setState({isLoading: false})); 
               
  }
  setTheTableData(json){  	 
        this.setState({tableData: json.ociScans});  
  }
  goBack(json){  	 
  	   this.setState({showResultModal: false});    
  }

  showTheResult(ScanId)
  {  	 
      this.setState({isLoading: true})
      this.setState({loadingMessage: 'Loading ...'});    
      let authToken = localStorage.getItem('auth_token');  	 
      fetch(`${url}/get-oci/threats/${ScanId}`, {
                  method: "GET",     
                  headers: {
                    'Accept': 'application/json',  
                    'Authorization': 'Bearer '+authToken                  
                  }            
              })
              .then((response) => response.json())
              .then((json) => {
                let {currentlyShowingScan,showResultModal,currentScanId}  = this.state;
                if(json.success)
                {
                    currentlyShowingScan  = json.data
                    currentScanId  = ScanId
                    this.setPieData(currentlyShowingScan);
                    this.setState({currentlyShowingScan,showResultModal: true,currentScanId},()=>{
                      //console.log(currentlyShowingScan);
                      //console.log(showResultModal);
                    });   
                }else{
                    this.setState({isLoading: false})
                }
              })
              .catch((error) => console.error('ss'+error))
              .finally(() => this.setState({isLoading: false})); 
  }
  setPieData(pieData){
    let ok  = 0;
    let UNKNOWN = 0;
    let FAIL = 0;
    let total = pieData.length;
    var {series} = this.state
    for(var i=0;i<pieData.length;i++)
    {
      if(pieData[i].status === "OK"){
        ok = ok + 1;
      }else if(pieData[i].status === "UNKNOWN"){
        UNKNOWN = UNKNOWN + 1;
      }else if(pieData[i].status === "FAIL"){
        FAIL = FAIL + 1;
      }
    }  
    series = [
      parseFloat(((ok*100)/total).toFixed(2)),
      parseFloat(((UNKNOWN*100)/total).toFixed(2)),
      parseFloat(((FAIL*100)/total).toFixed(2))
    ];
    //console.log(series)
    this.setState({
      series: series
    })
  }
  createScan()
  {
  	  this.setState({isLoading: true});    
  	  this.setState({show: false});    
  	  this.setState({loadingMessage: 'Starting a new scan'});    
  	  var authToken = localStorage.getItem('auth_token');  	 
    	
     fetch(`${url}/create-oci-scan`, {
                  method: "POST",
                  body:JSON.stringify({
                      scanName:this.state.scanName,
                  }),     
                  headers: {
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json', 
                    'Authorization': 'Bearer '+authToken                  
                   }            
               })
               .then((response) => response.json())
               .then((json) => {
                  if(json.success){
                      this.setState({tableData: json.AllociScan});  
                      //toastr.success(json.message,'Success');
                      this.setState({isLoading: false})
                  }else{
                      //toastr.error(json.message,'Error');
                      this.setState({isLoading: false})
                  }
               })
               .catch((error) => console.error('ss'+error))
               .finally(() => this.setState({isLoading: false})); 
  }
  downloadpdf() {
    var userId = localStorage.getItem('userId'); 
    let scanId  = this.state.currentScanId;
    window.open(`${Downloadurl}/pdf-oci-download?scan_id=${scanId}&user_id=${userId}`,'_blank')
  }
  toggle = () => {
    var {show} = this.state
    this.setState({show:!show})
  }

  render() 
  {

    const data = {
      columns: [
        {
          label: "Scan ID",
          field: "id",
          sort: "asc",
          width: 30
        },
        {
          label: "Started At",
          field: "start_datetime",
          sort: "asc",
          width: 270
        },
        {
          label: "Completed At",
          field: "end_datetime",
          sort: "asc",
          width: 200
        },
        {
          label: "Scan Status",
          field: "status",
          sort: "asc",
          width: 100
        },
        {
          label: "Threats Found",
          field: "threats_found",
          sort: "asc",
          width: 150
        },
        {
          label: "Actions",
          field: "action",
          sort: "asc",
          width: 100
        }
      ],
      columns1: [        
        {
          label: "Scan Name",
          field: "scan_name",
          sort: "asc",
          width: 30
        },
        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 30
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 30
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 30
        },
        {
          label: "Region",
          field: "region",
          sort: "asc",
          width: 30
        },        
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 30
        },        
        {
          label: "Resource",
          field: "resource",
          sort: "asc",
          width: 30
        },        
      ],
      rows: [

      ]
    };

    var theData = {};
    theData.columns = data.columns;
    
    var theRows = this.state.tableData;
    if(theRows){
      for(var i=0;i<this.state.tableData.length;i++){
        
        if(theRows[i].status == "Pending"){
        theRows[i].action = <MDBBtn color="purple"  size="sm">CANCEL</MDBBtn>
        }
        
        if(theRows[i].status == "Completed")
        {
          let id = theRows[i].id
          theRows[i].action = <MDBBtn color="purple" 
                                    onClick={(theRows) => this.showTheResult(id)} 
                                    size="sm">VIEW REPORT</MDBBtn>
        }
        
        if(theRows[i].status == "Cancelled"){
        theRows[i].action = <MDBBtn color="purple" size="sm">RESUME</MDBBtn>
        }

      }    
    }
    theData.rows = theRows;
    //console.log(theData);
    var theResult = {};
    if(this.state.showResultModal){
        theResult.columns = data.columns1;
        theResult.rows = this.state.currentlyShowingScan;
        //console.log(theResult)
    }
    
    
    
    
    return (
      <React.Fragment>
      <Helmet><title>Oracle Cloud Scans</title></Helmet>
      {this.state.isLoading &&
         <div>
          <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
          <Loader
             type="Bars"
             color="#00BFFF"
             height={100}
             width={'100%'}   
             timeout={5000} //3 secs
          />  
         </div>     
      }
      
      {!this.state.isLoading &&
      
        <div className="page-content" >
        <Container fluid >
        <Breadcrumbs title="Oracle Cloud Scans" breadcrumbItems={this.state.breadcrumbItems} />
            {this.state.showResultModal &&                         
                <Row>
                    <Col xs={12}>  
                          <Button
                            color="success"
                            onClick={this.downloadpdf}
                            className="waves-effect waves-light mr-1 pull-right"
                            style={{float:'right'}}
                        >
                            Export to PDF
                        </Button> 

                        <Button
                            color="primary"
                            onClick={this.goBack}
                            className="waves-effect waves-light mr-1 pull-right"
                            style={{float:'right'}}
                        >
                            Back to All Scans
                        </Button> 
                        
                    </Col>
                    <Col xs={12}>  
                        <h5>Scan #{this.state.currentScanId}</h5>
                        <Card>
                            <CardBody>                                      
                                <div id="donut-chart" className="apex-charts">
                                  <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="230" />
                                </div>
                                <Row>
                                    <Col xs={4}>
                                        <div className="text-center mt-4">
                                            <p className="mb-2 text-truncate"><i className="mdi mdi-circle font-size-10 mr-1" style={{color:'#0EC905'}}></i> OK </p>
                                            <h5>{this.state.series && this.state.series[0]}%</h5>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="text-center mt-4">
                                            <p className="mb-2 text-truncate"><i className="mdi mdi-circle font-size-10 mr-1" style={{color:'#FFC300'}}></i> UNKNOWN</p>
                                            <h5>{this.state.series && this.state.series[1]}%</h5>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="text-center mt-4">
                                            <p className="mb-2 text-truncate"><i className="mdi mdi-circle font-size-10 mr-1" style={{color:'#CA0B00'}}></i> FAILED</p>
                                            <h5>{this.state.series && this.state.series[2]}%</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <MDBDataTable responsive bordered data={theResult} />
                    </Col>
                </Row> 
            }
                        
         {!this.state.showResultModal &&  
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                     <h3 className="card-title">View and Manage Your Oracle Cloud Scan History</h3>
                     <hr/>
                     
                     <Button
                         color="primary"
                         onClick={()=> this.setState({show:true})}
                         className="waves-effect waves-light mr-1 pull-right start-new-scan"
                         style={{float:'right'}}
                     >
                         Start a New Scan
                     </Button> 

                    <MDBDataTable responsive bordered data={theData} />

                  </CardBody>
                </Card>
              </Col>
            </Row> 
         } 
        <Modal isOpen={this.state.show} toggle={this.toggle} backdrop={false}>
          <ModalHeader toggle={this.toggle}>Set Oracle Cloud Scan Name</ModalHeader>
          <AvForm className="form-horizontal" onValidSubmit={this.createScan}>
          <ModalBody>
              <Row>
                <Col xs={12}>
                  <AvField name="ScanName" value={this.state.scanName} onChange={(event)=>this.setState({scanName: event.target.value})} type="text" className="form-control" validate={{required: true}} placeholder="Enter Scan Name"/>
                </Col>
              </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Start Scan</Button>
          </ModalFooter>
          </AvForm>
        </Modal>
            
          </Container>
        </div>
       }
      </React.Fragment>
    );
  }
}

export default OracleCloudScans;

