import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Row, Col, Card, Modal, Container, ModalHeader, ModalBody, ModalFooter, CardBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../Tables/datatables.scss";

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from "react-loader-spinner";
//Import Charts
import ReactApexChart from 'react-apexcharts';
import {url,Downloadurl} from '../../services/common';
import "../Dashboard/dashboard.scss";
import toastr from 'toastr'
import {Helmet} from "react-helmet";

class AllScans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "Dashboard", link : "/dashboard" },
        { title : "Docker Scans", link : "#" },
    ],
    series: [42.0, 26.0, 15.0],
    options : {
        labels: ["OK", "UNKNOWN", "FAILED"],
        plotOptions: {
            pie: {
                donut: {
                    size: '75%'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
        },
        colors: ['#0EC905', '#FFC300', '#CA0B00'],
    
    },
    imageId:this.props.match.params.id,
    tableData:[
      {
        id:1,
        imageName:'docker Image one',
        status:'pending',
        addedon:'10/12/2021',
        total_value:0,
      },
      {
        id:2,
        imageName:'docker Image two',
        status:'completed',
        addedon:'10/12/2021',
        total_value:42,
      }
    ],
    authToken:localStorage.getItem('auth_token'),
    isLoading:false,
    loadingMessage:'',
    showResultModal:false,
    currentlyShowingScan:{},
    currentScanId:null,
    currentScanName:'',
    show:false,
    Editshow:false,
    scanName:'',
    scanType:this.props.match.params.type,
    };
    
    this.setTheTableData = this.setTheTableData.bind(this); 
    this.showTheResult = this.showTheResult.bind(this); 
    this.goBack = this.goBack.bind(this);  
    this.createScan = this.createScan.bind(this);        
    this.setPieData = this.setPieData.bind(this);        
    this.downloadpdf = this.downloadpdf.bind(this);        

  }
  
  child   = { width: `30em`, height: `100%`}
  
  ref = React.createRef();
  
  options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4,2]
  };

  componentDidMount()
  {
      this.getImageScans(this.state.imageId)
  }

  getImageScans = (id) => {
    this.setState({isLoading: true});    
    this.setState({loadingMessage: 'Loading Scans'});    
    fetch(`${url}/dockers-images/scan/get?id=${id}`, {
        method: "GET",     
        headers: {
          'Accept': 'application/json',  
          'Authorization': 'Bearer '+this.state.authToken                  
        }            
    })
    .then((response) => response.json())
    .then((json) => this.setState({tableData: json.scans}))
    .catch((error) => console.error('ss'+error))
    .finally(() => this.setState({isLoading: false})); 
}

  getImagesScans = (id) => {
      this.setState({isLoading: true});    
      this.setState({loadingMessage: 'Loading Scans'});    
      fetch(`${url}/kubernetes-clusters/scan/get?id=${id}`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => this.setState({tableData: json.scans}))
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
  }
  setTheTableData(json){  	 
        this.setState({tableData: json.awsScans});  
  }
  goBack(json){  	 
  	   this.setState({showResultModal: false});    
  }

  showTheResult(ScanId, scanName)
  {  	 
      this.setState({isLoading: true})
      this.setState({loadingMessage: 'Loading ...'});    
      let authToken = localStorage.getItem('auth_token');  	 
      fetch(`${url}/get-aws/threats/${ScanId}`, {
                  method: "GET",     
                  headers: {
                    'Accept': 'application/json',  
                    'Authorization': 'Bearer '+authToken                  
                  }            
              })
              .then((response) => response.json())
              .then((json) => {
                let {currentlyShowingScan,showResultModal,currentScanId, currentScanName}  = this.state;
                if(json.success)
                {
                    currentlyShowingScan  = json.data
                    currentScanId  = ScanId
                    currentScanName  = scanName
                    this.setPieData(currentlyShowingScan);
                    this.setState({currentlyShowingScan,showResultModal: true,currentScanId, currentScanName},()=>{
                      //console.log(currentlyShowingScan);
                      //console.log(showResultModal);
                    });   
                }else{
                    this.setState({isLoading: false})
                }
              })
              .catch((error) => console.error('ss'+error))
              .finally(() => this.setState({isLoading: false})); 
  }
  
  setPieData(pieData){
  	
    let ok  = 0;
    let UNKNOWN = 0;
    let FAIL = 0;
    let total = pieData.length;
    var {series} = this.state
    for(var i=0;i<pieData.length;i++)
    {
      if(pieData[i].status === "OK"){
        ok = ok + 1;
      }else if(pieData[i].status === "UNKNOWN"){
        UNKNOWN = UNKNOWN + 1;
      }else if(pieData[i].status === "FAIL"){
        FAIL = FAIL + 1;
      }
    }  
    series = [
      parseFloat(((ok*100)/total).toFixed(2)),
      parseFloat(((UNKNOWN*100)/total).toFixed(2)),
      parseFloat(((FAIL*100)/total).toFixed(2))
    ];
   
    this.setState({
      series: series
    })
  }
  createScan(e,value)
  {
    this.setState({isLoading: true});    
    this.setState({loadingMessage: 'Creating Scan'});   
    value.docker_Image_id = this.state.imageId
    fetch(`${url}/dockers-images/scan/create`, {
        method: "POST",
        body:JSON.stringify(value),     
        headers: {
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+this.state.authToken                  
          }            
      })
      .then((response) => response.json())
      .then((json) => {
        if(json.success){
            this.setState({tableData: json.scans})
            //toastr.success(json.message,'Success');
        }else{
            //toastr.error(json.message,'Error');
        }
      })
      .catch((error) => console.error('ss'+error))
      .finally(() => {
        this.setState({isLoading: false})
        this.setState({show: false});    
      }); 
  }
  downloadpdf() {
    var userId = localStorage.getItem('userId'); 
    let scanId  = this.state.currentScanId;
    window.open(`${Downloadurl}/pdf-aws-download?scan_id=${scanId}&user_id=${userId}`,'_blank')
  }
  toggle = () => {
    var {show} = this.state
    this.setState({show:!show})
  }

  StartScan = (id) => {
    
    this.setState({isLoading: true});
    this.setState({loadingMessage: 'Performing the scan'});   
    setTimeout(()=>{
      //toastr.success('Scan completed successfully','Success');
      this.setState({isLoading: false});
    },2000)
  }

  EditScan = (id) => {
    
    this.setState({Editshow:true})
  }

  render() 
  {

    const data = {
      columns: [
        {
          label: "Scan ID",
          field: "id",
          sort: "asc",
          width: 30
        },
        {
          label: "Scan Name",
          field: "scan_name",
          sort: "asc",
          width: 30
        },
        {
          label: "Added On",
          field: "added_on",
          sort: "asc",
          width: 30
        },
        {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150
        },
        {
          label: "Actions",
          field: "action",
          sort: "asc",
          width: 100
        }
      ],        
      rows: [

      ]
    };

    var theData = {};
    theData.columns = data.columns;
    
    var theRows = this.state.tableData;
    
    if(theRows){
      for(var i=0;i<this.state.tableData.length;i++){
        let scanId = theRows[i].id
        theRows[i].action = <div>
                              <Button color="primary" size="sm"
                                onClick={() => this.StartScan(scanId)}
                              >Run Scan</Button>{' '}
                              <Button color="primary" size="sm"
                                onClick={()=>this.props.history.push(`/docker/image/scan-result/${scanId}`)}
                              >View Scan History</Button>{' '}
                            </div>
      }    
    }
    
    theData.rows = theRows;
    
    var theResult = {};
    if(this.state.showResultModal){
        theResult.columns = data.columns1;
        theResult.rows = this.state.currentlyShowingScan;
    }
    
    return (
      <React.Fragment>
      <Helmet><title>All Docker Scans</title></Helmet>
      {this.state.isLoading &&
         <div>
          <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
          <Loader
             type="Bars"
             color="#00BFFF"
             height={100}
             width={'100%'}   
             timeout={5000} //3 secs
          />  
         </div>     
      }
      
      {!this.state.isLoading &&
      
        <div className="page-content" >
        <Container fluid >
        <Breadcrumbs title={`Docker Scans`} breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                     <h4 className="card-title">View and manage your docker scans
                      <Button
                          color="primary"
                          onClick={()=> this.props.history.push('/docker/all-images')}
                          className=" waves-effect waves-light mr-1 pull-right"
                          style={{float:'right'}}
                          size="sm"
                      >
                          All Images
                      </Button> {' '}
                       <Button
                          color="primary"
                          onClick={()=> this.setState({show:true})}
                          className=" waves-effect waves-light mr-1 pull-right"
                          style={{float:'right'}}
                          size="sm"
                      >
                          Add New Scan
                      </Button> 
                     </h4>
                    <MDBDataTable responsive bordered data={theData} />
                  </CardBody>
                </Card>
              </Col>
            </Row> 
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop={false}>
              <ModalHeader toggle={this.toggle}>Add Scan</ModalHeader>
              <AvForm className="form-horizontal" onValidSubmit={this.createScan}>
              <ModalBody>
                  <Row>
                    <Col xs={12}>
                      <AvField name="scan_name" 
                              type="text" className="form-control" 
                              validate={{required: true}} 
                              placeholder="Enter Scan Name"/>
                    </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit">Add Scan</Button>
              </ModalFooter>
              </AvForm>
            </Modal>
          </Container>
        </div>
       }
      </React.Fragment>
    );
  }
}

export default AllScans;
