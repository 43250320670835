import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import {url} from '../../services/common';
import Loader from "react-loader-spinner";

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{
                labels: [],
                datasets: [
                    {
                        label:"FAILED",
                        backgroundColor: "#E3071D",
                        borderColor: "#E3071D",
                        borderWidth: 0,                        
                        data: []
                    },
                    {
                        label:"UNKNOWN",
                        backgroundColor: "#FFBE00",
                        borderColor: "#FFBE00",
                        borderWidth: 0,                        
                        data: []
                    },
                    {
                        label:"OK",
                        backgroundColor: "#75AD6F",
                        borderColor: "#75AD6F",
                        borderWidth: 0,                       
                        data: []
                    },{
                        label:"WARN",
                        backgroundColor: "#FF7E01",
                        borderColor: "#FF7E01",
                        borderWidth: 0,                       
                        data: []
                    },
                    
                ]
            },
            option: {
                scales: {
                    xAxes: [{
                        barPercentage: 0.7,
                        ticks: {
                            fontColor: 'white', // Set x-axis label color to white
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: 'white', // Set y-axis label color to white
                        },
                    }],
                }
            },
            Xaxis:['Weekly','Monthly','Yearly'],
            scanType:'all',
            scanRange:'Yearly',
            isLoading:false,
            loadingMessage:'',
        }
           
    }

    componentDidMount(){
        this.ChangeScanType(this.state.scanType,this.state.scanRange)
    }

    ChangeScanType = (type,value) =>{

        let scanResult = this.state.scanType;
        let scanRange = this.state.scanRange;
        if(type === 'scanType'){
            scanResult = value;
            this.setState({scanType:value})
        }
        else if(type === 'scanRange'){
            scanRange   = value;
            this.setState({scanRange:value})
        }
        var authToken = localStorage.getItem('auth_token');  
        var newurl   = `${url}/threats-discovery-trends?scanResult=${scanResult}&scanRange=${scanRange}`;
        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Loading...'});
        fetch(newurl, {
            method: "GET",  
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {
            let {data}                        =   this.state;
            data.labels                      =   json.barChart.dates;
            data.datasets[0].data            =   json.barChart.threats;
            data.datasets[1].data            =   json.barChart.unknowns;
            data.datasets[2].data            =   json.barChart.oks;
            data.datasets[3].data            =   json.barChart.warns;
            this.setState({data,scanType:scanResult});
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }

    render() {
        return (
            <React.Fragment>
                    <Card>
                        <CardBody>
                            <div className="float-right">
                                <ButtonGroup className="mb-2">
                                    <select name="scantype" onChange={(e) => this.ChangeScanType('scanType',e.target.value)} className="custom-select custom-select-sm">
                                        <option value="all" selected={this.state.scanType === 'all' ? true : false}>All</option>
                                        <option value="aws" selected={this.state.scanType === 'aws' ? true : false}>AWS</option>
                                        <option value="gcp" selected={this.state.scanType === 'gcp' ? true : false}>GCP</option>
                                        <option value="azure" selected={this.state.scanType === 'azure' ? true : false}>AZURE</option>
                                        <option value="oci" selected={this.state.scanType === 'oci' ? true : false}>OCI</option>
                                    </select>
                                    {
                                        this.state.Xaxis.map((item,index)=>{
                                            return <Button key={index} size="sm" color="light" type="button" onClick={() => this.ChangeScanType('scanRange',item)}  className={this.state.scanRange === item ? 'active' : ''}>{item}</Button>
                                        })
                                    }
                                </ButtonGroup>
                            </div>
                            <h4 className="card-title mb-4">Threats Discovery Trends</h4>
                            {
                                this.state.isLoading &&
                                <div>
                                <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
                                <Loader
                                    type="Bars"
                                    color="#00BFFF"
                                    height={100}
                                    width={'100%'}   
                                />  
                                </div> 
                            }
                            {
                                !this.state.isLoading &&
                                <div>
                                    <Bar width={474} height={300} data={this.state.data} options={this.state.option} />                                    
                                </div>
                            }
                        </CardBody>
                    </Card>
            </React.Fragment>
        );
    }
}

export default BarChart;   