import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {url} from '../../services/common';
import toastr from 'toastr'
import {Helmet} from "react-helmet";

class AddUsers extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
        breadcrumbItems : [
            { title : "Users", link : "/all-users" },
            { title : "Add User", link : "#" },
        ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {

    fetch(`${url}/register`, {
        method: "POST",        
        body:JSON.stringify({
            email: values.email,
            password: values.password,
            firstname: values.firstname,
            lastname: values.lastname,
            companyname: values.companyname,
            username: values.username,
        }),       
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },

     })
     .then((response) => response.json())
     .then((json) => {
        if(json.success){
            //toastr.success(json.message,'Success');
            this.props.history.push('/all-users')
        }else{
            //toastr.error(json.message,'Error');
            if(json.error){
                Object.keys(json.error).map((itme,index)=>{
                    json.error[itme].map((item2,index2)=>{
                        //toastr.error(item2,'Error');
                    })
                })
            }
        }
     })
     .catch((error) => console.error('ss'+error))
     .finally(() => this.setState({isLoading: false}));  
}


  render() {
    return (
      <React.Fragment>
        <Helmet><title>Add User</title></Helmet>
        <div className="page-content">
          <Container fluid={true}>
                <Breadcrumbs title="Add User" breadcrumbItems={this.state.breadcrumbItems} />
                <Row>
                    <Col md={{ size: 8, offset: 2 }} lg={8}>
                        <Card>
                        <CardBody>
                            <p className="card-title-desc">
                        </p>
                            <AvForm onValidSubmit={this.handleSubmit} className="form-horizontal" >
                                <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="firstName" style={{color:'white'}}>First Name</Label>
                                    <AvField name="firstname" validate={{required: true}} type="text" className="form-control" id="firstName" placeholder="Enter First Name"/>
                                </FormGroup>
                                
                                <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="lastname" style={{color:'white'}}>Last Name</Label>
                                    <AvField name="lastname" validate={{required: true}} type="text" className="form-control" id="lastname" placeholder="Enter Last Name"/>
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="companyname" style={{color:'white'}}>Company Name</Label>
                                    <AvField name="companyname" validate={{required: true}} type="text" 
                                      className="form-control" id="companyname" placeholder="Enter Company Name"/>
                                </FormGroup>
                                
                                <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-mail-line auti-custom-input-icon"></i>
                                    <Label htmlFor="email" style={{color:'white'}}>Email</Label>
                                    <AvField name="email" validate={{email: true, required: true}} type="email" className="form-control" id="email" placeholder="Enter Email"/>
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="username" style={{color:'white'}}>Username</Label>
                                    <AvField name="username" validate={{required: true}} type="text" className="form-control" id="username" placeholder="Enter username"/>
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="password" style={{color:'white'}}>Password</Label>
                                    <AvField name="password" validate={{required: true}} type="password" className="form-control" id="password" placeholder="Enter password"/>
                                </FormGroup>
                                
                                <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="userpassword" style={{color:'white'}}>Confirm Password</Label>
                                    <AvField name="userpassword"  value="" validate={{
                                        required: { value: true },
                                        match: { value: "password" }
                                        }} type="password" className="form-control" id="userpassword" placeholder="Confirm Password"/>
                                </FormGroup>


                                <FormGroup className="mb-0">
                                    <Col sm={{ size:2, offset:5 }}>
                                        <Button type="submit" color="primary" className="mr-1">
                                            Submit
                                        </Button>
                                    </Col>
                                </FormGroup>
                            </AvForm>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default AddUsers;
