import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col, Card, CardBody, FormGroup, Button, Label,Container} from "reactstrap";
import { AvForm, AvField,AvCheckboxGroup,AvCheckbox } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {url} from '../../services/common';
import toastr from 'toastr'
import classnames from 'classnames';
import {Helmet} from "react-helmet";

class EditUsers extends Component {

  constructor(props) {

    super(props);

    this.state = {
        breadcrumbItems : [
            { title : "Users", link : "/all-users" },
            { title : "Edit User", link : "#" },
        ],
        id:this.props.match.params.id,
        user:[],
        permissions:null,
        userPermission:[],
        activeTab:'1',
        showSecurityAnalyticsTab:false,
        showIntrusionDetectionTab:false,
        showFileIntegrityMonitoringTab:false,
        showVulnerabilityDetectionTab:false,
        showConfigurationAssessmentTab:false,
        showIncidentResponseTab:false,
        showCloudSecurityMonitoringTab:false,
        showRegulatoryComplianceTab:false,   
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount(){

    var authToken = localStorage.getItem('auth_token'); 
	fetch(`${url}/edit-user/${this.state.id}`, {
		method: "GET",     
		headers: {
		  'Accept': 'application/json',  
		  'Authorization': 'Bearer '+authToken                  
		 }            
	 })
	 .then((response) => response.json())
	 .then((json) => {
		let {user,permissions,userPermission} =  this.state;
		user =  json.users
		permissions =  json.permissions;

        user.has_perms.map((item,value)=>{
            userPermission.push(item.permissions_id);
        })

        if(user.can_access_security_analytics == 1){
            this.setState({showSecurityAnalyticsTab: true})
        }

        if(user.can_access_intrusion_detection == 1){
            this.setState({showIntrusionDetectionTab: true})
        }

        if(user.can_access_file_integrity_monitoring == 1){
            this.setState({showFileIntegrityMonitoringTab: true})
        }

        if(user.can_access_vulnerability_detection == 1){
            this.setState({showVulnerabilityDetectionTab: true})
        }

        if(user.can_access_configuration_assessment == 1){
            this.setState({showConfigurationAssessmentTab: true})
        }

        if(user.can_access_incident_response == 1){
            this.setState({showIncidentResponseTab: true})
        }

        if(user.can_access_cloud_security_monitoring == 1){
            this.setState({showCloudSecurityMonitoringTab: true})
        }

        if(user.can_access_regulatory_compliance == 1){
            this.setState({showRegulatoryComplianceTab: true})
        }

		this.setState({user,permissions,userPermission})
	 })
	 .catch((error) => console.error('ss'+error))
	 .finally(() => this.setState({isLoading: false})); 

  }


  handleClick(event) {    

   
    if(event.target.value == 'security_analytics'){        

        if(event.target.checked == true){
            this.setState({showSecurityAnalyticsTab: true})
            //console.log('true')
        }else{
            this.setState({showSecurityAnalyticsTab: false})
            //console.log('false')
        }
        
    }

    if(event.target.value == 'intrusion_detection'){

        if(event.target.checked == true){
            this.setState({showIntrusionDetectionTab: true})
        }else{
            this.setState({showIntrusionDetectionTab: false})
        }
        
    }

    if(event.target.value == 'file_integrity_monitoring'){

        if(event.target.checked == true){
            this.setState({showFileIntegrityMonitoringTab: true})
        }else{
            this.setState({showFileIntegrityMonitoringTab: false})
        }
        
    }

    if(event.target.value == 'vulnerability_detection'){

        if(event.target.checked == true){
            this.setState({showVulnerabilityDetectionTab: true})
        }else{
            this.setState({showVulnerabilityDetectionTab: false})
        }
        
    }

    if(event.target.value == 'configuration_assessment'){

        if(event.target.checked == true){
            this.setState({showConfigurationAssessmentTab: true})
        }else{
            this.setState({showConfigurationAssessmentTab: false})
        }
        
    }

    if(event.target.value == 'incident_response'){

        if(event.target.checked == true){
            this.setState({showIncidentResponseTab: true})
        }else{
            this.setState({showIncidentResponseTab: false})
        }
        
    }

    if(event.target.value == 'cloud_security_monitoring'){

        if(event.target.checked == true){
            this.setState({showCloudSecurityMonitoringTab: true})
        }else{
            this.setState({showCloudSecurityMonitoringTab: false})
        }
        
    }

    if(event.target.value == 'regulatory_compliance'){

        if(event.target.checked == true){
            this.setState({showRegulatoryComplianceTab: true})
        }else{
            this.setState({showRegulatoryComplianceTab: false})
        }
        
    }


  }
   


  handleSubmit(event, values) {

    var authToken = localStorage.getItem('auth_token'); 

    fetch(`${url}/update-user`, {
        method: "POST",        
        body:JSON.stringify({
            id:this.state.id,
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            companyname: values.companyname,
            username: values.username,
            permissions : values.permissions,
            anomaly_permissions : values.anomaly_permissions,
            showSecurityAnalyticsTab:this.state.showSecurityAnalyticsTab,
            showIntrusionDetectionTab:this.state.showIntrusionDetectionTab,
            showFileIntegrityMonitoringTab:this.state.showFileIntegrityMonitoringTab,
            showVulnerabilityDetectionTab:this.state.showVulnerabilityDetectionTab,
            showConfigurationAssessmentTab:this.state.showConfigurationAssessmentTab,
            showIncidentResponseTab:this.state.showIncidentResponseTab,
            showCloudSecurityMonitoringTab:this.state.showCloudSecurityMonitoringTab,
            showRegulatoryComplianceTab:this.state.showRegulatoryComplianceTab,

        }),       
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+authToken  
        },

     })
     .then((response) => response.json())
     .then((json) => {
        if(json.success){
           // toastr.success(json.message,'Success');
            this.props.history.push('/all-users')
        }else{
            toastr.error(json.message,'Error');
            if(json.error){
                Object.keys(json.error).map((itme,index)=>{
                    json.error[itme].map((item2,index2)=>{
                        //toastr.error(item2,'Error');
                    })
                })
            }
        }
     })
     .catch((error) => console.error('ss'+error))
     .finally(() => this.setState({isLoading: false}));  
  }

  changePassword(event, values) 
  {
    var authToken = localStorage.getItem('auth_token'); 
    fetch(`${url}/update-user-password`, {
        method: "POST",        
        body:JSON.stringify({
            id:this.state.id,
            changedpassword: values.changedpassword,
        }),       
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+authToken  
        },
     })
     .then((response) => response.json())
     .then((json) => {
        if(json.success){
           // toastr.success(json.message,'Success');
            this.props.history.push('/all-users')
        }else{
            //toastr.error(json.message,'Error');
        }
     })
     .catch((error) => console.error('ss'+error))
     .finally(() => this.setState({isLoading: false}));  
  }

  toggle = tab => {
    let {activeTab}   =   this.state;
    if(activeTab !== tab)
         this.setState({activeTab:tab})
  }

  render() {
    var defaultValues = {
        permissions: this.state.userPermission,
      };

    return (
      <React.Fragment>
        <Helmet><title>Edit User</title></Helmet>
        <Container fluid={true}>
            <div className="page-content">
                <Breadcrumbs title="Edit User" breadcrumbItems={this.state.breadcrumbItems} />
                <Nav tabs>
                    <Row style={{width:"100%"}}>
                        <Col xs="6" style={{padding:0,textAlign:"center"}}>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    Info
                                </NavLink>
                            </NavItem>
                        </Col>
                        <Col xs="6" style={{padding:0,textAlign:"center"}}>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => {  this.toggle('2'); }}
                                >
                                    Password
                                </NavLink>
                            </NavItem>
                        </Col>
                    </Row>
                </Nav>
                <TabContent activeTab={this.state.activeTab} style={{marginTop:"15px"}}>
                    <TabPane tabId="1">
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} lg={8}>
                                <Card>
                                    <CardBody>
                                        <p className="card-title-desc"></p>
                                        <AvForm onValidSubmit={this.handleSubmit} 
                                                model={defaultValues} 
                                                className="form-horizontal" >
                                            <FormGroup className="auth-form-group-custom mb-4">
                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="firstName" style={{color:'white'}}>First Name</Label>
                                                <AvField name="firstname" value={this.state.user && this.state.user.firstname} validate={{required: true}} type="text" className="form-control" id="firstName" placeholder="Enter First Name"/>
                                            </FormGroup>
                                            
                                            <FormGroup className="auth-form-group-custom mb-4">
                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="lastname" style={{color:'white'}}>Last Name</Label>
                                                <AvField name="lastname" value={this.state.user && this.state.user.lastname} validate={{required: true}} type="text" className="form-control" id="lastname" placeholder="Enter Last Name"/>
                                            </FormGroup>

                                            <FormGroup className="auth-form-group-custom mb-4">
                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="companyname" style={{color:'white'}}>Company Name</Label>
                                                <AvField name="companyname" value={this.state.user && this.state.user.company_name} 
                                                   validate={{required: true}} type="text" className="form-control" id="lastname" placeholder="Enter Company Name"/>
                                            </FormGroup>
                                            
                                            <FormGroup className="auth-form-group-custom mb-4">
                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                <Label htmlFor="email" style={{color:'white'}}>Email</Label>
                                                <AvField name="email" value={this.state.user && this.state.user.email} validate={{email: true, required: true}} readOnly={true} type="email" className="form-control" id="email" placeholder="Enter Email"/>
                                            </FormGroup>

                                            <FormGroup className="auth-form-group-custom mb-4">
                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="username" style={{color:'white'}}>Username</Label>
                                                <AvField name="username" value={this.state.user && this.state.user.username} validate={{required: true}} type="text" className="form-control" id="username" placeholder="Enter username"/>
                                            </FormGroup>


                                            <AvCheckboxGroup inline label="Cloud Scan Permissions" name="permissions">
                                                {
                                                    this.state.permissions ?
                                                        this.state.permissions.map((item,index)=>{
                                                            return <AvCheckbox key={index} label={item.value} value={item.id} />  
                                                        })
                                                    : null
                                                }
                                            </AvCheckboxGroup>


                                            <AvCheckboxGroup inline 
                                                             label="Anomaly Detection Permissions" 
                                                             name="anomaly_permissions" 
                                                             style={{display:'none'}}>
                                             
                                               <AvCheckbox value="security_analytics" label="Security Analytics" onClick={this.handleClick} checked={this.state.showSecurityAnalyticsTab}/>  
                                               <AvCheckbox value="intrusion_detection" label="Intrusion Detection" onClick={this.handleClick} checked={this.state.showIntrusionDetectionTab}/> 
                                               <AvCheckbox value="file_integrity_monitoring" label="File Integrity Monitoring" onClick={this.handleClick} checked={this.state.showFileIntegrityMonitoringTab}/> 
                                               <AvCheckbox value="vulnerability_detection" label="Vulnerability Detection" onClick={this.handleClick} checked={this.state.showVulnerabilityDetectionTab}/> 
                                               <AvCheckbox value="configuration_assessment" label="Configuration Assessment" onClick={this.handleClick} checked={this.state.showConfigurationAssessmentTab}/> 
                                               <AvCheckbox value="incident_response" label="Incident Response" onClick={this.handleClick} checked={this.state.showIncidentResponseTab}/> 
                                               <AvCheckbox value="cloud_security_monitoring" label="Cloud Security Monitoring" onClick={this.handleClick} checked={this.state.showCloudSecurityMonitoringTab}/> 
                                               <AvCheckbox value="regulatory_compliance" label="Regulatory Compliance" onClick={this.handleClick} checked={this.state.showRegulatoryComplianceTab}/> 
                                                        
                                            </AvCheckboxGroup>


                                            <FormGroup className="mb-0">
                                                <Col sm={{ size:2, offset:5 }}>
                                                    <Button type="submit" color="primary" className="mr-1">
                                                        Update
                                                    </Button>
                                                </Col>
                                            </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2" style={{marginTop:"15px"}}>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }} lg={8}>
                                <Card>
                                    <CardBody>
                                        <p className="card-title-desc"></p>
                                        <AvForm onValidSubmit={this.changePassword} className="form-horizontal" >
                                            <FormGroup className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                <Label htmlFor="changedpassword">Enter New Password</Label>
                                                <AvField name="changedpassword" validate={{required: true}} type="password" className="form-control" id="changedpassword" />
                                            </FormGroup>
                                             
                                            <FormGroup className="mb-0" style={{textAlign:"center"}}>
                                                <Button type="submit" color="primary" className="mr-1">
                                                    Update Password
                                                </Button>
                                            </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
          </Container>
      </React.Fragment>
    );
  }
}

export default EditUsers;
