import React, { Component } from "react";
import {Label, Modal, ModalHeader, ModalBody, ModalFooter,Button,Container,TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader,Carousel, CarouselItem, CarouselControl } from "reactstrap";
//Import Breadrumb
import Select from 'react-select';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'
import classnames from "classnames";
import SecurityAnalytics from './SecurityAnalytics'
import IntrusionDetection from './IntrusionDetection'
import LogDataAnalysis from './LogDataAnalysis'
import FileIntegrityMonitoring from './FileIntegrityMonitoring'
import VulnerabilityDetection from './VulnerabilityDetection'
import ConfigurationAssessment from './ConfigurationAssessment'
import IncidentResponse from './IncidentResponse'
import RegulatoryCompliance from './RegulatoryCompliance'
import CloudSecurityMonitoring from './CloudSecurityMonitoring'
import ContainersSecurity from './ContainersSecurity'
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import './anomaly.css'
import {url} from '../../services/common';
import toastr from 'toastr'

class AnomalyDetection extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Anomaly Detection" , link : "#" },
            ],
            options : {
                labels: ['Critical', 'Low', 'Medium', 'High', 'Info'],
                colors: ["#34c38f", "#556ee6","#f46a6a", "#50a5f1", "#f1b44c"],
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    floating: false,
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: -10
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240
                        },
                        legend: {
                            show: false
                        },
                    }
                }]
              
              },
            series: [44, 55, 41, 17, 15],
            authToken:localStorage.getItem('auth_token'),
            agentShow:false,
            agentserviceShow:false,
            activeTab: 1,
            os:null,
            arch:'none',
            activeTabComponet: null,
            activeIndex:0,
            agents:[],
            infrastructures:[],
            showSecurityAnalyticsTab:false,
            showIntrusionDetectionTab:false,
            showFileIntegrityMonitoringTab:false,
            showVulnerabilityDetectionTab:false,
            showConfigurationAssessmentTab:false,
            showIncidentResponseTab:false,
            showCloudSecurityMonitoringTab:false,
            showRegulatoryComplianceTab:false,   
        }
    }

    // 1. Malware Detection
    // 2. Security Events
    // 3. Cloud Security Monitoring
    // 4. Incident Response
    // 5. Security Configuration Assessment
    // 6. File Integrity Monitoring
    // 7. Vulnerability Detection

    //linkArray = [];


   

   /* linkArray = [
        {id:1,name:'Security Analytics',component:<SecurityAnalytics heading="Security Analytics" />,icon:''},
        {id:2,name:'Intrusion Detection',component:<IntrusionDetection heading="Intrusion Detection" />,icon:''},
        {id:4,name:'File Integrity Monitoring',component:<FileIntegrityMonitoring heading="File Integrity Monitoring" />,icon:''},
        {id:5,name:'Vulnerability Detection',component:<VulnerabilityDetection heading="Vulnerability Detection" />,icon:''},
        {id:6,name:'Configuration Assessment',component:<ConfigurationAssessment heading="Configuration Assessment" />,icon:''},
        {id:7,name:'Incident Response',component:<IncidentResponse heading="Incident Response" />,icon:''},
        {id:9,name:'Cloud Security Monitoring',component:<CloudSecurityMonitoring heading="Cloud Security Monitoring" />,icon:''},
        {id:8,name:'Regulatory Compliance',component:<RegulatoryCompliance heading="Regulatory Compliance" />,icon:''},        
    ]*/
  
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
       if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                this.props.history.push('/');       	 
        	 }
             if(superAdmin === 'admin'){
                this.setState({user_type:'admin'})
             }   
       }else{
           this.props.history.push('/');    
       }


       this.getTabAccesses();

       this.getAgents();
    }

    toggle1 = (tab,component) => {
        
        if(this.state.agents?.length > 0){
            this.setState({
                activeTab: tab,
                activeTabComponet:component,
            });
        }else{
            this.setState({
                activeTab: tab,
                activeTabComponet:null,
            });
        }
	}

    agentToggale = () => {
        this.setState({agentShow:!this.state.agentShow})
    }


    agentserviceToggale = () => {
        this.setState({agentserviceShow:!this.state.agentserviceShow})
    }


    
    // Check whether this user has accesses to tabs
    getTabAccesses = () => {

        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Loading configuration...'});  

        fetch(`${url}/fetch-anomaly-detection-tabs-accesses`, {
            method: "GET",     
            headers: {
            'Accept': 'application/json',  
            'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            
            if(json.can_access_security_analytics == 1){
                this.setState({showSecurityAnalyticsTab: true})
            }

            if(json.can_access_intrusion_detection == 1){
                this.setState({showIntrusionDetectionTab: true})
            }

            if(json.can_access_file_integrity_monitoring == 1){
                this.setState({showFileIntegrityMonitoringTab: true})
            }

            if(json.can_access_vulnerability_detection == 1){
                this.setState({showVulnerabilityDetectionTab: true})
            }

            if(json.can_access_configuration_assessment == 1){
                this.setState({showConfigurationAssessmentTab: true})
            }

            if(json.can_access_incident_response == 1){
                this.setState({showIncidentResponseTab: true})
            }

            if(json.can_access_cloud_security_monitoring == 1){
                this.setState({showCloudSecurityMonitoringTab: true})
            }

            if(json.can_access_regulatory_compliance == 1){
                this.setState({showRegulatoryComplianceTab: true})
            }

            

            
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }



    getAgents = () => {
        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Loading images'});    
        fetch(`${url}/anomaly/all-agent`, {
            method: "GET",     
            headers: {
            'Accept': 'application/json',  
            'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({agents: json.agents})
            if(this.state.agents.length > 0){
                this.setState({
                    activeTab: 1,
                    activeTabComponet:<SecurityAnalytics heading="Security Analytics" />,
                });
            }else{
                this.setState({
                    activeTab: 1,
                    activeTabComponet:null,
                });
            }
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false})); 
    }

    addagentData = (e,value) => {
        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Adding Agent'});    
        value.architecture = this.state.arch
        fetch(`${url}/anomaly/add-agent`, {
            method: "POST",
            body:JSON.stringify(value),     
            headers: {
                'Accept': 'application/json', 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            if(json.success){
               // toastr.success(json.message,'Success');
                this.getAgents();
            }else{
                //toastr.error(json.message,'Error');
            }
        })
        .catch((error) => toastr.error('Something went wrong','Error'))
        .finally(() => {
            this.setState({isLoading: false});
            this.agentToggale();
        });  
    }



    addagentserviceData = (e,value) => {
        
        this.agentserviceToggale();        
    }

    goToAgentsPage = () => {
        this.props.history.push('/anomaly-detection-agents')    
    }

    render() {

        var linkArray = [];

        if(this.state.showSecurityAnalyticsTab){
            linkArray.push({id:1,name:'Security Analytics',component:<SecurityAnalytics heading="Security Analytics" />,icon:''})
        }
    
        if(this.state.showIntrusionDetectionTab){
            linkArray.push({id:2,name:'Intrusion Detection',component:<IntrusionDetection heading="Intrusion Detection" />,icon:''});
        }
    
        if(this.state.showFileIntegrityMonitoringTab){
    
            linkArray.push({id:4,name:'File Integrity Monitoring',component:<FileIntegrityMonitoring heading="File Integrity Monitoring" />,icon:''});
        }
    
        if(this.state.showVulnerabilityDetectionTab){
            linkArray.push({id:5,name:'Vulnerability Detection',component:<VulnerabilityDetection heading="Vulnerability Detection" />,icon:''});
        }
    
        if(this.state.showConfigurationAssessmentTab){
            linkArray.push({id:6,name:'Configuration Assessment',component:<ConfigurationAssessment heading="Configuration Assessment" />,icon:''});
        }
    
        if(this.state.showIncidentResponseTab){
            linkArray.push({id:7,name:'Incident Response',component:<IncidentResponse heading="Incident Response" />,icon:''});
        }
    
        if(this.state.showCloudSecurityMonitoringTab){
            linkArray.push({id:9,name:'Cloud Security Monitoring',component:<CloudSecurityMonitoring heading="Cloud Security Monitoring" />,icon:''});
        }
    
        if(this.state.showRegulatoryComplianceTab){
    
            linkArray.push({id:8,name:'Regulatory Compliance',component:<RegulatoryCompliance heading="Regulatory Compliance" />,icon:''});        
        }


        const child = { width: `300em`, height: `100%`}
        const RedhatOptions = ["CentOS 5","CentOS 6 or Higher"];
        const DebianOptions = ["i386","x86_64","armhf","aarch64"];
        return (
            <React.Fragment>               
                <Helmet><title>Anomaly Detection</title></Helmet>

                
                <div className="page-content">
                    <Breadcrumbs title="Anomaly Detection" breadcrumbItems={this.state.breadcrumbItems} />
                    <Row className="float-right mr-2">
                                
                                <Button 
                                    color="info" 
                                    size="md"
                                    style={{marginRight:10}}
                                    onClick={()=>this.goToAgentsPage()}
                                >
                                    View Agents
                                </Button>

                                <Button 
                                    color="primary" 
                                    size="md"
                                    onClick={()=>this.agentToggale()}
                                >
                                    Monitor Instances
                                </Button>
                                
                                <Button  
                                    className="ml-2"
                                    color="success" 
                                    size="md"
                                    onClick={()=>this.agentserviceToggale()}
                                >
                                    Monitor Cloud Services
                                </Button>
                            </Row> 

                            
                    <Row className="mb-4 mt-4 float-right mr-2">
                        <Container>
                            <Row>
                                <Nav pills className="navtab-bg nav-left">
                                    {
                                        linkArray.map((item) => {
                                            return <NavItem class='item'>
                                                        <NavLink
                                                            style={{ cursor: "pointer",width:'max-content' }}
                                                            className={classnames({
                                                                active: this.state.activeTab === item.id
                                                            })}
                                                            onClick={() => {
                                                                this.toggle1(item.id,item.component);
                                                            }}
                                                        >
                                                            {item.name}
                                                        </NavLink>
                                                    </NavItem>
                                        })
                                    }
                                </Nav>
                            </Row>   
                        </Container> 
                        <Container>
                            
                            {
                                this.state.activeTabComponet !== null ?
                                this.state.activeTabComponet
                                :<div style={{marginTop:40}}>
                                  {!this.state.isLoading  &&
                                    <p style={{fontSize: '17px',paddingTop: '20px'}}>No Agents Added. Please add an agent and deploy it on the system you want to monitor.</p>
                                  }
                                </div> 
                            }
                        </Container>   
                    </Row>



                    {!this.state.showSecurityAnalyticsTab && 
                     !this.state.showIntrusionDetectionTab && 
                     !this.state.showFileIntegrityMonitoringTab && 
                     !this.state.showVulnerabilityDetectionTab && 
                     !this.state.showConfigurationAssessmentTab && 
                     !this.state.showIncidentResponseTab && 
                     !this.state.showCloudSecurityMonitoringTab && 
                     !this.state.showRegulatoryComplianceTab && !this.state.isLoading  &&


                     <p style={{color:'red'}}> You do not have access to any of the anomaly detection sections. Please contact anomaly-detect@threatspotter.com to get those enabled</p>

                    }
                    

            </div>
                
                <Modal isOpen={this.state.agentShow} toggle={this.agentToggale} backdrop={false}>
                    <ModalHeader toggle={this.agentToggale}>Add Agent</ModalHeader>
                        <ModalBody>
                            <AvForm style={{padding:'10px'}}
                                onValidSubmit={this.addagentData}
                            >
                                <AvGroup>
                                    <Label>Agent's Name</Label>
                                    <AvField type="text" className="form-control" name="agent_name" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>Choose the Operating system</Label>
                                    <AvField type="select" className="form-control" name="os"
                                        onChange={(e)=>{
                                            this.setState({os:e.target.value,arch:'none'})
                                        }}
                                        required
                                    >
                                        <option value="">Select OS</option>
                                        <option value="Red Hat/CentOS">Red Hat/CentOS</option>
                                        <option value="Debian/Ubuntu">Debian/Ubuntu</option>
                                        <option value="Windows">Windows</option>
                                        <option value="Mac OC">Mac OC</option>
                                    </AvField>
                                </AvGroup>

                                <AvGroup>
                                    <Label>Choose the Architecture</Label>
                                    <AvField type="select" className="form-control" name="architecture"
                                         onChange={(e)=>{
                                            this.setState({arch:e.target.value}) 
                                        }}
                                    >
                                        <option value="">Select Architecture</option>
                                        { this.state.os === 'Red Hat/CentOS' && RedhatOptions.map((item,index)=>{
                                            return <option key={index} value={item}>{item}</option>
                                           })
                                        }
                                        { this.state.os === 'Debian/Ubuntu' && DebianOptions.map((item,index)=>{
                                            return <option key={index} value={item}>{item}</option>
                                           })
                                        }
                                    </AvField>
                                </AvGroup>
                                <AvGroup>
                                    <Label>Agent's IP</Label>
                                    <AvField type="text" className="form-control" name="agent_ip" />
                                </AvGroup>
                                <AvGroup style={{float:'right'}}>
                                    <Button color="primary" type="submit">Add Agent</Button>
                                </AvGroup>
                            </AvForm>
                        </ModalBody>
                </Modal>
                <Modal isOpen={this.state.agentserviceShow} toggle={this.agentserviceToggale} backdrop={false}>
                    <ModalHeader toggle={this.agentserviceToggale}>Monitor Cloud Services</ModalHeader>
                        <ModalBody>
                            <AvForm style={{padding:'10px'}}
                                onValidSubmit={this.addagentserviceData}
                            >
                                <AvGroup>
                                    <Label>Your Name</Label>
                                    <AvField type="text" className="form-control" name="name" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>Company name</Label>
                                    <AvField type="text" className="form-control" name="company_name" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>Your email</Label>
                                    <AvField type="email" className="form-control" name="email" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>Contact Number</Label>
                                    <AvField type="number" className="form-control" name="number" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>Cloud infrastructure</Label>
                                    <Select
                                        isMulti
                                        options={[
                                            { value: 'aws', label: 'AWS' },
                                            { value: 'googlecloud', label: 'Google Cloud' },
                                            { value: 'azure', label: 'Azure' }
                                          ]}
                                        className="basic-multi-select"
                                        onChange={(e)=>{
                                            if(e !==  null)
                                                this.setState({infrastructures:[...e.map((i) => i.value)]})
                                            else
                                                this.setState({infrastructures:[]})
                                        }}
                                        required
                                    />
                                </AvGroup>
                                
                                <AvGroup style={{float:'right'}}>
                                    <Button color="primary" type="submit">Submit</Button>
                                </AvGroup>
                            </AvForm>
                        </ModalBody>
                </Modal>

            </React.Fragment>
        );
    }
}

export default AnomalyDetection;
