import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import Loader from "react-loader-spinner";
import {url} from '../../services/common';

class ThreatOccurenceComparison extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [48, 34, 18, 0],
            options : {
                labels: ["Azure", "AWS", "GCP", "OCI"],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '75%'
                        }
                    }
                },
                dataLabels: {
                    enabled: true
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        const total = series.reduce((a, b) => a + b, 0);
                        const percent = ((series[seriesIndex] / total) * 100).toFixed(1);
                        const label = w.config.labels[seriesIndex]; // Access label using seriesIndex
                        return `<div class="apexcharts-tooltip-custom">${label}: ${percent}%</div>`;
                    }
                },
                legend: {
                    position: 'right',
                },
                fill: {
                    type: 'gradient',
                },
                gradients: [
                    {
                      shade: 'light',
                      type: 'vertical',
                      gradientToColors: ['#DC9A1C', '#0197EA', '#04C687'],
                      stops: [0, 100],
                    },
                    {
                      shade: 'light',
                      type: 'vertical',
                      gradientToColors: ['#DC9A1C', '#0197EA', '#04C687'],
                      stops: [0, 100],
                    },
                    {
                      shade: 'light',
                      type: 'vertical',
                      gradientToColors: ['#DC9A1C', '#0197EA', '#04C687'],
                      stops: [0, 100],
                    },
                    {
                        shade: 'light',
                        type: 'vertical',
                        gradientToColors: ['#FF5733', '#6A00F4', '#00A800'], 
                        stops: [0, 100],
                    }
                    
                  ],
                  states: {
                    hover: {
                      filter: {
                        type: 'none',
                      },
                    },
                  },
                  theme: {
                    palette: 'palette1', // adding this theme to apply gradients
                  },
                  stroke: {
                    show: false, // Remove border lines
                  },

                
            
            },
            scanType:"all",
            isLoading:false,
            loadingMessage:'Loading...'
        }
    }
    
    componentDidMount()
    {
        this.ChangeScanType()
    }

    ChangeScanType = (scanType) =>{
        var authToken = localStorage.getItem('auth_token');  	
        this.setState({isLoading: true})
        fetch(`${url}/cloud-based-threat-occurence-comparison`, {
            method: "GET",  
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {
            let seriesUpdated    =   json.pieData;
            this.setState({series:seriesUpdated})
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }

    render() {
        return (
            <React.Fragment>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Cloud Platform Based Threat Occurence Comparison</h4>
                            <div id="donut-chart" className="apex-charts">
                                {
                                    this.state.isLoading ? 
                                        <div>
                                        <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'20%'}}>{this.state.loadingMessage}</h3>
                                        <Loader
                                            type="Bars"
                                            color="#00BFFF"
                                            height={100}
                                            width={'100%'}   
                                            timeout={5000} //3 secs
                                        />  
                                        </div> 
                                    : <ReactApexChart options={this.state.options} series={this.state.series} type="pie" height="230" />
                                }
                            </div>

                            
                        </CardBody>
                    </Card>
            </React.Fragment>
        );
    }
}

export default ThreatOccurenceComparison;