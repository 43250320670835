import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import Loader from "react-loader-spinner";
import {url} from '../../services/common';

class ScanResultsRatio extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            series: [0, 0, 0],
            options: {
                labels: ["OK", "FAILED", "UNKNOWN", "WARN"],
                fill: {
                    type: 'solid', // Change the type to 'solid'
                  },
                  colors: ['#75AD6F', '#E3071D', '#FFBE00', '#FF7E01'],
                  states: {
                    hover: {
                      filter: {
                        type: 'none',
                      },
                    },
                  },
                  theme: {
                    palette: 'palette1', // adding this theme to apply gradients
                  },
                  stroke: {
                    show: false, // Remove border lines
                  },
                plotOptions: {
                    pie: {
                        // Remove the 'donut' property to make it a pie chart
                        innerRadius: 0
                    }
                },
                dataLabels: {
                    enabled: true,                    
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        const total = series.reduce((a, b) => a + b, 0);
                        const percent = ((series[seriesIndex] / total) * 100).toFixed(1);
                        const label = w.config.labels[seriesIndex]; // Access label using seriesIndex
                        return `<div class="apexcharts-tooltip-custom">${label}: ${percent}%</div>`;
                    }
                },
                legend: {
                    position: 'right', // Change the position to 'right'   
                    labels: {
                        colors: '#fff', // Set the legend text color to white
                    }                 
                },                
            },
            scanType:"all",
            isLoading:false,
            loadingMessage:'Loading...'
        }
    }
    
    componentDidMount()
    {
        this.setState({series:this.props.data})
        this.ChangeScanType(this.state.scanType)
        this._isMounted = true;
    }

   
    
    componentWillUnmount() {
        this._isMounted = false;
        // Cancel or clean up your asynchronous tasks here
    }



    ChangeScanType = (value) =>{        

        
        this.setState({scanType:value})
        var authToken = localStorage.getItem('auth_token');  	
        var newurl =   `${url}/scan-results-Ratio?scanResult=${value}`;
        this.setState({isLoading: true})

        fetch(newurl, {
            method: "GET",  
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {


            if(json){
              let seriesUpdated  =  json.pieData;
              console.log('seriesUpdated', seriesUpdated)
              this.setState({series:seriesUpdated})
            }
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
       
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-right">
                            <select name="scantype" onChange={(e)=>this.ChangeScanType(e.target.value)} className="custom-select custom-select-sm">
                                <option value="all" {...this.state.scanType === 'All' ? 'selected': ''}>All</option>
                                <option value="aws" {...this.state.scanType === 'aws' ? 'selected': ''}>AWS</option>
                                <option value="gcp" {...this.state.scanType === 'gcp' ? 'selected': ''}>GCP</option>
                                <option value="azure" {...this.state.scanType === 'azure' ? 'selected': ''}>AZURE</option>
                                <option value="oci" {...this.state.scanType === 'oci' ? 'selected': ''}>OCI</option>
                            </select>
                        </div>
                        <h4 className="card-title mb-4">Scan Results Ratio</h4>

                        <div id="donut-chart" className="apex-charts">
                            {
                                this.state.isLoading ? 
                                    <div>
                                    <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'20%'}}>{this.state.loadingMessage}</h3>
                                    <Loader
                                        type="Bars"
                                        color="#00BFFF"
                                        height={100}
                                        width={'100%'}   
                                    />  
                                    </div> 
                                : <ReactApexChart options={this.state.options} series={this.state.series} type="pie" height="230" />
                            }
                        </div>

                        
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default ScanResultsRatio;