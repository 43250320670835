import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';
import {url} from '../../services/common';

class ClusterLineChart extends Component {
        constructor(props) {
        super(props);
            this.state = {
                series: [],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                        enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Date wise vulnerability trends',
                        align: 'left'
                    },
                    xaxis: {
                        categories: [],
                    }
                },
                authToken:localStorage.getItem('auth_token'),
                isLoading:false,
                loadingMessage:'Loading..',
            };
        }
        componentDidMount(){
            this.getchart()
        }
        getchart = () =>{
            this.setState({isLoading: true});    
            this.setState({loadingMessage: 'Loading...'});
            fetch(`${url}/kubernetes-clusters/dashboard-chart`, {
                method: "GET",  
                headers: {
                'Accept': 'application/json',  
                'Authorization': 'Bearer '+this.state.authToken                  
                }            
            })
            .then((response) => response.json())
            .then((json) => {
                this.setState({series:json.series});
                this.setState(prevState => ({
                    options: {
                        ...prevState.options,           // copy all other key-value pairs of food object
                        xaxis: {                     // specific object of food object
                            ...prevState.options.xaxis,   // copy all pizza key-value pairs
                            categories: json.categories          // update value of specific key
                        }
                    }
                }))
            })
            .catch((error) => console.error('ss'+error))
            .finally(() => this.setState({isLoading: false}));  
        }
        render() {
            return (
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
            )
        }
}

export default ClusterLineChart;