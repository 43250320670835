import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "../Dashboard/dashboard.scss";
import Loader from "react-loader-spinner";
import {url} from '../../services/common';

class DockerLowMediumHigh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options : {
                labels: ["LOW", "MEDIUM","HIGH"],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '75%'
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                },
                colors: ['#CA0B00', '#5cb85c','#e50202'],
            
            },
            authToken:localStorage.getItem('auth_token'),
            isLoading:false,
            loadingMessage:'Loading...'
        }
    }
    
    componentDidMount()
    {
       this.getpieData()
    }

    getpieData = () =>{

        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Loading...'});
        fetch(`${url}/dockers-images/dashboard-piechart`, {
            method: "GET",  
            headers: {
            'Accept': 'application/json',  
            'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({series:json.pieData});
        })
        .catch((error) => console.error('ss'+error))
        .finally(() => this.setState({isLoading: false}));  
        
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Vulnerabilities Severity Distribution</h4>

                        <div id="donut-chart" className="apex-charts">
                            {
                                this.state.isLoading ? 
                                    <div>
                                        <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'20%'}}>{this.state.loadingMessage}</h3>
                                        <Loader
                                            type="Bars"
                                            color="#00BFFF"
                                            height={100}
                                            width={'100%'}   
                                            timeout={5000} //3 secs
                                        />  
                                    </div> 
                                : <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="230" />
                            }
                        </div>
                        <Row>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle font-size-10 mr-1" style={{color:'#CA0B00'}}></i>LOW</p>
                                    <h5>{this.state.series && this.state.series[0]}%</h5>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle font-size-10 mr-1" style={{color:'#5cb85c'}}></i>MEDIUM</p>
                                    <h5>{this.state.series && this.state.series[1]}%</h5>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle font-size-10 mr-1" style={{color:'#e50202'}}></i>HIGH</p>
                                    <h5>{this.state.series && this.state.series[2]}%</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default DockerLowMediumHigh;