import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Row, Col, Card, Modal, Container, ModalHeader, ModalBody, ModalFooter, CardBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../Tables/datatables.scss";

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from "react-loader-spinner";
//Import Charts
import ReactApexChart from 'react-apexcharts';
import {url,Downloadurl} from '../../services/common';
import "../Dashboard/dashboard.scss";
import toastr from 'toastr'
import {Helmet} from "react-helmet";

class Clusters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
          { title : "Dashboard", link : "/dashboard" },
          { title : "Clusters", link : "#" },
      ],
      series: [42.0, 26.0, 15.0],
      options : {
          labels: ["OK", "UNKNOWN", "FAILED"],
          plotOptions: {
              pie: {
                  donut: {
                      size: '75%'
                  }
              }
          },
          dataLabels: {
              enabled: false
          },
          legend: {
              show: false,
          },
          colors: ['#0EC905', '#FFC300', '#CA0B00'],
      
      },
      ClusterType:this.props.match.params.type,
      tableData:[
        {
          id:1,
          cluster_name:'Test Aks',
          threats_found:0,
        },
        {
          id:2,
          cluster_name:'Test EKS',
          threats_found:23,
        }
      ],
      authToken:localStorage.getItem('auth_token'),
      isLoading:false,
      loadingMessage:'',
      showResultModal:false,
      currentlyShowingScan:{},
      currentScanId:null,
      currentScanName:'',
      show:false,
      Editshow:false,
      scanName:'',
      scanType:this.props.match.params.type,
      clusterType:this.props.match.params.type,
      clusterId:null,
      editId:null,
      editType:null,
    };
  }
  
  child   = { width: `30em`, height: `100%`}
  
  ref = React.createRef();
  
  options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4,2]
  };

  componentDidMount()
  {
      this.getClusters(this.state.clusterType);
  }

  getClusters = (type) => {
      this.setState({isLoading: true});    
      this.setState({loadingMessage: 'Loading Clusters'});    
      fetch(`${url}/kubernetes-clusters/get?type=${type}`, {
                  method: "GET",     
                  headers: {
                    'Accept': 'application/json',  
                    'Authorization': 'Bearer '+this.state.authToken                  
                  }            
              })
              .then((response) => response.json())
              .then((json) => this.setState({tableData: json.clusters}))
              .catch((error) => console.error('ss'+error))
              .finally(() => this.setState({isLoading: false})); 
  }
 
  createScan = (e,value) => {
  	  this.setState({isLoading: true});    
  	  this.setState({loadingMessage: 'Creating Scan'});   
      value.kubernetes_cluster_id = this.state.clusterId
      
     fetch(`${url}/kubernetes-cluster/scan/create`, {
        method: "POST",
        body:JSON.stringify(value),     
        headers: {
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+this.state.authToken                  
          }            
      })
      .then((response) => response.json())
      .then((json) => {
        if(json.success){
           // toastr.success(json.message,'Success');
        }else{
            //toastr.error(json.message,'Error');
        }
      })
      .catch((error) => console.error('ss'+error))
      .finally(() => {
        this.setState({isLoading: false})
        this.setState({show: false});    
      }); 
  }
 
  toggle = () => {
    var {show} = this.state
    this.setState({show:!show})
  }

  addScan = (clusterId,scanType) =>{
    this.setState({show:true,clusterId:clusterId,scanType:scanType})
  }

  EditCluster = (id,editType) => {
    this.setState({Editshow:true,editId:id,editType:editType})
  }

  UpdateCluster = (e,value) => {
    this.setState({isLoading: true});    
    this.setState({loadingMessage: 'Update Cluster'});   
    value.id= this.state.editId;
    value.type= this.state.clusterType;
    fetch(`${url}/kubernetes-cluster/update`, {
      method: "POST",
      body:JSON.stringify(value),     
      headers: {
        'Accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+this.state.authToken                  
        }            
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success){
          this.setState({tableData: json.clusters})
          //toastr.success(json.message,'Success');
      }else{
          //toastr.error(json.message,'Error');
      }
    })
    .catch((error) => console.error('ss'+error))
    .finally(() => {
      this.setState({isLoading: false})
      this.setState({Editshow: false});    
    }); 
  }

  render() 
  {

    const data = {
      columns: [
        {
          label: "Cluster ID",
          field: "id",
          sort: "asc",
          width: 30
        },
        {
          label: "Cluster Name",
          field: "cluster_name",
          sort: "asc",
          width: 30
        },
        {
          label: "Cluster Type",
          field: "cluster_provider",
          sort: "asc",
          width: 30
        },
        {
          label: "Actions",
          field: "action",
          sort: "asc",
          width: 100
        }
      ],
      rows: [

      ]
    };

    var theData = {};
    theData.columns = data.columns;
    var theRows = this.state.tableData;
    if(theRows){
      for(var i=0;i<this.state.tableData.length;i++){
           let clusterId = theRows[i].id
           let type = theRows[i].cluster_provider
           theRows[i].action = <div>
                                  <Button color="primary" size="sm"
                                    onClick={()=>this.EditCluster(clusterId,type)}
                                  >Edit</Button>{' '}
                                  <Button color="primary" size="sm"
                                    onClick={()=>this.addScan(clusterId,type)}
                                  >Add Scan</Button>{' '}
                                  <Button color="primary" size="sm"
                                    onClick={()=>this.props.history.push(`/kubernetes/cluster/${clusterId}/scans`)}
                                  >View Scans</Button>{' '}
                                  <Button color="danger" size="sm">Delete</Button>
                                </div>

      }    
    }
    theData.rows = theRows;

    return (
      <React.Fragment>
      <Helmet><title>{this.state.ClusterType} Cluster</title></Helmet>
      {this.state.isLoading &&
         <div>
          <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
          <Loader
             type="Bars"
             color="#00BFFF"
             height={100}
             width={'100%'}   
             timeout={5000} //3 secs
          />  
         </div>     
      }
      
      {!this.state.isLoading &&
      
        <div className="page-content" >
        <Container fluid >
        <Breadcrumbs title={`${this.state.ClusterType} Cluster`} breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                     <h4 className="card-title">View and manage your {this.state.ClusterType} clusters</h4>
                     
                     <MDBDataTable responsive bordered data={theData} />
                  </CardBody>
                </Card>
              </Col>
            </Row> 
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop={false}>
              <ModalHeader toggle={this.toggle}>Add {this.state.scanType} Scan</ModalHeader>
              <AvForm className="form-horizontal" onValidSubmit={this.createScan}>
              <ModalBody>
                  <Row>
                    <Col xs={12}>
                      <AvField name="scan_name" 
                              value={this.state.scanName} 
                              onChange={(event)=>this.setState({scanName: event.target.value})} 
                              type="text" className="form-control" 
                              validate={{required: true}} 
                              placeholder="Enter Scan Name"/>
                    </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit">Add Scan</Button>
              </ModalFooter>
              </AvForm>
            </Modal>

            <Modal isOpen={this.state.Editshow} toggle={()=>this.setState({Editshow:false})} backdrop={false}>
            <ModalHeader toggle={()=>this.setState({Editshow:false})}>Edit Cluster</ModalHeader>
            <AvForm className="form-horizontal" onValidSubmit={this.UpdateCluster}>
            <ModalBody>
                <Row>
                  <Col xs={12}>
                    <AvField name="cluter_name" 
                            value={this.state.scanName} 
                            onChange={(event)=>this.setState({scanName: event.target.value})} 
                            type="text" className="form-control" 
                            validate={{required: true}} 
                            placeholder="Enter cluster name"/>
                  </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">Update Cluster</Button>
            </ModalFooter>
            </AvForm>
          </Modal>
            
          </Container>
        </div>
       }
      </React.Fragment>
    );
  }
}

export default Clusters;
