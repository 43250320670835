import React, { Component } from "react";
import { Form,Container, Row,  Modal, ModalHeader, ModalBody, ModalFooter, Col, Card, CardBody, ButtonGroup,Input,Button,FormGroup,Label} from "reactstrap";
//Import Breadrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";
import toastr from 'toastr'
//Import Components
import MiniWidgetsKubernetes from "./MiniWidgetsKubernetes";
import LargeWidgetsKubernetes from "./LargeWidgetsKubernetes";
import KubernetesTabs from "./KubernetesTabs";
import { AvForm,AvField,AvGroup } from 'availity-reactstrap-validation';
import {url} from '../../services/common';
import {Helmet} from "react-helmet";
import ClusterFailedPass from './ClusterFailedPass'
import ClusterLineChart from './ClusterLineChart'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import './pdfStyles.css';


class KubernetesVulnManagement extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Intrucept CSPM", link: "#" },
                { title : "Kubernetes Dashboard" , link : "#" },
            ],
            authToken:localStorage.getItem('auth_token'),  
            isLoading:false,
            loadingMessage:'',
            scans:null,
            threatsAnalytics:null,
            threats:null,
            barChart:null,
            user_type:'normal',
            tableData:[],
            show:false,
            allPerms:[],
            singleUserPerms:[],
            addClusterModal:false,
            cluster_provider:''
        }

        this.exportPDFWithMethod = this.exportPDFWithMethod.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);

        this.container = React.createRef();
        this.pdfExportComponent = React.createRef();
    }


    exportPDFWithMethod(){

        let element = this.container.current || document.body;
        savePDF(element, {
          paperSize: "auto",
          margin: 40,
          fileName: `Report for ${new Date().getFullYear()}`,
        });
      }
    
    exportPDFWithComponent(){
        
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
    };

   
    componentDidMount()
    {
        var isLoggedIn = localStorage.getItem("is_logged_in");
        var superAdmin = localStorage.getItem("user_type");
        
        if(isLoggedIn){
                if(isLoggedIn === "no"){
                    this.props.history.push('/');       	 
                }
                if(superAdmin === 'admin'){
                    this.setState({user_type:'admin'})
                }   
        }else{
            this.props.history.push('/');    
        }
    }

    addClustertoggle = () => {
        this.setState({addClusterModal:!this.state.addClusterModal})
    }

    addClusterData = (e,value) => {
        this.setState({isLoading: true});    
        this.setState({loadingMessage: 'Adding Cluster'});    
        value.cluster_provider = this.state.cluster_provider;	 
        fetch(`${url}/kubernetes-cluster/create`, {
            method: "POST",
            body:JSON.stringify(value),     
            headers: {
                'Accept': 'application/json', 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer '+this.state.authToken                  
            }            
        })
        .then((response) => response.json())
        .then((json) => {
            
            if(json.success){
                //toastr.success(json.message,'Success');
            }else{
               // toastr.error(json.message,'Error');
            }
        })
        .catch((error) => toastr.error('Something went wrong','Error'))
        .finally(() => {
            this.setState({isLoading: false});
            this.addClustertoggle();
        }); 
    }


    render() {
        return (
            <React.Fragment>
                <Helmet><title>Kubernetes Vulnerability Management</title></Helmet>


                <PDFExport
                   ref={this.pdfExportComponent}
                   paperSize="auto"
                   margin={40}
                   fileName={`Report for ${new Date().getFullYear()}`}
                    author="KendoReact Team"
                 >
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Kubernetes vulnerability Management Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        {
                            this.state.user_type === 'admin' ? 
                            <>
                                <Row>
                                    <Col xl={12}>
                                        <Row>
                                            <LargeWidgetsKubernetes history={this.props.history} />
                                        </Row>
                                    </Col>
                                   
                                </Row>
                            </>
                            : 
                            <Row>
                                <Col xl={12}>
                                    <Row>
                                        <MiniWidgetsKubernetes history={this.props.history}/>
                                    </Row>
                                </Col>
                            </Row>
                        }
                        
                        <Row>
                        
                            <Col xl={6}>
                                
                            </Col>
                        
                            <Col xl={6}>
                                <Button
                                    color="primary"
                                    onClick={()=> this.exportPDFWithComponent()}
                                    className=" waves-effect waves-light mr-1 pull-right"
                                    style={{float:'right'}}
                                >
                                    Export Report
                                </Button> 
                                <Button
                                    color="primary"
                                        onClick={this.addClustertoggle}
                                        className=" waves-effect waves-light mr-1 pull-right"
                                        style={{float:'right', marginLeft:10}}
                                    >
                                    Add a cluster
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col xl={8}>
                                <ClusterLineChart />
                            </Col>
                            <Col xl={4}>
                                <ClusterFailedPass />
                            </Col>
                        </Row>

                        <Row> 
                            <Col xl={12}>
                                  {/* <KubernetesTabs/> */}
                            </Col>   
                        </Row>     
                    </Container> 
                </div>
                </PDFExport>
                
            <Modal isOpen={false} toggle={this.toggle} backdrop={false}>
                <ModalHeader toggle={this.toggle}>Export Options</ModalHeader>
                <AvForm className="form-horizontal" onValidSubmit={this.createScan}>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <div style={{flexDirection:'row'}}>                  
                                    <AvField name="ScanType1" 
                                        value={this.state.scanName} 
                                        type="checkbox" 
                                        id="ScanType1"
                                        />
                                        <label for="ScanType1" style={{marginLeft:20}}>Detailed View </label> 
                                </div>                                  
                            </Col>
                            
                            <Col xs={12}>
                                <div style={{flexDirection:'row'}}>                  
                                    <AvField name="ScanType2" 
                                        value={this.state.scanName} 
                                        type="checkbox" 
                                        id="ScanType2"
                                        />
                                        <label for="ScanType2" style={{marginLeft:20}}>Graphical View</label> 
                                </div>                                  
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" 
                           onClick={()=> this.exportPDFWithComponent} 
                           type="submit">Export Report</Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        
            <Modal isOpen={this.state.addClusterModal} toggle={this.addClustertoggle} backdrop={false}>
                <ModalHeader toggle={this.addClustertoggle}>Add a cluster</ModalHeader>
                    <ModalBody style={{padding:'10px'}}>
                        <Row style={{padding:'10px'}}>
                            <select 
                                onChange={(e)=>{
                                    
                                    this.setState({cluster_provider:e.target.value})
                                }}
                                className="form-control"
                            >   
                                <option value=''>Select Cluster Type</option>
                                <option value="AKS">AKS</option>
                                <option value="EKS">EKS</option>
                            </select>

                        </Row>
                        {
                            this.state.cluster_provider === 'AKS' &&

                            <AvForm style={{padding:'10px'}}
                                onValidSubmit={this.addClusterData}
                            >
                                <p>Add AKS Cluster</p>
                                <AvGroup>
                                    <Label>Cluster Name</Label>
                                    <AvField type="text" className="form-control" name="cluster_name" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>AZURE CLIENT ID</Label>
                                    <AvField type="text" className="form-control" name="AKS_CLIENT_ID" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>AZURE TENANT ID</Label>
                                    <AvField type="text" className="form-control" name="AKS_TENANT_ID" required />
                                </AvGroup>
                                <AvGroup>
                                        <Label>AZURE CLIENT SECRET</Label>
                                        <AvField type="text" className="form-control" name="AKS_CLIENT_SECRET" required />
                                </AvGroup>
                                <AvGroup>
                                        <Label>AZURE SUBSCRIPTION ID</Label>
                                        <AvField type="text" className="form-control" name="AKS_SUBSCRIPTION_ID" required />
                                </AvGroup>
                                <AvGroup style={{float:'right'}}>
                                    <Button color="primary" type="submit">Add Cluster</Button>
                                </AvGroup>
                            </AvForm>
                        }
                        {
                            this.state.cluster_provider === 'EKS' &&

                            <AvForm style={{padding:'10px'}}
                                onValidSubmit={this.addClusterData}
                            >
                                <p>Add EKS Cluster</p>
                                <AvGroup>
                                    <Label>Cluster Name</Label>
                                    <AvField type="text" className="form-control" name="cluster_name" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>AWS PROFILE</Label>
                                    <AvField type="text" className="form-control" name="EKS_PROFILE" required />
                                </AvGroup>
                                <AvGroup>
                                    <Label>AWS ACCESS KEY ID</Label>
                                    <AvField type="text" className="form-control" name="EKS_ACCESS_KEY_ID" required />
                                </AvGroup>
                                <AvGroup>
                                        <Label>AWS SECRET ACCESS KEY</Label>
                                        <AvField type="text" className="form-control" name="EKS_SECRET_ACCESS_KEY" required />
                                </AvGroup>
                                <AvGroup style={{float:'right'}}>
                                    <Button color="primary" type="submit">Add Cluster</Button>
                                </AvGroup>
                            </AvForm>
                        }
                    </ModalBody>
            </Modal>
        
        
            </React.Fragment>
        );
    }
}

export default KubernetesVulnManagement;
