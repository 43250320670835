import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import UiCarousel from "../pages/Ui/UiCarousel";

import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";

//Kanban Board
import KanbanBoard from "../pages/KanbanBoard/index";

// Threatspotter User Pages
import MyAccount from "../pages/threatspotterUser/myAccount";
import AWSScans from "../pages/threatspotterUser/awsScans";
import ScheduleAnAWSScan from "../pages/threatspotterUser/scheduleAnAWSScan";
import AWSScanResultIntegration from "../pages/threatspotterUser/awsScanResultIntegration";
import AzureScans from "../pages/threatspotterUser/azureScans";
import ScheduleAnAzureScan from "../pages/threatspotterUser/scheduleAnAzureScan";
import AzureScanResultIntegration from "../pages/threatspotterUser/azureScanResultIntegration";
import GCPScans from "../pages/threatspotterUser/gcpScans";
import ScheduleAGCPScan from "../pages/threatspotterUser/scheduleAGCPScan";
import GCPScanResultIntegration from "../pages/threatspotterUser/gcpScanResultIntegration";
import OracleCloudScans from "../pages/threatspotterUser/oracleCloudScans";
import ScheduleAnOracleScan from "../pages/threatspotterUser/scheduleAnOracleCloudScan";
import OracleCloudScanResultIntegration from "../pages/threatspotterUser/oracleCloudScanResultIntegration";
import Settings from "../pages/threatspotterUser/settings";
import ResultIntegration from "../pages/threatspotterUser/resultIntegration";
import Welcome from "../pages/Authentication/welcome";
import Users from "../pages/Users";
import AddUsers from "../pages/Users/add";
import EditUsers from "../pages/Users/edit";
import HowToGetKeys from "../pages/threatspotterUser/howToGetKeys";
import TermsAndConditions from "../pages/threatspotterUser/TermsAndConditions";

import KubernetesVulnManagement from "../pages/kubernetesVulnManagement/kubernetesVulnManagement";
import Clusters from "../pages/kubernetesVulnManagement/Clusters";
import ClusterScans from "../pages/kubernetesVulnManagement/ClusterScans";
import ClusterResultHistory from "../pages/kubernetesVulnManagement/ClusterResultHistory";

import DockerVulnManagement from "../pages/Docker/dockerVulnManagement";
import AllImages from "../pages/Docker/AllImages";
import AllScans from "../pages/Docker/AllScans";
import DockerResultHistory from "../pages/Docker/DockerResultHistory";

import AnomalyDetection from "../pages/Anomaly/AnomalyDetection"
import AnomalyDetectionAgents from "../pages/Anomaly/AnomalyDetectionAgents"
import AnomalyDetectionAgentInstallationGuide from "../pages/Anomaly/AnomalyDetectionAgentInstallationGuide"
import Notifications from "../pages/Notifications"


const authProtectedRoutes = [

    //Kanban Board
	{ path: "/apps-kanban-board", component : KanbanBoard },
	
	// App routes
	{ path: "/all-users", component: Users , title:"All Users" },
	{ path: "/add-user", component: AddUsers },
	{ path: "/edit-user/:id", component: EditUsers },

	{ path: "/my-account", component: MyAccount },
	{ path: "/aws-scans", component: AWSScans },
	{ path: "/schedule-an-aws-scan", component: ScheduleAnAWSScan },
	{ path: "/aws-scan-result-integration", component: AWSScanResultIntegration },
	{ path: "/azure-scans", component: AzureScans },
	{ path: "/schedule-an-azure-scan", component: ScheduleAnAzureScan },
	{ path: "/azure-scan-result-integration", component: AzureScanResultIntegration },
	{ path: "/gcp-scans", component: GCPScans },
   	{ path: "/schedule-a-gcp-scan", component: ScheduleAGCPScan },
   	{ path: "/gcp-scan-result-integration", component: GCPScanResultIntegration },
   	{ path: "/oracle-cloud-scans", component: OracleCloudScans },
   	{ path: "/schedule-an-oracle-cloud-scan", component: ScheduleAnOracleScan },
   	{ path: "/oracle-cloud-scan-result-integration", component: OracleCloudScanResultIntegration },
   	{ path: "/settings", component: Settings },
   	{ path: "/result-integrations", component: ResultIntegration },
   	{ path: "/how-to-get-cloud-keys", component: HowToGetKeys },

   	{ path: "/kubernetes-vulnerability-management", component: KubernetesVulnManagement },
   	{ path: "/kubernetes/:type/cluster", component: Clusters },
   	{ path: "/kubernetes/cluster/:id/scans", component: ClusterScans },
   	{ path: "/kubernetes/cluster/scan-result/:id", component: ClusterResultHistory },

   	{ path: "/docker-vulnerability-management", component: DockerVulnManagement },
	{ path: "/docker/all-images", component: AllImages },
	{ path: "/docker/scans/:id", component: AllScans },
	{ path: "/docker/image/scan-result/:id", component: DockerResultHistory },

	{ path: "/anomaly-detection", component: AnomalyDetection },
	{ path: "/anomaly-detection-agents", component: AnomalyDetectionAgents },

	{ path: "/anomaly-detection-agent-installation-guide", component: AnomalyDetectionAgentInstallationGuide },
	{ path: "/notifications", component: Notifications },	

	
	{ path: "/ui-carousel", component: UiCarousel },	
	{ path: "/ui-tabs-accordions", component: UiTabsAccordions },	

	{ path: "/dashboard", component: Dashboard,title:"dash" },
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" />}
];

const publicRoutes = [
	{ path:"/",component:Welcome},
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/register", component: Register },
	{ path: "/terms-and-conditions", component: TermsAndConditions },	
	
];

export { authProtectedRoutes, publicRoutes };
