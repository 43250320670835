import React, { Component } from 'react';
import './welcome.css';
import {Link as Scroll} from 'react-scroll';
import { withRouter, Link } from 'react-router-dom';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    UncontrolledDropdown,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    DropdownToggle
} from 'reactstrap';

import toastr from 'toastr'
import {Helmet} from "react-helmet";
import logoDark from "../../assets/images/intrucept_logo.svg";
import logoLight from "../../assets/images/intrucept-white-logo.png";
import axios from 'axios';


class Welcome extends Component {

    constructor(props) {

        super(props);
        this.state = {
            input:"",
            isOpen:false,
            isShown:false,
            formData: {
                name: '',
                email: '',
                message: '',
            },
            apiResponse: '',
            formSubmitted:false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange = (e) => {
        
        const { name, value } = e.target;
        this.setState((prevState) => ({
          formData: { ...prevState.formData, [name]: value },
        }));
      };
    
      handleSubmit = async (e) => {

        e.preventDefault();

        this.setState({ formSubmitted: true });
    
       try {
            const response = await fetch('https://scan.intruceptlabs.com/api/submit-contact-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.formData),
            });

      if (response.ok) {
        const responseData = await response.json();
        //this.setState({ apiResponse: responseData, formSubmitted: true });
      } else {
        console.error('Error:', response.statusText);
        
      }
    } catch (error) {
      console.error('Error:', error);
    }
      };

    SubscribeHandle = (e) => {
        e.preventDefault();
        if(this.state.input === ''){
           // toastr.info('Please enter E-mail');    
        }
        else{
           // toastr.success('Thank you for subscribing');
        }
        
    }

    inputhandle =  (e) => {
        let inputValue = e.target.value;
        this.setState({input:inputValue})
    }
  

    openCommunity =  () => {

        window.open('https://github.com/goyalvartul/threatspotter', '_blank');
    }

    toggle = () => {
        let {isOpen}    =   this.state;
        
        this.setState({isOpen:!isOpen})
    }

    render() {

        const { formData, apiResponse } = this.state;


        var isLoggedIn = localStorage.getItem("is_logged_in");
        
        if(isLoggedIn){
        	 if(isLoggedIn === "no"){
                  	 
        	 }
             
        }else{
           
        }

        return (
            <React.Fragment>
            <Helmet><title>Welcome</title></Helmet>
            
            <div id="welcomepage">
                    <div>           
                    

                        <Navbar light expand="md" className="welcome-page-navbar">

                            <NavbarBrand href="/">
                                    <img src={logoDark} alt="logo-img" style={{width:300}}/>
                            </NavbarBrand>
                            <NavbarToggler onClick={this.toggle} />

                            <Collapse isOpen={this.state.isOpen} navbar>

                            <Nav className="ml-auto" navbar>

                                <NavItem className="pl-3">

                                <NavLink style={{display:'none'}} href="#" onMouseEnter={() => this.setState({isShown:true})}
                                                  onMouseLeave={() => this.setState({isShown:false})}>
                                        <Scroll to="features" spy={true} smooth={true}>PRODUCT</Scroll>
                                    </NavLink>

                                <Dropdown isOpen={this.state.isShown} style={{marginTop:-10, display:'none'}} onMouseEnter={() => this.setState({isShown:true})}
                                                  onMouseLeave={() => this.setState({isShown:false})}>
                                      <DropdownToggle caret style={{display:'none'}}>
                                      </DropdownToggle>
                                     <DropdownMenu right style={{backgroundColor:'#ffffff'}}>
                                        <DropdownItem header><Link to="/login">ENTERPRISE</Link>
                                        </DropdownItem>
                                        <DropdownItem header>
                                        <Link target="_blank"  onClick={this.openCommunity}
                                          to="//https://github.com/goyalvartul/threatspotter">COMMUNITY</Link>
                                        </DropdownItem>   
                                   </DropdownMenu>
                               </Dropdown>                                     

                                 
                                                                     
                                </NavItem>

                                <NavItem style={{display:'none'}} className="pl-3">
                                    <NavLink href="#">
                                        <Scroll to="features" spy={true} smooth={true}>FEATURES</Scroll>
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{display:'none'}} className="pl-3">
                                    <NavLink href="#">
                                        <Scroll to="aboutScroll" spy={true} smooth={true}>ABOUT</Scroll>
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{display:'none'}} className="pl-3">
                                    <NavLink href="#">
                                        <Scroll to="pricing" spy={true} smooth={true}>PRICING</Scroll>
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{display:'none'}} className="pl-3">
                                    <NavLink href="#">
                                        <Scroll to="contact" spy={true} smooth={true}>CONTACT</Scroll>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="pl-3">
                                    <NavLink href="#">
                                        {isLoggedIn && isLoggedIn == 'yes' ?
                                          <Link to="/dashboard" style={{color:'#616060'}}>DASHBOARD</Link>
                                          :
                                          <Link to="/login" style={{color:'#616060'}}>SIGN IN</Link>
                                        }   
                                    </NavLink>
                                </NavItem>

                            </Nav>
                            </Collapse>
                        </Navbar>
                    </div>

                    <main>
                        <section className="" id="about">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="">
                                        <img style={{marginTop:"30px",width:"100%"}} src={require('./cloudsec3.png')} alt="illustration-intro" />
                                    </div>
                                </div>
                                <div className="col-md-6" style={{textAlign:"left",marginTop:'auto',marginBottom:'auto'}}>
                                    <div className="" style={{marginTop:"30px"}} >
                                        <h1> Keep Track of Security Threats on Your Cloud Infrastructure</h1>
                                        <p className="" style={{textAlign:"left", color:'#ffffff', fontSize:18}}>
                                            We take care of "Shared Responsibility Model" where each and every 
                                            aspect of cloud infrastructure ensures the compliance check against malware and intrusion attacks.
                                        </p>
                                        <div>
                                            <button className="ts-button get-started" >
                                                <Link to="/login" style={{width:200}}>Get Started</Link>
                                            </button>
                                            <button className="ts-button see-features" 
                                               >
                                                <Scroll to="features" style={{width:200, backgroundColor:'transparent'}} spy={true} smooth={true}>See Features</Scroll>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="features mt-3" id="features">
                            <Row xs="1" sm="1" md="3">
                                <Col>
                                    <div className="features-item">
                                        <div className="features-item-head verticalAlign" >
                                            <img src={require('../../assets/images/icon-access-anywhere.svg')} alt="" />
                                        </div>
                                        <div className="features-item-body  mt-2">
                                            <h3>Compliance Security Automation</h3>
                                            <p style={{textAlign:"center", color:'#ffffff', fontSize:16}}>Regular monitoring helps in identifying the container vulnerabilities, intrusion attacks and malware attacks</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="features-item">
                                        <div className="features-item-head verticalAlign" >
                                            <img src={require('../../assets/images/icon-security.svg')} alt="" />
                                        </div>
                                        <div className="features-item-body mt-2">
                                            <h3>Security You Can Trust</h3>
                                            <p style={{textAlign:"center", color:'#ffffff', fontSize:16}}>Ensure the compliance over infrastructure workloads and cloud workloads</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="features-item">
                                        <div className="features-item-head verticalAlign" >
                                            <img src={require('../../assets/images/icon-collaboration.svg')} alt="" />
                                        </div>
                                        <div className="features-item-body  mt-2">
                                            <h3>Real-Time Analytics</h3>
                                            <p style={{textAlign:"center", color:'#ffffff', fontSize:16}}>Threat analytics dashboard where user and management can see the compliance score trend on weekly, monthly and yearly basis</p>
                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                            <Row xs="1" sm="1" md="2" className="text-center">
                                <Col className="m-auto">
                                    <div className="features-item">
                                        <div className="features-item-head" >
                                            <img src={require('../../assets/images/icon-any-file.svg')} alt="" />
                                        </div>
                                        <div className="features-item-body  mt-2">
                                            <h3>Relnvent Your continuous Monitoring</h3>
                                            <p style={{textAlign:"center", color:'#ffffff', fontSize:16}}>Helps in achievement of tracking continuously, helps in attaining rules and regulations with different compliance requirements</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                        
                    <section className="pricing py-5" id="pricing" style={{display:'none', }}>
                        <div className="container">
                            <h1 className="text-center">Pricing Plans</h1>
                            <div className="row" style={{marginTop:10}}>

                            <div className="col-lg-4">
                                <div className="card mb-5 mb-lg-0">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">Free</h5>
                                    <h6 className="card-price text-center" style={{fontSize:"2rem"}}>$800<span className="period">/Quarterly</span></h6>
                                    <hr></hr>
                                    <ul className="fa-ul">
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Single User</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>20 Scans</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited access to available cloud scans</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Management Dashboard Integrations</li>
                                    <li className="text-muted" style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-times"></i></span>Unlimited Private Projects</li>
                                    <li className="text-muted" style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-times"></i></span>Dedicated Phone Support</li>
                                    <li className="text-muted" style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-times"></i></span>Threat Analytics Alerts</li>
                                    <li className="text-muted" style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-times"></i></span>Monthly Status Reports</li>
                                    </ul>
                                    <a href="#" className="ts-button" style={{width:'100%'}}>Subscribe</a>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card mb-5 mb-lg-0">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">Plus</h5>
                                    <h6 className="card-price text-center" style={{fontSize:"2rem"}}>$1400<span className="period">/Half Yearly Plan</span></h6>
                                    <hr></hr>
                                    <ul className="fa-ul">
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span><strong>5 Users</strong></li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited Scan</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited access to available cloud scans</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Management Dashboard Integrations</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited Private Projects</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Dedicated Phone Support</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Threat Analytics Alerts</li>
                                    <li style={{color:'#ffffff'}} className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Monthly Status Reports</li>
                                    </ul>
                                    <a href="#" className="ts-button" style={{width:'100%'}}>Subscribe</a>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">Pro</h5>
                                    <h6 className="card-price text-center" style={{fontSize:"2rem"}}>$2000<span className="period">/Yearly Plan</span></h6>
                                    <hr></hr>
                                    <ul className="fa-ul">
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span><strong>Unlimited Users</strong></li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited Scan</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited Access to Threat Alerts Dashboard</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Management Integration Dashboard</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Customize Development according to Customer needs</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Dedicated Phone Support</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Daily Continous Threat Analytics Alerts</li>
                                    <li style={{color:'#ffffff'}}><span className="fa-li"><i className="fas fa-check"></i></span>Monthly Status Reports</li>
                                    </ul>
                                    <a href="#" className="ts-button" style={{width:'100%'}}>Subscribe</a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>                        

                    <section className="productive">
                        <div className="productive-img" style={{marginLeft:"auto",marginRight:"auto"}}>
                                <img src={require('../../assets/images/illustration-stay-productive.png')} alt="" />    
                        </div>
                        <div className="productive-content" style={{display:'none'}}>
                            <h2>Stay productive, wherever you are</h2>
                            <p className="lg-p"> Never let location be an issue when accessing your files. Fylo has you covered for all of your file storage needs. </p>
                            <p className="lg-p"> Securely share files and folders with friends, family and colleagues for live collaboration. No email attachments required.
                        </p>
                            <p className="lg-p"> <a className="content-link" href="#">See How CSPM Module Works <i className="fas fa-arrow-alt-circle-right"></i></a> </p>
                        </div>
                    </section>
                    
                    <div id="aboutScroll" className="row  mt-4">
                        
                        <div className="col" style={{textAlign:'center'}}>
                            <hr className="page-divider"/>
                            <br/>
                            <br/>
                            <h1>About Intrucept CSPM</h1>
                        </div>
                    </div>

                    <section className="AboutSection">


                        <h5 className="about-section-text"  >
                        Intrucept CSPM is a cloud security product that helps customers to achieve GDPR, PCI-DSS, and ISO compliance in an automated fashion where users would get real-time threat analytics results.</h5>
                        <br/>
                        <h5 className="about-section-text">Intrucept CSPM basically scans for compliance rules and data privacy which scans for every assets and resources attached to cloud in AWS, Azure and GCP that contains personal information and sensitive information inside to these resources.</h5> 
                        <br/>
                        <h5 className="about-section-text">Machine Learning technology learns from historical data and produce accurate results for GDPR, PCI-DSS, HIPAA, CIS, CCPA, FISMA, FINRA, NIST and SOC2, SOX with major compliance regulatory frameworks.</h5>
                        <br/>
                        <br/>
                        <br/>
                        <hr className="page-divider"/>
                        <h1 style={{marginTop:30, color:'#ffffff',textAlign:'center'}}>Intrucept CSPM Checks For</h1>
                            <Row xs="1" sm="1" md="4">
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Password Policies.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h4>Password Policies</h4>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Encryption level is not enabled at all servers.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h4>Encryption Standards</h4>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Application Level Controls.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h4>Application Level Controls</h4>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Network Level Controls.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h4>Network Level Controls</h4>
                                            </div>
                                        </div>
                                </Col>
                            </Row>
                            <Row xs="1" sm="1" md="4" className="text-center">
                                <Col className="m-auto"> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/IAM Permission Checks.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h4>IAM Permission Checks</h4>
                                            </div>
                                        </div>
                                </Col>
                                <Col className="m-auto"> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Host Infrastructure Environment.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h4>Host Infrastructure Environment</h4>
                                            </div>
                                        </div>
                                </Col>
                                <Col className="m-auto"> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/MFA Authentication.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h4>MFA Authentication</h4>
                                            </div>
                                        </div>
                                </Col>
                            </Row> 
                        <div className="row paddingtopBottom">
                            <div className="col-md-6">
                                <div className="AboutSectionImageDiv">
                                    <img style={{width:"75%"}} src={require('../../assets/images/aboutsection1.png')} alt="img-responsive" />
                                </div>
                            </div>
                            <div className="col-md-6" style={{textAlign:"left",marginTop:'auto',marginBottom:'auto'}}>
                                <div className="" style={{marginTop:"120px"}} >
                                 
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Intrucept CSPM compliance security software ensure the compliance over infrastructure workloads and cloud workloads. Regular Monitoring helps in identifying the container vulnerabilities, intrusion attacks and malware attacks. 
                                    Intrucept CSPM provides availability of continous monitoring of cloud assets and eliminate the possibility of future intrusion attacks.
                                    Intrucept CSPM ensures the "Business Continuity Process" by improving the posture of cloud infrastructure.</h5> 
                                    <br/>   
                                    <br/>                                   
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Intrucept CSPM takes care of "Shared Responsibility Model" where each and every aspect of cloud infrastructure ensures the compliance check against malware and intrusion attacks.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row paddingtopBottom">
                            <div className="col-md-6" style={{textAlign:"left",marginTop:'auto',marginBottom:'auto'}}>
                                <div className="" style={{marginTop:"10px"}} >
                                    <h4 style={{marginTop:30,textAlign:'left', color:'#62E0D9'}}>Intrucept CSPM</h4>
                                    <ul style={{color:'white', fontSize:18, fontWeight:700}}>
                                       <li>Ensures Cloud control security compliance policies</li>
                                       <li>Helps to audit the cloud security risk posture</li>
                                       <li>Helps in Identifying major vulnerabilties for prevention of high and severe cloud based attacks</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="AboutSectionImageDiv">
                                    <img style={{width:"75%"}} src={require('../../assets/images/section2.png')} alt="img-responsive" />
                                </div>
                            </div>
                        </div>

                        <div className="row paddingtopBottom">
                            <div className="col-md-6">
                                <div className="AboutSectionImageDiv">
                                    <img style={{width:"75%"}} src={require('../../assets/images/aboutsection3.png')} alt="img-responsive" />
                                </div>
                            </div>
                            <div className="col-md-6" style={{textAlign:"left",marginTop:'auto',marginBottom:'auto'}}>
                                <div className="" style={{marginTop:"10px"}} >
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Through the usage of Intrucept CSPM product, it provides flexibility to user that they can select high level vulnerabiilities from threat analytics and from affected region also. </h5>
                                    <br/> <br/>                                    
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Intrucept CSPM provides the threat analytics dashboard where user and management can see the compliance score trend on weekly, monthly and yearly basis. It would be helpful for the management to differentiate between initial score and last score.</h5>
                                </div>
                            </div>
                        </div>

                        <div className="row paddingtopBottom">
                            <div className="col-md-6" style={{textAlign:"left",marginTop:'auto',marginBottom:'auto'}}>
                                <div className="" style={{marginTop:"10px"}} >
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Intrucept CSPM tool integrates with private cloud resources and ensures that compliance risk management summary should be integrated with provided credentials so that all compliance results would be available in threat analytics dashboard. </h5>
                                    <br/>                                    
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Intrucept CSPM helps in achievement of tracking continously, helps in attaining rules and regulations with different compliance requirements.</h5>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="AboutSectionImageDiv">
                                    <img style={{width:"75%"}} src={require('../../assets/images/aboutsection4.png')} alt="img-responsive" />
                                </div>
                            </div>
                        </div>

                        <div className="row paddingtopBottom">
                            <div className="col-md-6">
                                <div className="AboutSectionImageDiv">
                                    <img style={{width:"75%"}} src={require('../../assets/images/section5.png')} alt="img-responsive" />
                                </div>
                            </div>
                            <div className="col-md-6" style={{textAlign:"left",marginTop:'auto',marginBottom:'auto'}}>
                                <div className="" style={{marginTop:"10px"}} >
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Intrucept CSPM risk management process works on utilising all private cloud resources and checks against all different compliance 
                                    dashboards where users can check their pass and fail conditions. </h5>
                                    <br/>
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>This process differentiate between non compliance requirements and compliance requirements. Intrucept CSPM risk management helps in identifying high severity compliance risks with most affected region and seggegate the level of vulnerabilities in threat analytics dashboard.</h5>
                                </div>
                            </div>
                        </div>

                        <div className="row paddingtopBottom">
                            <div className="col-md-6" style={{textAlign:"left",marginTop:'auto',marginBottom:'auto'}}>
                                <div className="" style={{marginTop:"10px"}} >
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>Generally private cloud providers provides basic security to customers but meeting the compliance security requirement is utmost requirement in the hands of customers. This is the "Shared Responsibility Model" needs to be taken care by customer onboard and that Intrucept CSPM helps in achieving the compliance security requirement against hackers. </h5>
                                    <br/>                                    
                                    <h5 className="AboutSectionparagraph" style={{textAlign:'justify'}}>With the increase in daily threats at cloud level and post covid, mostly all companies have been migrated to cloud solutions but they ignore the security where Intrucept CSPM is adding extra layer of security where it can as a layer of defense in saving millions of dollars of customers.</h5>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="AboutSectionImageDiv">
                                    <img style={{width:"75%"}} src={require('../../assets/images/section6.png')} alt="img-responsive" />
                                </div>
                            </div>
                        </div> 
                        <hr className="page-divider"/>
                        <h2 style={{marginTop:30,textAlign:'center', color:'#62E0D9'}}>
                            Apart from that </h2>
                        <h1 style={{marginTop:0,textAlign:'center'}}>Intrucept CSPM Features Helps in Identifying</h1>
                        <Row xs="1" sm="1" md="4">
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Misconfigured Servers.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>Misconfigured Servers</h5>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Misconfigured Buckets or file servers.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>Misconfigured Buckets or file servers</h5>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Encryption Standards.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>Encryption level is not enabled at all servers</h5>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Container level vulnerabilties are not identified.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>Container level vulnerabilties are not identified</h5>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/User level Permissions are misconfigured.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>User level Permissions are misconfigured</h5>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Port Identification for their opened services.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>Port Identification for their opened services</h5>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Changes occurs at IAM level.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>Changes occurs at IAM level</h5>
                                            </div>
                                        </div>
                                </Col>
                                <Col> 
                                        <div className="features-item">
                                            <div className="features-item-head" style={{minHeight:'100px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <img style={{width:'100px',maxHeight:'100px'}} className="img-responsive" src={require('../../assets/images/Changes occurs at AAA model.png')} alt="" />
                                            </div>
                                            <div className="features-item-body  mt-2">
                                                <h5>Changes occurs at AAA model</h5>
                                            </div>
                                        </div>
                                </Col>
                            </Row> 
                            <hr className="page-divider"/>
                        <h2 className="about-section-text">
                        Intrucept CSPM machine learning capabilities helps customers to learn from historical data and provide accurate results with alerts to customers.
                        </h2>
                        <br/><br/><br/>
                        <hr className="page-divider"/>
                    </section>
                       
                        
                    <div className="row mt-4" style={{paddingTop:100, display:'none'}}>
                        <div className="col" style={{textAlign:'center'}}>

                            <h1>What Our Client Say</h1>
                        </div>
                    </div>
                    <section className="testimonial" style={{display:'none'}}>
                        <div className="testimonial-item">
                            <div className="testimonial-item-reviewer">
                                <div className="testimonial-item-reviewer-img"> 
                                    <img src={require('../../assets/images/profile-1.jpg')} alt="" />
                                </div>
                                <div className="ml-4 testimonial-item-reviewer-text">
                                    <h4>Satish Patel</h4>
                                    <p style={{textAlign:'left'}}>Founder & CEO, Huddle</p>
                                </div>
                            </div>
                            <div className="testimonial-item-text">
                                <p style={{color:'#ffffff', textAlign:'center'}}>Intrucept CSPM has improved our team productivity by an order of magnitude. Since making the switch our team has become a well-oiled collaboration machine.</p>
                            </div>                            
                        </div>


                        <div className="testimonial-item">
                                <div className="testimonial-item-reviewer">
                                    <div className="testimonial-item-reviewer-img">
                                        <img src={require('../../assets/images/profile-2.jpg')} alt="" />
                                    </div>
                                        <div className="ml-4 testimonial-item-reviewer-text">
                                            <h4> Bruce McKenzie </h4>
                                            <p style={{textAlign:'left'}}>Founder & CEO, Huddle</p>
                                        </div>
                                </div>
                                <div className="testimonial-item-text">
                                    <p style={{color:'#ffffff', textAlign:'center'}}>Intrucept CSPM has improved our team productivity by an order of magnitude. Since making the switch our team has become a well-oiled collaboration machine.</p>
                                </div>
                                
                            </div>

                        <div className="testimonial-item">
                            <div className="testimonial-item-reviewer">
                                <div className="testimonial-item-reviewer-img">
                                    <img src={require('../../assets/images/profile-2.jpg')} alt="" />
                                </div>
                                <div className="ml-4 testimonial-item-reviewer-text">
                                    <h4> Iva Boyd</h4>
                                    <p style={{textAlign:'left'}}>Founder & CEO, Huddle</p>
                                </div>
                            </div>
                            
                            <div className="testimonial-item-text">
                                <p style={{color:'#ffffff', textAlign:'center'}}>Intrucept CSPM has improved our team productivity by an order of magnitude. Since making the switch our team has become a well-oiled collaboration machine.</p>
                            </div>
                            
                        </div>
                    </section>
                        
                        
                    <section className="contact mt-4" id="contact" style={{paddingTop:50}}>            
                                            
                    <div className="form-box">
                        <h2 className="text-center" style={{textTransform:'uppercase'}}>Contact Us for More Detailed Information</h2>

                        <form onSubmit={this.handleSubmit} style={{ marginTop: 20 }}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              className="form-control"
              id="name"
              type="text"
              name="name"
              placeholder="Your name"
              style={{ color: 'black' }}
              required
              value={formData.name}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className="form-control"
              id="email"
              type="email"
              name="email"
              required
              placeholder="Enter your E-mail"
              value={formData.email}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              placeholder="Type here..."
              required
              value={formData.message}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className="ts-button btn-block"
              style={{
                fontSize: 15,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                maxWidth: '100%',
              }}
            >
              SUBMIT
            </button>
          </div>
          <br/>
          {this.state.formSubmitted == true &&
                <span style={{color:'white', marginTop:10}}>Thank you for reaching out to us. We will reach out to you shortly.</span>
            }
        </form>

                    </div>

                    </section>    
                        
                    </main>

                    <footer>
                            <section className="contact-info" id="contact">
                                <div className="contact-info-logo">
                                    <img src={logoLight} alt="" style={{width:300}}/>
                                </div>
                                <div className="contact-info-location">
                                    <div className="contact-info-location-item">
                                        <i class="fas fa-map-marker-alt newicon"></i>
                                        <p style={{color:'#ffffff',textAlign:'left'}}>
                                            Your single stop solution for identifying threats to your cloud infrastructure, so you can protect it better.
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-info-contacts">
                                    <div className="contact-info-contacts-item" style={{display:'none'}}>
                                        <i class="fas fa-phone-alt newicon"></i>
                                        <p style={{color:'#ffffff'}}>
                                            <a style={{color:'#ffffff'}} href="tel:+XXX 41 7465660">+### ## ########</a>
                                        </p>
                                    </div>
                                    <div className="contact-info-contacts-item ">
                                        <i class="fas fa-envelope-square newicon"></i>
                                        <p style={{color:'#ffffff'}}>
                                            <a style={{color:'#ffffff'}} href="mailto:sales@intruceptlabs.com">sales@intruceptlabs.com</a>
                                        </p>
                                    </div>
                                </div>
                                <nav className="contact-info-links" style={{display:'none'}}>
                                    <ul className="contact-info-links-list" style={{listStyleType:'none',marginBottom:0}}>
                                        <li> <a href="#" style={{color:'#ffffff'}}>Contact Us</a> </li>
                                        <li> <Link  style={{color:'#ffffff'}} to="/terms-and-conditions">Terms and Conditions</Link> </li>
                                    </ul>
                                    <ul className="contact-info-links-list" style={{listStyleType:'none'}}>
                                        <li> <a href="#" style={{color:'#ffffff'}}>Privacy Policy</a> </li>
                                        <li> <a href="#" style={{color:'#ffffff'}}>Blog</a> </li>
                                    </ul>
                                </nav>
                                <div className="contact-info-social-links">
                                    <a className="icon-link" href="#">
                                        <div className="contact-info-social-link-item flex-center"> <i class="fab fa-linkedin-in"></i></div>
                                    </a>
                                    <a className="icon-link" href="#">
                                        <div className="contact-info-social-link-item flex-center"> <i className="fab fa-facebook-f"></i></div>
                                    </a>
                                    <a className="icon-link" href="#">
                                        <div className="contact-info-social-link-item flex-center"> <i className="fab fa-twitter"></i></div>
                                    </a>
                                    <a className="icon-link" href="#">
                                        <div className="contact-info-social-link-item flex-center"> <i className="fab fa-instagram"></i> </div>
                                    </a>
                                </div>
                                <div className="attribution-container">
                                    <p className="attribution" style={{fontSize:15}}>
                                        © 2023. All rights reserved by Intrucept Labs <a href="/" target="_blank"></a>
                                    </p>
                                </div>
                            </section>
                    </footer>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Welcome);