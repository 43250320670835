import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup } from 'reactstrap';
import "./dashboard.scss";
import Loader from "react-loader-spinner";
import {url} from '../../services/common';
const WorldMap = require('react-svg-worldmap').WorldMap;

class ThreatsMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryData:[],
            scanType:'all',
            mapfillcolor:'red',
            isLoading:false,
            loadingMessage:'Loading...'
        }
    }
    componentDidMount(){
        this.getMapData(this.state.scanType);
    }

    getMapData = (scanResult) => {
        this.setState({isLoading: true})
        var authToken = localStorage.getItem('auth_token');  	
        
        fetch(`${url}/region-wise-threat-map?scanType=${scanResult}`, {
            method: "GET",     
            headers: {
              'Accept': 'application/json',  
              'Authorization': 'Bearer '+authToken                  
             }            
         })
         .then((response) => response.json())
         .then((json) => {
            let updatedCountry    =   json.Country;
            let color             =   json.color;
            this.setState({countryData:updatedCountry,mapfillcolor:color})
            
         })
         .catch((error) => console.error('ss'+error))
         .finally(() => this.setState({isLoading: false})); 
    }
    
    render() {
        return (
            <React.Fragment>
                    <Card>
                        <CardBody>
                        <h4 className="card-title mb-4">Region-wise Threat Occurence Map</h4>
                        <ButtonGroup className="mb-2">
                            <select name="scantype" onChange={(e) => this.getMapData(e.target.value)} className="custom-select custom-select-sm">
                                <option value="all">All</option>
                                <option value="aws">AWS</option>
                                <option value="gcp">GCP</option>
                                <option value="azure">AZURE</option>
                                <option value="oci">OCI</option>
                            </select>
                        </ButtonGroup>
                        <div style={{textAlign:'center',width:"100%"}}>
                            {
                                this.state.isLoading &&
                                    <div>
                                    <h3 style={{color:'white', textAlign:'center', width:'100%', marginTop:'25%'}}>{this.state.loadingMessage}</h3>
                                    <Loader
                                        type="Bars"
                                        color="#00BFFF"
                                        height={100}
                                        width={'100%'}   
                                    />  
                                    </div> 
                            }
                            {
                                !this.state.isLoading &&
                            <WorldMap
                                color={this.state.mapfillcolor}
                                backgroundColor='#252B3B'
                                borderColor="white" 
                                size="lg" 
                                data={this.state.countryData} 
                            />     
                            } 
                        </div>
                        </CardBody>
                    </Card>   
            </React.Fragment>
        );
    }
}

export default ThreatsMap;